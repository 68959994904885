<link rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
    crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
    crossorigin="anonymous"></script>
<script
    src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>
<div class="container component-class">
    <mat-dialog-content>
      <p class="actionIcon" mat-dialog-close>
        <mat-icon class="cross" matTooltip="close">clear</mat-icon>
    </p>
        <h1 mat-dialog-title align="start">Add Details</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="itemForm" class="mainForm">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="ponoLabel">PO No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"
                                formControlName="ponoLabel"
                                placeholder="PO Number"
                                id="ponoLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="poDateLabel">PO Date </label>
                        </div>
                        <div class="col-sm-8">
                            <input type="date" class="form-control"
                                formControlName="poDateLabel"
                                placeholder="Choose Date" id="poDateLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="challanLabel">Challan No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"
                                formControlName="challanLabel"
                                placeholder="Challan Number" id="challanLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="challanDateLabel">Challan Date </label>
                        </div>
                        <div class="col-sm-8">
                            <input type="date" class="form-control"
                                formControlName="challanDateLabel"
                                placeholder="Choose Date" id="challanDateLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="freeQLabel">Free Quantity </label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"
                                formControlName="freeQLabel"
                                placeholder="Enter Ref No." id="freeQLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="godownLabel">Select Godown </label>
                        </div>
                        <div class="input-group col-sm-8">
                            <select class="form-control"
                                formControlName="godownLabel"
                                id="godownLabel" placeholder="Select an
                                Option">
                                <option [value]=''>Select</option>
                                <option *ngFor="let godown of inputData.goDown">{{godown.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="batchLabel">Batch </label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"
                                formControlName="batchLabel"
                                placeholder="Enter Ref No." id="batchLabel">
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-dialog-content>

    <mat-dialog-actions align='end'>
        <button class="btn btn-primary col-sm-2" mat-dialog-close (click)="addItem()">Submit</button>
    </mat-dialog-actions>
</div>
