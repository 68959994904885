import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-voucher-form',
  templateUrl: './voucher-form.component.html',
  styleUrls: ['./voucher-form.component.css']
})
export class VoucherFormComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

  @ViewChild('newVoucherForm') newVoucherForm!: TemplateRef<any>;

  @Output() public emitData = new EventEmitter();

  showErrorAlert: boolean = false;
  passData!: any;
  isLoading: boolean = false;
  forView: boolean = true;
  readView: boolean = true;
  voucherGroupData: any;
  data!: any;
  selectedValue = " ";
  one_attribute = " ";
  isEditFlow: any;
  isDeleteFlow: boolean;
  voucherTypeData: any;
  code: any;
  buttonDisable : boolean = false;
  inputDigit:number =1;
  digitCount : string = '';
  

  voucherForm = this.fb.group({
    'voucherLabel': ['', Validators.required],
    'prefixLabel': [''],
    'suffixLabel': [''],
    'startLabel': [''],
    // 'totalLabel':['',Validators.required],
    'typeLabel': ['', Validators.required],
    'currentLabel': [''],
    //'numberingLabel': ['', Validators.required],
    'numberingLabel': ['A'],
    'digitLabel': [''],
    'printLabel': [''],
  });
  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) {
      this.voucherForm.controls['numberingLabel'].patchValue('A');
     }

  ngOnInit() {
    this.resetVoucherForm();
    this.getVoucherData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getVoucherData() {
    this.isLoading = false;
    let listOfItems = ['Voucher', 'Voucher'];
    let optionValList = ['0', '1'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.voucherGroupData = response.voucher0;
      this.voucherTypeData = response.voucher1;
      this.passData = [this.appConstants.voucherGroupFormColumnNames, this.voucherGroupData, 'voucherGroupForm', this.appConstants.voucherGroupExcelColumnNames];
      this.isLoading = true;
      
    },
      (error => {
        this.passData = [this.appConstants.voucherGroupFormColumnNames, false, 'voucherGroupForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }


  resetVoucherForm() {
    this.showErrorAlert = false;
    this.voucherForm = this.fb.group({
      'voucherLabel': ['', Validators.required],
      'prefixLabel': [''],
      'suffixLabel': [''],
      'startLabel': [''],
      'typeLabel': ['', Validators.required],
      'currentLabel': [''],
      //'numberingLabel': ['', Validators.required],
      'numberingLabel': ['A'],
      'digitLabel': [''],
      'printLabel': [''],
    });
  }

  addVoucher() {
    console.log('Numbering >>> '+ this.voucherForm.controls['numberingLabel'].value);
    if (this.voucherForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      const generatedData = {
        Name: this.voucherForm.controls['voucherLabel'].value,
        Prefix: this.voucherForm.controls['prefixLabel'].value,
        Suffix: this.voucherForm.controls['suffixLabel'].value,
        StartWith: this.voucherForm.controls['startLabel'].value,
        CurrentNo: Number(this.voucherForm.controls['currentLabel'].value),
        Type: this.voucherForm.controls['typeLabel'].value,
        Numbering: this.voucherForm.controls['numberingLabel'].value,
        BillDigit: this.voucherForm.controls['digitLabel'].value,
        // BillPrint: this.voucherForm.controls['printLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateVoucher(generatedData).subscribe(response => {
          this.getVoucherData();
          this.toastr.success("Data Updated Successful", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        console.log(generatedData);
        this.masterService.addNewVoucher(generatedData).subscribe(response => {
          this.getVoucherData();
          this.toastr.success("Data added Successful", "Add Success")
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }


  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  patchBill(){
    this.inputDigit = this.voucherForm.value.digitLabel;
    const currentLabel = this.voucherForm.value.currentLabel.toString();
    const leadingZeros = '0'.repeat(this.inputDigit - currentLabel?.length);
    const bill_print_example = this.voucherForm.value.prefixLabel + leadingZeros + currentLabel + this.voucherForm.value.suffixLabel;
    this.voucherForm.patchValue({
      printLabel: bill_print_example
    });
  }



  addNewvoucher(rowData?: any) {
    this.resetVoucherForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.readView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newVoucherForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.readView = true
      this.patchVoucherFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newVoucherForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });

    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteVoucher(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      //this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.readView = false
      this.patchVoucherFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newVoucherForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.readView = false
      this.patchVoucherFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newVoucherForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchVoucherFormValues(row) {
    this.voucherForm.patchValue({
      'voucherLabel': row.name,
      'prefixLabel': row.prefix,
      'suffixLabel': row.suffix,
      'startLabel': row.start_With,
      'typeLabel': row.voucher_Type,
      'currentLabel': row.currentNo,
      'numberingLabel': row.numbering,
      'digitLabel': row.billDigit,
      'printLabel': row.billPrint,
    })
  }
}



