<mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
<div style="width: 100%; height: 100%;" *ngIf="isLoading">
    <div class="container" fxLayoutAlign="center center" fxFlexFill>
        <mat-card fxFlex='25'>
            <mat-toolbar color="primary">Customer Login</mat-toolbar>
            <form fxLayoutAlign="Strech" fxLayout="column" class="form-class" [formGroup]="loginForm">
                <p style="color: red;">*All fields are required</p>
                <mat-form-field>
                    <mat-label>Select Distributor</mat-label>
                    <mat-select formControlName="serialLabel" id="serialLabel" (selectionChange)="saveData($event.value)">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let list of distributorList" [value]="list.soCode">{{list.companyName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Registered Email"
                    formControlName="userLabel" id="userLabel">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Registered Password" [type]="hide ? 'password' : 'text'" formControlName="passwordLabel" id="passwordLabel" required>
                <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <button mat-raised-button color="primary" class="{{isLoading ? '':'loader' }}" [disabled]="!isLoading" type="submit" (click)="login('dashboard')">Click Here To Login</button>
            <br>
            <div align="center">
                <button mat-raised-button color="warn" (click)="openComponent('login')">Owner Login</button>
            </div>
        </form>
        </mat-card>
    </div>
</div>