import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterComponent } from './dashboard/master/master.component';
import { TransactionsComponent } from './dashboard/transactions/transactions.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { AccountingMasterComponent } from './dashboard/master/accounting-master/accounting-master.component';
import { InventoryMasterComponent } from './dashboard/master/inventory-master/inventory-master.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { CompanyComponent } from './company/company.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LedgerFormComponent } from './dashboard/master/accounting-master/ledger-form/ledger-form.component';
import { GodownMasterComponent } from './dashboard/master/inventory-master/godown-master/godown-master.component';
import { VoucherFormComponent } from './dashboard/master/accounting-master/voucher-form/voucher-form.component';
import { GroupFormComponent } from './dashboard/master/accounting-master/group-form/group-form.component';
import { SalesPersonComponent } from './dashboard/master/accounting-master/sales-person/sales-person.component';
import { StockGroupFormComponent } from './dashboard/master/inventory-master/stock-group-form/stock-group-form.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { ItemFormInventoryComponent } from './dashboard/master/inventory-master/item-form-inventory/item-form-inventory.component';
import { HsnFormComponent } from './dashboard/master/inventory-master/hsn-form/hsn-form.component';
import { AppConstants } from 'src/services/AppConstants';
import { TableComponent } from './shared/table/table.component';
import { AddDetailComponent } from './dashboard/transactions/sale-bill/add-detail/add-detail.component';
import { AddItemComponent } from './dashboard/transactions/sale-bill/add-item/add-item.component';
import { EachItemDetailComponent } from './dashboard/transactions/sale-bill/each-item-detail/each-item-detail.component';
import { AddLedgerComponent } from './dashboard/transactions/sale-bill/add-ledger/add-ledger.component';
// import { ItemFormInventoryComponent } from './dashboard/master/inventory-master/item-form-inventory/item-form-inventory.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoginComponent } from './auth/login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { RegisterComponent } from './auth/register/register.component';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { SaleBillComponent } from './dashboard/transactions/sale-bill/sale-bill.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { NewUnitComponent } from './dashboard/master/inventory-master/new-unit/new-unit.component';
import { RegisterReportComponent } from './dashboard/reports/register-report/register-report.component';
import { SalesComponent } from './dashboard/reports/register-report/sales/sales.component';

import { AccountingTransactionComponent } from './dashboard/transactions/accounting-transaction/accounting-transaction.component';
import { InventoryTransactionComponent } from './dashboard/transactions/inventory-transaction/inventory-transaction.component';
import { ConfirmationBoxComponent } from './shared/confirmation-box/confirmation-box.component';
import { ToastrModule } from 'ngx-toastr';
import { StatementOfAccountComponent } from './dashboard/reports/statement-of-account/statement-of-account.component';
import { FinalAccountComponent } from './dashboard/reports/final-account/final-account.component';
import { MisReportComponent } from './dashboard/reports/mis-report/mis-report.component';
import { ItemWiseSalesComponent } from './dashboard/reports/mis-report/item-wise-sales/item-wise-sales.component';
import { PartyWiseSalesComponent } from './dashboard/reports/mis-report/party-wise-sales/party-wise-sales.component';
import { SortPipe } from './pipe/sort.pipe';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, } from '@angular/material/core';
import { LedgerComponent } from './dashboard/reports/ledger/ledger.component';
import { ItemWiseColumnarComponent } from './dashboard/reports/mis-report/item-wise-columnar/item-wise-columnar.component';
import { PartyWiseColumnarComponent } from './dashboard/reports/mis-report/party-wise-columnar/party-wise-columnar.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ReceiptComponent } from './dashboard/transactions/accounting-transaction/receipt/receipt.component';
import { MatMenuModule } from '@angular/material/menu';
import { TaxInvoiceComponent } from './shared/invoices/tax-invoice/tax-invoice.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StockSummaryComponent } from './dashboard/reports/stock-summary/stock-summary.component';
import { CashBookComponent } from './dashboard/reports/cash-book/cash-book.component';
import { ExcelDownloadTableComponent } from './shared/excel-download-table/excel-download-table.component';
import { CostCenterComponent } from './dashboard/master/accounting-master/cost-center/cost-center.component';
import { SalesPriceComponent } from './dashboard/master/inventory-master/sales-price/sales-price.component';
import { PurchaseCostComponent } from './dashboard/master/inventory-master/purchase-cost/purchase-cost.component';
import { BillAdjustmentComponent } from './dashboard/master/accounting-master/bill-adjustment/bill-adjustment.component';
import { PayablesComponent } from './dashboard/master/accounting-master/bill-adjustment/payables/payables.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SettingComponent } from './setting/setting.component';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomerLoginComponent } from './auth/customer-login/customer-login.component';
import { RegisterClientComponent } from './auth/register-client/register-client.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipe } from './pipe/filter.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InProgressBoxComponent } from './shared/in-progress-box/in-progress-box.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MenuComponent } from './shared/menu/menu.component';
import { UploadMasterDialogComponent } from './dashboard/master/upload-master-dialog/upload-master-dialog.component';
import { ProductionInvoiceComponent } from './shared/production-invoice/production-invoice.component';
import { TaxInvoice2Component } from './shared/invoices/tax-invoice2/tax-invoice2.component';
import { TaxInvoice5Component } from './shared/invoices/tax-invoice5/tax-invoice5.component';
import { UserPermissionComponent } from './setting/user-permission/user-permission.component';
import { TableFilterPipe } from './pipe/table-filter.pipe';
import { CustomDateAdapter } from './shared/CustomDateAdapter';
import { Platform } from '@angular/cdk/platform';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { AuthTokenInterceptor } from 'src/services/authTokenInterceptor';
import { DashboardMainComponent } from './revenue-dashboard/main-dashboard/main-dashboard.component';

import { CardsComponent } from './revenue-dashboard/cards/cards.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PurchaseSalesTrendComponent } from './revenue-dashboard/purchase-sales-trend/purchase-sales-trend.component';
import { OutstandingBalanceGraphComponent } from './revenue-dashboard/outstanding-balance-graph/outstanding-balance-graph.component';
import { ProductSalesTrendComponent } from './revenue-dashboard/product-sales-trend/product-sales-trend.component';
import { MostSoldItemsComponent } from './revenue-dashboard/most-sold-items/most-sold-items.component';
import { LeastSoldItemsComponent } from './revenue-dashboard/least-sold-items/least-sold-items.component';


@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    TransactionsComponent,
    ReportsComponent,
    AccountingMasterComponent,
    InventoryMasterComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    CompanyComponent,
    ItemFormInventoryComponent,
    HsnFormComponent,
    LedgerFormComponent,
    GodownMasterComponent,
    VoucherFormComponent,
    GroupFormComponent,
    SalesPersonComponent,
    HsnFormComponent,
    StockGroupFormComponent,
    ItemFormInventoryComponent,
    TableComponent,
    AddDetailComponent,
    AddItemComponent,
    EachItemDetailComponent,
    AddLedgerComponent,
    LoginComponent,
    RegisterComponent,
    SaleBillComponent,
    CompanyListComponent,
    NewUnitComponent,
    RegisterReportComponent,
    SalesComponent,
    AccountingTransactionComponent,
    InventoryTransactionComponent,
    ConfirmationBoxComponent,
    StatementOfAccountComponent,
    FinalAccountComponent,
    MisReportComponent,
    ItemWiseSalesComponent,
    PartyWiseSalesComponent,
    SortPipe,
    LedgerComponent,
    ItemWiseColumnarComponent,
    PartyWiseColumnarComponent,
    ReceiptComponent,
    TaxInvoiceComponent,
    StockSummaryComponent,
    CashBookComponent,
    ExcelDownloadTableComponent,
    CostCenterComponent,
    SalesPriceComponent,
    PurchaseCostComponent,
    BillAdjustmentComponent,
    PayablesComponent,
    SettingComponent,
    CustomerLoginComponent,
    RegisterClientComponent,
    FilterPipe,
    InProgressBoxComponent,
    SideNavComponent,
    TaxInvoice2Component,
    MenuComponent,
    UploadMasterDialogComponent,
    TaxInvoice5Component,
    ProductionInvoiceComponent,
    UserPermissionComponent,
    TableFilterPipe,
    DashboardMainComponent,
    CardsComponent,
    PurchaseSalesTrendComponent,
    OutstandingBalanceGraphComponent,
    ProductSalesTrendComponent,
    MostSoldItemsComponent,
    LeastSoldItemsComponent,
  ],
  imports: [
    BrowserModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    MatCardModule,
    HttpClientModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTableModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatExpansionModule,
    MatMenuModule,
    QRCodeModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 1500

    }),
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule, MatDatepickerModule,
    MatSortModule,
    MatAutocompleteModule,
    Ng2SearchPipeModule,
    MatCheckboxModule,
    MatPaginatorModule
  ],
  providers: [AppConstants, DatePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy, // Use PathLocationStrategy instead of HashLocationStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
