import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InProgressBoxComponent } from 'src/app/shared/in-progress-box/in-progress-box.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  sideNavStatus: boolean = false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (key == 'R') {
      this.openComponent('dashboard/report/register');
    } else if (key == 'A') {
      this.openComponent('dashboard/report/statementOfAccount');
    } else if (key == 'I') {
      this.openComponent('dashboard/report/stock-item');
    } else if (key == 'F') {
      this.openComponent('dashboard/report/finalAccount');
    } else if (key == 'M') {
      this.openComponent('dashboard/report/misReport');
    } else if (key == 'L') {
      this.openComponent('dashboard/report/ledger');
    } else if (key == 'S') {
      this.openComponent('dashboard/report/stock-summary');
    } else if (key == 'T') {
      this.openComponent('dashboard/report/register/item-report');
    } else if (event.key == 'Escape') {
      this.openComponent('dashboard');
    }
  }

  constructor(private router: Router, private dialog: MatDialog) {}

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  ngOnInit(): void {
    if (sessionStorage.getItem('customerLogin') == 'true') {
      this.customerLogin = true;
    } else {
      this.customerLogin = false;
    }
  }
  InProgressDialog() {
    let dialogRef = this.dialog.open(InProgressBoxComponent, {
      disableClose: true,
      minWidth: '20%',
      minHeight: '15%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
