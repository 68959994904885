import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ItemSolds } from 'src/app/model/item-solds';
import { PurchaseSaleDetails } from 'src/app/model/purchase-sale-details';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  header = new HttpHeaders({ 'Content-Type': 'application/json' })

  serial: string = '';
  companycode: string = '';
  serverval: string = '';

  constructor(private http: HttpClient) { }

  public getCompanyTransactionData(): Observable<PurchaseSaleDetails> | any {

    this.serial = sessionStorage.getItem('Serial');
    this.companycode = sessionStorage.getItem('CompanyCode');
    this.serverval = sessionStorage.getItem('ServerVal');

    if(this.serial && this.companycode && this.serverval){
      let params = new HttpParams()
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
       return this.http.get<PurchaseSaleDetails>(API_URL + '/api/dashboard?', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
    }
    return of([]);
  }

  public getDetailsOfSoldItem(): Observable<ItemSolds> | any {

    this.serial = sessionStorage.getItem('Serial');
    this.companycode = sessionStorage.getItem('CompanyCode');
    this.serverval = sessionStorage.getItem('ServerVal');

    if(this.serial && this.companycode && this.serverval){
    let params = new HttpParams()
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http.get<ItemSolds>(API_URL + '/api/dashboard/item?', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
    }
    return of([]);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }



}
