<footer>
    <div class="box">
        <div class="item1">
           <div class="social-icon"><a href="https://www.youtube.com/channel/UCTdvF9F06Mb74_ci3dAZv9A" target="_blank" matTooltip="Youtube"><i class="fab fa-youtube"></i></a></div>
           <div class="social-icon"><a href="http://www.linkedin.com/in/dwarka-khandelwal-01674756" target="_blank" matTooltip="Linkedin"><i class="fab fa-linkedin"></i></a></div>
           <div class="social-icon"><a href="https://www.anugata.com/" target="_blank" matTooltip="Website"><i class="fas fa-globe"></i></a></div>
        </div>
        <div class="item2">Anugata NXG - Anu Computer Consultancy</div>
      </div>
    <!-- <div class="footer-container">
        <div class="inside-container">
            <img src="https://static.wixstatic.com/media/ae8a25_4bf38cb714c44101bd1078e59e7ed785~mv2.jpg/v1/fill/w_61,h_40,al_c,q_80,usm_0.66_1.00_0.01/ae8a25_4bf38cb714c44101bd1078e59e7ed785~mv2.webp">
        </div>
        <div class="inside-container">
            <h1>Anu<br> Computer<br> Consultancy</h1>
        </div>
        <div class="inside-container">
            <h2>LINKS</h2>
            <ul>
                <a href="https://play.google.com/store/apps/details?id=com.anugata.DataTable"><li>Mobile App</li></a><br>
                <a (click)="openComponent('dashboard')"><li>Home</li></a><br>
                <a href="https://www.anugata.com/" target="_blank"><li>About Us</li></a><br>
                <a href="#"><li>Contact</li></a><br>
            </ul>
        </div>
        <div class="inside-container">
            <h2>ABOUT</h2>
            <ul>
                <a href="mailto:support@anugat.com"><li>support@anugat.com</li></a>
                <a href="mailto:info@anugat.com"><li>info@anugat.com</li></a><br>
                <li>Mobile No: +919922994747 |</li>
                <li> +918796265265</li><br>
                <li>226, Sarai Apartment, Behind <br> Sandvik Colony, Dighi road,<br> Bhosari, Pune - 411039</li>
            </ul>
        </div>
        <div class="inside-container">
            <h2>SOCIAL</h2>
            <ul>
                <li><a href="https://www.youtube.com/channel/UCTdvF9F06Mb74_ci3dAZv9A" target="_blank"><img src="https://static.wixstatic.com/media/44eb1e29ffa34198aee01e8d4f305903.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01/44eb1e29ffa34198aee01e8d4f305903.webp"></a></li>
                <li><a href="http://www.linkedin.com/in/dwarka-khandelwal-01674756" target="_blank"><img style="background-color:#0e76a8;" src="https://static.wixstatic.com/media/7528824071724d12a3e6c31eee0b40d4.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01/7528824071724d12a3e6c31eee0b40d4.webp"></a></li>
            </ul>
        </div>
    </div> -->
</footer>