<div class="table-container">
<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="ItemName" style="margin: 2px;">
    <th mat-header-cell *matHeaderCellDef>Item Name</th>
    <td mat-cell *matCellDef="let item">
      {{ item.name | uppercase }}
    </td>
  </ng-container>

  <ng-container matColumnDef="SoldQty" style="margin: 2px;">
    <th mat-header-cell *matHeaderCellDef> Qty </th>
    <td mat-cell *matCellDef="let item">
      {{item.qtySold}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
  <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
</table>
</div>
