<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
  <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
      <mat-drawer-content style="min-height: 93vh; width: 97vw;padding: 0 2rem; overflow: hidden;">
        <mat-toolbar>
          <nav class="headTitle">{{this.headTitle}}</nav>
        </mat-toolbar>
        <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
        <div mat-form-field class="search alignSearch">
          <div class="row" style="display: flex; margin: 0.5em 0 0 0 " >
            <div>
              <label for="filterOnSelect" style="font-weight: bold;"> Filter On</label>&nbsp;
              <select class="filterBySelect" [(ngModel)]="selectedColumn" (change)="columnSelectionFn(selectedColumn)">
                <option *ngFor="let column of columnName" [value]="column.value">{{ column.name }}</option>
              </select>
            </div>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <div>
              <label for="filterConditions" style="font-weight: bold">
                Filter By</label
              >&nbsp;
              <select class="filterBySelect" [(ngModel)]="selectedCondtion"  [disabled]="disableSelectionDropDown">
                <option
                  *ngFor="let condition of filterConditionsList"
                  [value]="condition"
                >
                  {{ condition }}
                </option>
              </select>
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <div>
              <input [(ngModel)]="searchValue" (keyup)="valueSearchEvent(searchValue)" class="search-input" placeholder="Search..." #input>
            </div>
          </div>
        </div>

        <!-- <button (click)="exportToExcel()">Export to Excel</button> -->

       <div id="ttlRow" *ngIf="totalRowCount > 0">
          <p style="display: flex; margin: 1em 0 0 0 ">Total rows: {{ totalRowCount }}</p>
        </div>
        <div id="ttlRow" *ngIf="filterDataCount > 0" >
          <p style="display: flex; margin: 1em 0 0 0 ">{{filterDataCount}} Entries Found</p>
        </div>

        <div *ngIf="isLoading">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- bill date Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher Name Column -->
            <ng-container matColumnDef="opening">
              <th mat-header-cell *matHeaderCellDef> Opening </th>
              <td mat-cell *matCellDef="let transaction"> {{transaction.opening}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher No Column -->
            <ng-container matColumnDef="in Qty">
              <th mat-header-cell *matHeaderCellDef> In Qty </th>
              <td mat-cell *matCellDef="let transaction"> {{transaction['in Qty']}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Ledger Name Column -->
            <ng-container matColumnDef="out Qty">
              <th mat-header-cell *matHeaderCellDef> Out Qty </th>
              <td mat-cell *matCellDef="let transaction"> {{transaction['out Qty']}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <!-- BillAmount Column -->
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef> Balance </th>
              <td mat-cell *matCellDef="let transaction"> {{transaction.balance }} </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
            <tr mat-row *matRowDef="let row; columns: this.passData[0];"></tr>
            <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of Data"></mat-paginator>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
