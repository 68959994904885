import { Inject, Injectable } from '@angular/core'
import { environment } from '../environments/environment'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { AppConstants } from './AppConstants'

import { catchError } from 'rxjs/operators'

const API_URL = environment.apiUrl

@Injectable({
  providedIn: 'any',
})
export class MasterService {
  ngOnInit() {}
  public passData!: BehaviorSubject<any>

  header = new HttpHeaders({
    'Content-Type': 'application/json',
    //'Authorization':sessionStorage.getItem('Token')
  })

  loginHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  })

  options = { headers: this.header }
  getVoucher: any
  serverval: any = sessionStorage.getItem('ServerVal')
  companycode: any = sessionStorage.getItem('CompanyCode')
  serial: any = sessionStorage.getItem('Serial')

  constructor(private http: HttpClient, private appConstants: AppConstants) {}
  // Item API Call
  public addNewItem(newItemData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/NewItem', newItemData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public updateItem(newItemData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/Updateitem', newItemData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public deleteItem(newItemData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/DeleteItem', newItemData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }
  //  HSN Api Call
  public addNewHsn(newHsnData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/NewHSN', newHsnData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public updateHsn(newHsnData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/UpdateHsn', newHsnData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public deleteHsn(newHsnData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/DeleteHsn', newHsnData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  // StockGroup Api Cal

  public addNewStockGroup(newStockGroupData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewStockGroup',
        newStockGroupData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }
  public updateStockGroup(newStockGroupData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateStockGroup',
        newStockGroupData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public deleteStockGroup(newStockGroupData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteStockGroup',
        newStockGroupData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }
  // Godown Api Call
  public addNewGodown(newGodownData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/NewGodown', newGodownData,{ headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public updateGodown(newGodownData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateGodown',
        newGodownData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }
  public deleteGodown(newGodownData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteGodown',
        newGodownData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // Ledger Api Call
  public addNewLedger(newLedgerData: any): Observable<any> {
    return this.http.post(API_URL + '/api/Master/NewLedger', newLedgerData, { headers : this.header, responseType: 'text' })
    .pipe(catchError(this.errorHandler))
  }


  public updateLedger(newLedgerData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateLedger',
        newLedgerData,
        { headers : this.header, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler))
  }

  public deleteLedger(newLedgerData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/Master/DeleteLedger',
        newLedgerData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }
  // Voucher Api Call
  public addNewVoucher(newVoucherData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewVoucher',
        newVoucherData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updateVoucher(newVoucherData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateVoucher',
        newVoucherData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public deleteVoucher(newVoucherData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/Master/DeleteVoucher',
        newVoucherData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }
  // Group Api Call
  public addNewGroup(newGroupData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/NewGroup', newGroupData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public updateGroup(groupData: any): Observable<any> {
    return this.http
      .post(API_URL + '/api/Master/UpdateGroup', groupData, { headers : this.header, responseType: 'text' })
      .pipe(catchError(this.errorHandler))
  }

  public deleteGroup(deleteGroupData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteGroup',
        deleteGroupData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // Register client Api Call NXGGetSODatabase?

  public getCledgerData(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial=sessionStorage.getItem('Serial');
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
    return this.http
      .get(API_URL + '/api/NXGGetSODatabase?', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }
  public getClient(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial=sessionStorage.getItem('Serial');
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http
      .get(API_URL + '/api/NXGGetUsersList', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }
  public addNewClient(newClientData: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/api/NXGSORegister', newClientData, this.options)
      .pipe(catchError(this.errorHandler))
  }

  public updateClient(clientData: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/api/NXGEditUsersList', clientData, this.options)
      .pipe(catchError(this.errorHandler))
  }

  public deleteClient(deleteClientData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/NXGDeleteFromUsersList',
        deleteClientData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }

  // Cost-Center Api Call
  public addNewCostCenter(newCostCenterData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewCostCenter',
        newCostCenterData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updateCostCenter(CostCenterData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateCostCenter',
        CostCenterData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public deleteCostCenter(deleteCostCenterData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteCostCenter',
        deleteCostCenterData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // Sales-Price Api Call
  public addNewSalesPrice(newSalesPriceData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewPriceCall',
        newSalesPriceData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updateSalesPrice(salesPriceData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdatePriceCall',
        salesPriceData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public deleteSalesPrice(deleteSalesPriceData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeletePriceCall',
        deleteSalesPriceData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // Purchase-Cost Api Call
  public addNewPurchaseCost(newPurchaseCostData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewPriceCall',
        newPurchaseCostData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updatePurchaseCost(purchaseCostData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdatePriceCall',
        purchaseCostData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public deletePurchaseCost(deletePurchaseCostData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeletePriceCall',
        deletePurchaseCostData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // SalesPerson Api Call
  public addNewSalesPerson(newSalesPersonData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/NewSalesMen',
        newSalesPersonData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updateSalesMen(newSalesMenData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateSalesMen',
        newSalesMenData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  public updateCompany(companyData: any): Observable<any> {
    return this.http
      .post(API_URL + '/Api/NXGEditDatabase', companyData, {headers: this.header,
      responseType: 'text'})
      .pipe(catchError(this.errorHandler))
  }

  // Api/NXGEditDatabase

  public deleteSalesMen(newSalesMenData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteSalesMen',
        newSalesMenData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }
  // Bill Adjusment Api call
  public addNewBillAdjustment(newBillData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/Master/NewBillAdjustment',
        newBillData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }
  public updateBillAdjustment(billData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/UpdateBillAdjustment',
        billData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }
  public deleteBillAdjustment(deleteBillData: any): Observable<any> {
    return this.http
      .post(
        API_URL + '/api/Master/DeleteBillAdjustment',
        deleteBillData,
        { headers : this.header, responseType: 'text' },
      )
      .pipe(catchError(this.errorHandler))
  }

  // Login Form Api Call
  public LoginForm(loginData: any): Observable<any> {
    return this.http.post(API_URL + '/api/login', loginData, { headers : this.loginHeader, responseType: 'text' })
    .pipe(catchError(this.errorHandler));
  }
  // RegisterForm Api Call
  public registerForm(registerData: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/api/NXGRegister', registerData, this.options)
      .pipe(catchError(this.errorHandler))
  }
  // registerClientForm Api Call
  public registerClientForm(registerClientData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/NXGGetSODatabase',
        registerClientData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }
  //
  public getSelectCustomer(
    MName: any,
    optionVal: any,
    companyCode: any,
  ): Observable<any> {
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial = sessionStorage.getItem('Serial')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', companyCode)
      .append('ServerVal', this.serverval)
    return this.http
      .get(API_URL + '/api/Master/Fetch', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }

  public getItem(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial = sessionStorage.getItem('Serial')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http
      .get(API_URL + '/api/Master/Fetch', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }

  public getStateData(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http
      .get(API_URL + '/api/FetchState', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }

  // Partywise sales - ledger1
  public getLedger1Data(MName: any, optionVal: any): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http
      .get(API_URL + '/api/Master/Fetch', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }

  // group dropdown - ledger

  public getGroupData(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial = sessionStorage.getItem('Serial')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http.get(API_URL + '/api/Master/Fetch', {
      params,
      headers: this.header,
    })
  }

  // register-report-sales
  public getReportSales(MName: any, pageNumber: number, pageSize: number, startDate: string, endDate: string): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode');
    this.serverval = sessionStorage.getItem('ServerVal');
    this.serial = sessionStorage.getItem('Serial');
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('PageNumber', pageNumber.toString())
      .append('PageSize', pageSize.toString())
      .append('StartDate', startDate)
      .append('EndDate', endDate)
    return this.http
      .get(API_URL + '/api/MobileNXG/FetchTran', {
        params,
        headers: this.header,
      })
      .pipe(catchError(this.errorHandler))
  }

  public searchTranReport(MName: any,
    pageNumber: number,
    pageSize: number,
    searchFilter: string,
    searchText: string,
    filterBy: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
      this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('PageNumber', pageNumber.toString())
      .append('PageSize', pageSize.toString())
      .append('SearchField', searchFilter)
      .append('SearchText', searchText)
      .append('FilterBy', filterBy)
      .append('StartDate', startDate)
      .append('EndDate', endDate)
      return this.http
      .get(API_URL + '/api/MobileNXG/SearchTran', {
        params,
        headers: this.header,
      })
      .pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
  public getSalesDetails(MName: any, optionVal: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    this.serial = sessionStorage.getItem('Serial')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http.get(API_URL + '/api/Master/Fetch', {
      params,
      headers: this.header,
    })
  }

  public getVoucherForSales(MName: any): Observable<any> {
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('Type', MName)
    return this.http.get(API_URL + '/api/Master/FetchVoucher', {
      params,
      headers: this.header,
    })
  }

  public salesTransactions(salesData: any): Observable<any> {
    let params = new HttpParams().append('ServerVal', this.serverval)
    return this.http
      .post(API_URL + '/api/MobileNXG/NewTran', salesData, {
        params,
        headers: this.header,
        responseType: 'text'
      })
      .pipe(catchError(this.errorHandler))
  }

  public salesEditTransactions(salesData: any): Observable<any> {
    let params = new HttpParams().append('ServerVal', this.serverval)
    return this.http
      .post(API_URL + '/api/MobileNXG/UpdateTran', salesData, {
        params,
        headers: this.header,
        responseType: 'text'
      })
      .pipe(catchError(this.errorHandler))
  }

  // company API-call
  public addNewCompany(newCompanyData: any): Observable<any> {
    return this.http.post(
      API_URL + '/api/NXGAddDatabase',
      newCompanyData,
      {
        headers: this.header,
        responseType: 'text'
    }
    )
  }
  // new unit API-call
  public addUnit(data: any): Observable<any> {
    return this.http.post(API_URL + '/api/Master/NewUnit', data, {headers: this.header , responseType: 'text'})
  }

  public getCompany(MName: any, optionVal: any): Observable<any> {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':sessionStorage.getItem('Token')
    })
    this.options = { headers: this.header }
    this.serial = sessionStorage.getItem('Serial')
    let params = new HttpParams()
      .append('MName', MName)
      .append('OptionVal', optionVal)
      .append('Serial', this.serial)
    return this.http
      .get(API_URL + '/api/NXGGetDatabase', { params, headers: this.header })
      .pipe(catchError(this.errorHandler))
  }
  public deleteCompany(newCompanyData: any): Observable<any> {
    return this.http
      .post<any>(
        API_URL + '/api/NXGDeleteDatabase',
        newCompanyData,
        this.options,
      )
      .pipe(catchError(this.errorHandler))
  }

  // Distributor login list
  public getDistributor(): Observable<any> {
    // let params = new HttpParams().append("MName", MName).append("OptionVal", optionVal)
    return this.http
      .get(API_URL + '/api/NXGGetSODatabase', { headers: this.loginHeader })
      .pipe(catchError(this.errorHandler))
  }

  //For uploading data to table directly
  public uploadMasterData(formData: FormData, MName: any): Observable<any> {
    console.log()
    this.header = new HttpHeaders({
      'Content-Type': 'mutipart/formdata boundary=boundary',
      'Accept': '*/*',
      // 'Authorization':sessionStorage.getItem('Token')
    })
    formData.append('MName', MName)
    formData.append('Serial', this.serial)
    formData.append('CompanyCode', this.companycode)
    formData.append('ServerVal', this.serverval)
    return this.http
      .post<any>(API_URL + '/api/Upload/uploadMaster', formData)
      .pipe(catchError(this.errorHandler))
  }
}
