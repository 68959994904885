import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { ConfirmDialogModel } from '../confirmation-box/confirmDialog.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('newItemForm') newItemForm!: TemplateRef<any>;
  @Input() public recievedData: Array<any> = [];
  @Output() public rowData: Array<any> = [];
  @Output('itemFormComp') itemFormComp: EventEmitter<any> = new EventEmitter();
  @Output('hsnFormComp') hsnFormComp: EventEmitter<any> = new EventEmitter();
  @Output('stockGroupFormComp') stockGroupFormComp: EventEmitter<any> = new EventEmitter();
  @Output('ledgerFormComp') ledgerFormComp: EventEmitter<any> = new EventEmitter();
  @Output('voucherFormComp') voucherFormComp: EventEmitter<any> = new EventEmitter();
  @Output('groupFormComp') groupFormComp: EventEmitter<any> = new EventEmitter();
  @Output('godownFormComp') godownFormComp: EventEmitter<any> = new EventEmitter();
  @Output('salesMenFormComp') salesMenFormComp: EventEmitter<any> = new EventEmitter();
  @Output('costCenterFormComp') costCenterFormComp: EventEmitter<any> = new EventEmitter();
  @Output('salesPriceFormComp') salesPriceFormComp: EventEmitter<any> = new EventEmitter();
  @Output('purchaseCostFormComp') purchaseCostFormComp: EventEmitter<any> = new EventEmitter();
  @Output('billAdjustmentFormComp') billAdjustmentFormComp: EventEmitter<any> = new EventEmitter();
  @Output('registerClientFormComp') registerClientFormComp: EventEmitter<any> = new EventEmitter();
  dataSource: any;
  formType: any;
  errorValue: boolean = false;
  excelColumnData: any = [];
  url: any = window.location.href;
  @Output() editDialog = new EventEmitter();
  routerName: string;
  viewIcon: boolean = true



  constructor(private dialog: MatDialog, private router: Router,
    private toastr: ToastrService) { }


  ngOnInit(): void {
     if (this.recievedData[1] != false || (this.recievedData[1] != undefined && this.recievedData != null)) {
      this.excelColumnData = [this.recievedData[3], this.recievedData[1]]
      this.formType = this.recievedData[2] != undefined && this.recievedData[2] != null ? this.recievedData[2] : null;
      this.dataSource = new MatTableDataSource(this.recievedData[1]);
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        return data.name.toLowerCase().includes(filter);
      };
    }
    if (this.formType === 'clientForm') {
      this.viewIcon = false
    }
  }
  ngAfterViewInit() {
    if(this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event) {
    this.dataSource.filter = event.target.value.trim().toLowerCase();
  }
  delete(row: any) {
    if (row.canDelete == true || this.formType === 'clientForm') {
      const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        disableClose: true,
        width: "35%",
        height: "40%",
        data : {
          title : 'Delete Confirmation',
          message : 'Do you want to DELETE this record?',
          dialogData : row
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.recievedData[1].forEach((value: any, index: any) => {
            if (value == row) {
              this.recievedData[1].splice(index, 1);
              this.dataSource = new MatTableDataSource(this.recievedData[1]);
            }
          });
          this.itemFormComp.emit({ row, 'deleteFlow': true });
          this.godownFormComp.emit({ row, 'deleteFlow': true });
          this.salesMenFormComp.emit({ row, 'deleteFlow': true });
          this.hsnFormComp.emit({ row, 'deleteFlow': true });
          this.stockGroupFormComp.emit({ row, 'deleteFlow': true });
          this.ledgerFormComp.emit({ row, 'deleteFlow': true });
          this.groupFormComp.emit({ row, 'deleteFlow': true });
          this.voucherFormComp.emit({ row, result, 'deleteFlow': true });
          this.costCenterFormComp.emit({ row, result, 'deleteFlow': true });
          this.salesPriceFormComp.emit({ row, result, 'deleteFlow': true });
          this.purchaseCostFormComp.emit({ row, result, 'deleteFlow': true });
          this.billAdjustmentFormComp.emit({ row, result, 'deleteFlow': true });
          this.registerClientFormComp.emit({ row, result, 'deleteFlow': true });
        } else {

          this.toastr.info('Not Deleted')
        }
      })
    } else {
      this.toastr.warning("You don't have required privilege to Delete this Data", "Delete Alert")
    }
  }

  edit(row: any) {
    this.itemFormComp.emit({ row, 'editFlow': true });
    this.hsnFormComp.emit({ row, 'editFlow': true });
    this.stockGroupFormComp.emit({ row, 'editFlow': true });
    this.ledgerFormComp.emit({ row, 'editFlow': true });
    this.voucherFormComp.emit({ row, 'editFlow': true });
    this.groupFormComp.emit({ row, 'editFlow': true });
    this.salesMenFormComp.emit({ row, 'editFlow': true });
    this.godownFormComp.emit({ row, 'editFlow': true });
    this.costCenterFormComp.emit({ row, 'editFlow': true });
    this.salesPriceFormComp.emit({ row, 'editFlow': true });
    this.purchaseCostFormComp.emit({ row, 'editFlow': true });
    this.billAdjustmentFormComp.emit({ row, 'editFlow': true });
    this.registerClientFormComp.emit({ row, 'editFlow': true });
  }

  view(row: any) {
    this.ledgerFormComp.emit({ row, 'viewFlow': true });
    this.billAdjustmentFormComp.emit({ row, 'viewFlow': true });
    this.costCenterFormComp.emit({ row, 'viewFlow': true });
    this.groupFormComp.emit({ row, 'viewFlow': true });
    this.salesMenFormComp.emit({ row, 'viewFlow': true });
    this.voucherFormComp.emit({ row, 'viewFlow': true });
    this.godownFormComp.emit({ row, 'viewFlow': true });
    this.hsnFormComp.emit({ row, 'viewFlow': true });
    this.itemFormComp.emit({ row, 'viewFlow': true });
    this.purchaseCostFormComp.emit({ row, 'viewFlow': true });
    this.salesPriceFormComp.emit({ row, 'viewFlow': true });
    this.stockGroupFormComp.emit({ row, 'viewFlow': true });
    this.registerClientFormComp.emit({ row, 'viewFlow': true });
  }
  renameColumn(columnName) {
    if (this.formType === 'ledgerGroupForm') {
      if (columnName === 'name') {
        return 'Voucher Name';
      } else if (columnName === 'group') {
        return 'Group';
      } else if (columnName === 'igst') {
        return 'IGST';
      } else if (columnName === 'cgst') {
        return 'CGST';
      } else if (columnName === 'sgst') {
        return 'SGST';
      }
    } else if (this.formType === 'voucherGroupForm') {
      if (columnName === 'name') {
        return 'Voucher Name';
      } else if (columnName === 'voucher_Type') {
        return 'Voucher Type';
      } else if (columnName === 'currentNo') {
        return 'Current No';
      } else if (columnName === 'numbering') {
        return 'Numbering';
      }
    } else if (this.formType === 'groupForm') {
      if (columnName === 'groupBase') {
        return 'Group Name';
      } else if (columnName === 'name') {
        return 'New Group Name';
      }
    } else if (this.formType == 'salesPersonGroupForm') {
      if (columnName === 'name') {
        return 'Sale Person Name';
      }
    } else if (this.formType === 'itemFormInventory') {
      if (columnName === 'name') {
        return 'Item Name';
      } else if (columnName === 'stock_Group') {
        return 'Stock Group';
      } else if (columnName === 'unit') {
        return 'Unit';
      } else if (columnName === 'hsn') {
        return 'HSN';
      } else if (columnName === 'cgst') {
        return 'CGST';
      } else if (columnName === 'igst') {
        return 'IGST';
      } else if (columnName === 'sgst') {
        return 'SGST';
      } else if (columnName === 'opening') {
        return 'Quantity';
      }
    } else if (this.formType === 'stockGroupForm') {
      if (columnName === 'name') {
        return 'Item Group';
      } else if (columnName === 'hsn') {
        return 'HSN';
      } else if (columnName === 'sgst') {
        return 'SGST';
      } else if (columnName === 'cgst') {
        return 'CGST';
      } else if (columnName === 'igst') {
        return 'IGST';
      }
    } else if (this.formType === 'godownGroupForm') {
      if (columnName === 'name') {
        return 'Godown Name';
      }
    } else if (this.formType === 'hsnGroupForm') {
      if (columnName === 'name') {
        return 'HSN';
      } else if (columnName === 'igst') {
        return 'IGST';
      } else if (columnName === 'cgst') {
        return 'CGST';
      } else if (columnName === 'sgst') {
        return 'SGST';
      }
    } else if (this.formType === 'salesPriceForm' || this.formType === 'purchaseCostForm') {
      if (columnName === 'aCode') {
        return 'Party';
      }
      if (columnName === 'iCode') {
        return 'Item';
      }
      if (columnName === 'discountPer') {
        return 'Discount %';
      }
      if (columnName === 'partNo') {
        return 'Part No';
      }
      if (columnName === 'rate') {
        return 'Rate';
      }
    } else if (this.formType === 'clientForm') {
      if (columnName === 'urLedgerCodeName') {
        return 'Ledger Name';
      }
      if (columnName === 'emailId') {
        return 'Email Id'
      }
    }
    return columnName;
  }
  renameData(data) {
    if (this.formType === 'ledgerGroupForm') {
      for (var i = 0; i < this.recievedData[1].length; i++) {
        for (var j = 0; j < this.recievedData[4].length; j++) {
          if (this.recievedData[1][i].group == data) {
            if (this.recievedData[1][i].group == this.recievedData[4][j].code) {
              return this.recievedData[4][j].name;
            }
          }
        }
      }
    }
    return data;
  }


  exportToExcel() {
    if (this.url.endsWith("master/accounting/ledger")) {
      this.routerName = "dashboard/master/accounting/ledger/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/voucher")) {
      this.routerName = "dashboard/master/accounting/voucher/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/group")) {
      this.routerName = "dashboard/master/accounting/group/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/cost-center")) {
      this.routerName = "dashboard/master/accounting/cost-center/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/salesmen")) {
      this.routerName = "dashboard/master/accounting/salesmen/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/bill-adjustment/receivables")) {
      this.routerName = "dashboard/master/accounting/bill-adjustment/receivables/excelDownloadTable";
    } else if (this.url.endsWith("master/accounting/bill-adjustment/payables")) {
      this.routerName = "dashboard/master/accounting/bill-adjustment/payables/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/item")) {
      this.routerName = "dashboard/master/inventory/item/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/stock-group")) {
      this.routerName = "dashboard/master/inventory/stock-group/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/godown")) {
      this.routerName = "dashboard/master/inventory/godown/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/sales-price")) {
      this.routerName = "dashboard/master/inventory/sales-price/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/purchase-cost")) {
      this.routerName = "dashboard/master/inventory/purchase-cost/excelDownloadTable";
    } else if (this.url.endsWith("master/inventory/hsn")) {
      this.routerName = "dashboard/master/inventory/hsn/excelDownloadTable";
    }
    this.router.navigate([this.routerName]);
    sessionStorage.setItem("excelColumnData", JSON.stringify(this.excelColumnData))
  }

}
