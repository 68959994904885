<nav class="main-menu"  [ngClass]="{'nav-list-open':sideNavStatus}">
    <div class="side-bar-item">
        <app-menu *ngFor="let item of itemList" [item]="item"></app-menu>
    </div>

    <!-- <div class="side-bar-item-bottom">
        <ul class="logout">
            <li>
                <a (click)="logOut()">
                    <i class="fa icon fa-power-off"></i>
                    <span class="nav-text"> Logout </span>
                </a>
            </li>
        </ul>
    </div> -->
</nav>