import { Component, HostListener, OnInit } from '@angular/core';
import { MatButtonModule} from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import{MatButtonToggleModule} from '@angular/material/button-toggle';
import { Router } from '@angular/router';

import {AccountingMasterComponent} from '../master/accounting-master/accounting-master.component'
import { LedgerFormComponent } from 'src/app/dashboard/master/accounting-master/ledger-form/ledger-form.component';
import { GodownMasterComponent } from 'src/app/dashboard/master/inventory-master/godown-master/godown-master.component';
import { VoucherFormComponent } from 'src/app/dashboard/master/accounting-master/voucher-form/voucher-form.component';
import { GroupFormComponent } from 'src/app/dashboard/master/accounting-master/group-form/group-form.component';
import { SalesPersonComponent } from 'src/app/dashboard/master/accounting-master/sales-person/sales-person.component';

// const MaterialComponent =[
//     MatButtonModule,
//     MatButtonToggleModule
// ]


@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {
  sideNavStatus:boolean =false;
  value="accounting"
  toggleBtn:boolean=false
  customerLogin: boolean;

  constructor(public dialog: MatDialog, private router: Router) { }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key=event.key?.toUpperCase();
    if(key=="A"){
      this.toggleButton1()
    }else if(key == "I"){
      this.toggleButton2()
    }else if(event.key=="Escape"){
      this.dialog.closeAll();
      this.openComponent('dashboard')
    }
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
    document.getElementById('accountingMaster').style.display="Block"
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }

  toggleButton1(){
    this.value="accounting"
    this.toggleBtn=false
    document.getElementById('accountingMaster').style.display="Block"
    document.getElementById('inventoryMaster').style.display="none"
  }
  toggleButton2(){
    this.value="inventory"
    this.toggleBtn=true
    document.getElementById('accountingMaster').style.display="none"
    document.getElementById('inventoryMaster').style.display="Block"
  }
  openLedger() {
    const dialogRef = this.dialog.open(LedgerFormComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openGodown() {
    const dialogRef = this.dialog.open(GodownMasterComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openVoucher() {
    const dialogRef = this.dialog.open(VoucherFormComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openGroup() {
    const dialogRef = this.dialog.open(GroupFormComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openSales() {
    const dialogRef = this.dialog.open(SalesPersonComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
