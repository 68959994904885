import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchText: number)
  {
    if(value.length === 0 || searchText === 0)
    {
      return value;
    }

    const item = [];
    for (const item of value)
    {
      if(item['voucherNo'] === searchText) 
      {
        item.push(item);
      }
    }
    return item
  }

}
