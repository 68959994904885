<div style="margin: 1.5rem;"><h1 style="font-weight:bold;"> User Permission</h1></div>
<div class="container">
  <table border="1">
    <tr class="headerRow">
      <td colspan="5"><h2 style="float: left;">Master Permission</h2></td>
    </tr>
    <tr class="headerRow">
      <th>Sub-Group</th>
      <th>Components</th>
      <th>View Only Permission</th>
      <th>Creation and View Permission <br>( No Alter & Delete) </th>
      <th>All Permission</th>
    </tr>

    <ng-container *ngFor="let group of data">
      <tr class="headerRow" *ngIf = "group.group !== 'Master'">
        <td colspan="5"><h2 style="float: left;">{{ group.group}} Permission</h2></td>
      </tr>
      <ng-container *ngFor="let subGroup of group.subGroup">
        <tr *ngFor="let component of subGroup.subGroupComponent; let i=index">
          <ng-container *ngIf="i === 0">
            <td [attr.rowspan]="subGroup.subGroupComponent.length">
              {{ subGroup.subGroupName }}
            </td>
          </ng-container>
          <td>{{ component }}</td>
          <td><mat-checkbox></mat-checkbox></td>
          <td><mat-checkbox></mat-checkbox></td>
          <td><mat-checkbox></mat-checkbox></td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>
