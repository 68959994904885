import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  sideNavStatus:boolean =false;
  value="accounting"
  toggleBtn:boolean=false
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key=="T"){
      if(this.toggleBtn==false){
        this.toggleButton2()
      }else if(this.toggleBtn==true){
        this.toggleButton1()
      }
    }else if(event.key=="Escape"){
      this.dialog.closeAll();
      this.openComponent('dashboard')
    }
  }
  constructor(public dialog: MatDialog,private router: Router,) { }
  
  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }

  toggleButton1(){
    this.value="accounting"
    this.toggleBtn=false
  }  
  toggleButton2(){
    this.value="inventory"
    this.toggleBtn=true
  }
}