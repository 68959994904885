<!--dash.component.html-->
<div class="grid-container" style="margin-left:50px;">
  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="190px">
    <div style="display: flex;margin-left: 280px; position: relative;">

      <!--Mini Cards-->

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.miniCards?.cols"
      [rowspan]="(cardLayout | async)?.miniCards?.rows"
    >
      <cards>
        <div [ngClass]="{'miniCard': true }">
          <div class="miniCard-container">
            <div class="mat-icon-container">
              <mat-icon>shopping_cart</mat-icon>
            </div>
            <div style="margin-top: 20px;">
            <h4 class="card-subtitle">Sales : {{sales| currency : "INR" }}</h4>
            <h4 class="card-subtitle">GST : {{saleGST | currency : "INR" }}</h4>
          </div>
          </div>
        </div>
      </cards>
    </mat-grid-tile>

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.miniCards?.cols"
      [rowspan]="(cardLayout | async)?.miniCards?.rows"
    >
      <cards>
        <div [ngClass]="{'miniCard': true }">
          <div class="miniCard-container">
            <div class="mat-icon-container">
              <mat-icon>add_shopping_cart</mat-icon>
            </div>
            <div style="margin-top: 20px;">
              <h4 class="card-subtitle">Purchase : {{purchase| currency : "INR" }}</h4>
              <h4 class="card-subtitle">GST : {{purchaseGST | currency : "INR" }}</h4>
            </div>
          </div>
        </div>
      </cards>
    </mat-grid-tile>

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.miniCards?.cols"
      [rowspan]="(cardLayout | async)?.miniCards?.rows"
    >
      <cards>
        <div [ngClass]="{'miniCard': true }">
          <div class="miniCard-container">
            <div class="mat-icon-container">
              <mat-icon>account_balance_wallet</mat-icon>
            </div>
            <div style="margin-top: 20px;">
            <h4 class="card-subtitle">Profit : {{profit | currency : "INR" }}</h4>
            <h4 class="card-subtitle">GST : {{profitGST | currency : "INR" }}</h4>
            </div>
          </div>
        </div>
      </cards>
    </mat-grid-tile>

  </div>
    <!--Charts-->

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.purchaseSalesTrend?.cols"
      [rowspan]="(cardLayout | async)?.purchaseSalesTrend?.rows"
    >
      <cards>
        <h4 class="subtitle">Purchase Vs Sales</h4>
      <purchase-sales-trend></purchase-sales-trend>
    </cards>
    </mat-grid-tile>

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.productSalesTrend?.cols"
      [rowspan]="(cardLayout | async)?.productSalesTrend?.rows"
    >
      <cards>
        <h4 class="subtitle">Product Sales Trends by Month</h4>
        <product-sales-trend></product-sales-trend>
      </cards>
    </mat-grid-tile>

     <!-- Most Sold Itmes -->
      <mat-grid-tile
      [colspan]="(cardLayout | async)?.MostSoldItem?.cols"
      [rowspan]="(cardLayout | async)?.MostSoldItem?.rows"
    >
      <cards>
        <h4 class="subtitle">Top Sold Products</h4>
        <most-sold-items></most-sold-items>
      </cards>
    </mat-grid-tile>

     <!-- least Sold Itmes -->
     <mat-grid-tile
     [colspan]="(cardLayout | async)?.leastSoldItem?.cols"
     [rowspan]="(cardLayout | async)?.leastSoldItem?.rows"
   >
     <cards>
      <h4 class="subtitle">Least Sold Products</h4>
       <least-sold-items></least-sold-items>
     </cards>
   </mat-grid-tile>

    <!--Outstanding Balance-->

    <!-- <mat-grid-tile
      [colspan]="(cardLayout | async)?.outstandingBalance?.cols"
      [rowspan]="(cardLayout | async)?.outstandingBalance?.rows"
    >
      <cards title="Outstanding Balance">
        <outstanding-balance-graph></outstanding-balance-graph>
      </cards>
    </mat-grid-tile> -->

    <!--Table-->
    <!-- <mat-grid-tile
      [colspan]="(cardLayout | async)?.table?.cols"
      [rowspan]="(cardLayout | async)?.table?.rows"
    >
      <cards title="Table"></cards>
    </mat-grid-tile> -->

  </mat-grid-list>
</div>
