<link href="https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;700&display=swap" rel="stylesheet">
<button (click)="exportToPDF()">Export to PDF</button>
<button (click)="exportToExcel()">Export to Excel</button>
<button (click)="switchFormat('dashboard/transactions/accounting/sale-bill/tax-invoice5')">Change Format</button>
<div id="panel" class="invoice-box">
    <table width="90%" border="0" cellpadding="0" cellspacing="0" class="tb">
        <tbody><br>
            <tr height="100">
                <!-- qr code starts -->
                <td align="start" class="txt" id="company_logo">
                    <img src="#" alt="company_logo">
                </td>
                <td align="start" class="txt cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
                </td>

                <table>
                    <tbody>
                        <tr>
                            <td width="30%"><strong><span class="tdData">Name</span></strong>
                            </td>
                            <td width="70%"><span class="tdData colon">{{editData.data.BillNo}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%"><strong><span class="tdData">Phone</span></strong></td>
                            <td width="70%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td width="20%"><strong><span class="tdData">Email</span></strong>
                            </td>
                            <td width="80%"><span class="tdData colon">{{editData.data.paymentTrm}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </tr>
            <tr>
                <td>GSTIN:</td>
                <td align="center" style="width: 50%;" class="txt">TAX INVOICE</td>
                <td height="1" style="font-size: 15px;">ORIGINAL FOR RECIPIENT</td>
            </tr>
            
            <tr>
                <!-- <td width="3%">&nbsp;</td> -->
                <td colspan="12">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                        <tbody>
                            <!-- first table box starts -->
                            <tr class="outerTable bigLineHeight">
                                <table width="50%" border="1" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">Customer Detail</td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Name:</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Address:</span></strong>
                                            </td>
                                            <td width="50%"><span
                                                    class="tdData colon">{{editData.data.paymentTrm}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">PHONE:</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">GSTIN:</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Place of
                                                Supply:</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Invoice No</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.GST}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Invoice Date</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.state}}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Due Date</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.panNo}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- first table box ends -->
                            <!-- second table box starts -->
                            <tr class="outerTable">
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <td></td>
                                    </tbody>
                                </table>
                                </tr>
                            <!-- second table box ends -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                            <td width="3%" height="30" align="center" class="borderLeft borderBottom">
                                                <strong>sr no.</strong>
                                            </td>
                                            <td width="30%" align="center" class="borderLeft borderBottom">
                                                <strong>Name of Product</strong>
                                            </td>
                                            <td width="9%" align="center" class="borderLeft borderBottom">
                                                <strong>HSN/SAC</strong>
                                            </td>
                                            <td width="9%" align="center" class="borderLeft borderBottom">
                                                <strong>Qty</strong>
                                            </td>
                                            <td width="8%" align="center" class="borderLeft borderBottom">
                                                <strong>GST%</strong>
                                            </td>
                                            <td width="10%" align="center" class="borderLeft borderBottom"><strong>Dis %
                                                </strong></td>
                                            <td width="10%" align="center" class="borderLeft borderBottom"><strong>Taxable Value
                                                </strong></td>
                                            <td width="15%" align="center" class="borderLeft borderBottom"><strong>CGST%
                                                </strong></td>
                                            <td width="7.5%" align="center" class="borderLeft borderBottom">
                                                <strong>SGST</strong>
                                            </td>
                                            <td width="7.5%" align="center" class="borderLeft borderBottom">
                                                <strong>Total</strong>
                                            </td>
                                            
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">1.</td>
                                            <td align="start" class="borderLeft">
                                                {{editData.data.INVData[0].Description}}</td>
                                                <td align="center" class="borderLeft">{{editData.data.INVData[0].package}}</td>
                                                <td align="center" class="borderLeft">{{editData.data.INVData[0].hsn}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].gst}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].ActualQty}}
                                            </td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Unit}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Rate}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Amount}}
                                            </td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Amount}}
                                            </td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <tr class="outerTable">
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        </tr>


                            <!-- third table box starts -->
                            <tr class="outerTable">
                                <table border="1">
                                    <tbody>
                                        <tr class="thirdTableData">
                                            <td><strong><span class="tdData">Total in words</span></strong>
                                            </td>
                                        </tr>
                                        <tr class="thirdTableData">
                                            <td><strong><span class="tdData">SGST</span></strong>
                                            </td>
                                            <td><span>{{editData.data.sgst}}</span>
                                            </td>
                                        </tr>
                                        <tr class="thirdTableData">
                                            <td class="borderBottom"><strong><span class="tdData">CGST</span></strong>
                                            </td>
                                            <td class="borderBottom"><span>{{editData.data.cgst}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="40%" class="borderBottom"><strong><span
                                                        class="tdData">Taxable Amount</span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.CGST}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%" class="borderBottom"><strong><span
                                                        class="tdData">Add:CGST</span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.SGST}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%" class="borderBottom"><strong><span class="tdData">Add:SGST
                                                        </span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData">{{editData.data.roundOff}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%" class="borderBottom"><strong><span class="tdData">Total Tax</span></strong>
                                            </td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.BillAmount}}</span></td>
                                        </tr>
                                       
                                        <tr>
                                            <td width="40%" class="borderBottom"><strong><span class="tdData">Total Amount After Tax</span></strong>
                                            </td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.BillAmount}}</span></td>
                                        </tr>
                                        <tr>
                                            </tr>
                                            <td width="100%" style="text-align:right;">(E & O.E.)
                                                </td>

                                    </tbody>
                                </table>
                            </tr>
                            <!-- third table box ends -->


                            <!-- fourth table box starts -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                            <td width="45%" align="left" class="borderLeft lineHeight">
                                                <strong>Subject to our home Jurisdiction.
                                                    Our Responsibility Ceases as soon as goods leaves our Premises.
                                                    Goods once sold will not taken back.
                                                    Delivery Ex-Premises.</strong>
                                            </td>
                                            <td width="20%" align="center" class="borderLeft">
                                                <strong>Receiver's Sign</strong>
                                            </td>
                                            <td width="35%" align="center" class="borderLeft">
                                                <strong>For Anu Computer Consultancy <br><br>Authorised
                                                    Signature</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- fourth table box ends -->
                            <!-- Fifth table box starts -->
                            <tr>
                                <!-- qr code starts -->
                                <td align="start" class="txt" id="qrCode">
                                    <!--qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode-->
                                </td>
                                <!-- qr code ends -->

                            </tr>
                            <!-- fifth table box ends -->

                            <tr>
                                <td colspan="4">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>