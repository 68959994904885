import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReportsService } from 'src/services/reports.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SortByOrder } from 'src/app/shared/sortByOrder';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-item-wise-sales',
  templateUrl: './item-wise-sales.component.html',
  styleUrls: ['./item-wise-sales.component.css'],
})
export class ItemWiseSalesComponent implements OnInit {
  sideNavStatus: boolean = false;
  ledger1Data: any;
  partyData: any;
  showLabel: boolean = false;
  ledShowLabel: boolean = false;
  dateRange: FormGroup;
  passData: any[];
  columnName: any;
  DateFilterData: any = [];
  searchData: any = [];
  balance !: any;
  customerLogin: boolean;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (event.key == "Escape") {
      //Conditions added by Priyanka for issue #464
      if (this.url.endsWith("report/mis-report/item-wise-sales"))
        this.openComponent('dashboard/report/misReport')
      else if (this.url.endsWith("report/stock-item"))
        this.openComponent('dashboard/reports')
    }
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

  disableSelect = new FormControl(false);

  ItemData: any;
  itemForm = this.fb.group({
    'itemlable1': [''],
  });

  itemData: any;
  itemCardData: any = [];
  fillData: any = [];
  data: any = [];
  isLoading: boolean = false;
  selectedLevel: any;
  subTotal: number = 0;
  total: number = 0;
  headTitle: string;
  url: any = window.location.href;
  LLshowLabel: boolean = false;
  searchText: number;
  searchBar: FormGroup;
  filteredOptions: Observable<any[]>;
  totalRowCount: number = 0;
  sortfields = [
    { orderBy: 'date', order: 'asc' },
    { orderBy: 'voucherName', order: 'asc' },
    { orderBy: 'voucherNo', order: 'asc' }
  ];



  constructor(
    private fb: FormBuilder, private router: Router,
    private reportService: ReportsService,
    private masterService: MasterService,
    public appConstants: AppConstants,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('customerLogin') == "true") {
      this.customerLogin = true;
    } else {
      this.customerLogin = false;
    }

    let startYear: number = Number(sessionStorage.getItem('startYear'));
    let startMonth: number = Number(sessionStorage.getItem('startMonth'));

    this.dateRange = this.fb.group({
      'startDate': [new Date(startYear, (startMonth - 1), 1)],
      'endDate': [new Date((startYear + 1), (startMonth - 1), 1)]
    });
    this.searchBar = this.fb.group({
      'searchLabel': ['']
    });
    this.getItemData();

    this.filteredOptions = this.itemForm.controls['itemlable1'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  private _filter(value: string): any[] {
    const filterValue = typeof value === "string" ? value.toLowerCase() : null;
    return this.ItemData.filter(option => (option.iName).toLowerCase().includes(filterValue));
  }


  search(event) {
    var searching = this.searchBar.controls['searchLabel'].value + event.key
    var searches = []
    if (searching == '') {
      this.fillData = this.data
    } else {
      for (var i = 0; i < this.searchData.length; i++) {
        if (this.searchData[i].voucherNo.includes(searching)) {
          searches.push(this.searchData[i])
        }
      }
      this.fillData = searches;
    }
  }

  getItemData() {
    this.isLoading = false;
    let listOfItems = ['item'];
    let optionValList = ['0'];
    this.headTitle = this.url.endsWith("report/mis-report/item-wise-sales") ? "Item Wise Sales" : 'Stock Item';
    this.LLshowLabel = this.url.endsWith("report/mis-report/item-wise-sales") ? true : false;
    this.masterService.getSalesDetails(listOfItems, optionValList).subscribe(response => {
      this.ItemData = response.item0;
      console.log(response);
      this.tableFill(this.ItemData[0]);
    },
      (error => {
        this.isLoading = true;
      }))
  }

  clearInput() {
    this.itemForm.get('itemlable1').reset('')
  }

  displayFn(value?: any) {
    if (value === '') {
      let initialValue: string = (this.ItemData.find(item => item.iCode === this.selectedLevel)).iName;
      return initialValue;
    }
    else {
      return value?.iName;
    }

  }

  tableFill(itemObj: any) {
    this.itemForm.get('itemlable1').setValue(itemObj.iName)

    this.selectedLevel = itemObj.iCode;

    if (this.url.endsWith("report/mis-report/item-wise-sales")) {
      if (this.ItemData) {
        for (var i = 0; i < this.ItemData.length; i++) {
          if (this.ItemData[i].iCode == this.selectedLevel) {
            this.balance = this.ItemData[i].iBalance
            break;
          }
        }
      }
      this.isLoading = false;
      this.showLabel = true;
      this.LLshowLabel = true;
      this.appConstants.code = this.selectedLevel;
      let listOfItems = ['sales'];
      this.reportService.getItemDetails(listOfItems).subscribe(response => {
        this.fillData = response;
        this.passData = [this.appConstants.itemWiseColumnsNames, this.fillData];
        console.log("DataSourec", this.passData);
        this.dataSource = new MatTableDataSource(this.passData[1]);
        this.data = response;
        this.searchData = response;
        this.columnName = this.appConstants.itemWiseColumnsNames;
        this.itemCardData.push(this.fillData);
        this.calculateSubTotal();
        this.calculateTotal();
        this.isLoading = true;
        this.filterBasedOnDates();
      },
        (error => {
          this.isLoading = true;
        })
      )
    }
    else if (this.url.endsWith("report/stock-item")) {
      if (this.ItemData) {
        for (var i = 0; i < this.ItemData.length; i++) {
          if (this.ItemData[i].iCode == this.selectedLevel) {
            this.balance = this.ItemData[i].iBalance
            //console.log("this.balance", this.balance);

            break;
          }
        }
      }
      this.isLoading = false;
      this.ledShowLabel = true;
      this.LLshowLabel = false;
      this.appConstants.code = "A1";
      this.appConstants.code = this.selectedLevel;
      let listOfItems = ['Item'];
      
      this.reportService.getLedgerDetails(listOfItems).subscribe(response => {
        response.forEach((d:any,i:any)=>{
          if (this.ItemData[i].iCode == this.selectedLevel) 
          {
            d.balance =this.ItemData[i].iqty
          }
          // if(i==0){
          //   d.clcBalance = d.balance
          // }else{
          //   d.clcBalance = (response[i-1].clcBalance||0) + (response[i-1].in||0) - (response[i-1].out||0)
          //   // console.log("value--->", response[i-1], (response[i-1].clcBalance||0) + (response[i-1].in||0) - (response[i-1].out||0));
            
          // }

          return d
        })
        this.fillData = response;
        // this.fillData.sort((a, b) => {
        //   const dateA = new Date(a.date).getTime();
        //   const dateB = new Date(b.date).getTime();
          
        //   return dateA - dateB;
        // });
        //console.log("fillll-->", this.fillData);
        //Adding blank row at the end as we display 0th row for opening balance and every next row is displays (i-1)th data.
        let data_row = [['', '', '','','', '', '', '', '', '']];
    
        this.data =[...response,...data_row];
        this.passData = [this.appConstants.stockItemColumnsNames, this.fillData];
        //console.log("DataSourec", this.passData);
        this.dataSource = new MatTableDataSource(this.passData[1]);
        SortByOrder.sortByOrder(this.dataSource.data, this.sortfields);
        
        this.searchData = response;
        this.columnName = this.appConstants.stockItemColumnsNames;
        //this.itemCardData.push(data_row)
        this.itemCardData.push(this.fillData);
        this.calculateSubTotal();
        this.calculateTotal();
        this.isLoading = true;
        this.filterBasedOnDates();
      },
        (error => {
          this.isLoading = true;
          // this.toastr.error("Deleted Unsuccessfull");
        }))

    }
  }
  calculateBalance(balance:any, inValue:any, out:any){
    console.log("balance", balance, inValue, out);
    
    return (balance|| 0)+(inValue||0)-(out||0)
  }

  calculateTotal() {
    this.subTotal = 0;
    this.fillData.forEach((item) => {
      if (item.amount) {
        this.subTotal += item.amount;
      }

    });
  }

  calculateSubTotal() {
    this.total = 0;
    this.fillData.forEach((item) => {
      if (item.amount) {
        this.total += item.amount;
      };
    });
  }

  filterBasedOnDates() {
    let startDate = null;
    let endDate = null;
    let updatedRows = [];
    this.DateFilterData = this.data;
    let varStore = this.DateFilterData;
    // DO not need in Stock item. Will need in Item wise sales.
    /*
    startDate = this.dateRange.controls['startDate'].value != null ? this.datePipe.transform(this.dateRange.controls['startDate'].value, 'MM-dd-yyyy').toString() : '';
    endDate = this.dateRange.controls['endDate'].value != null ? this.datePipe.transform(this.dateRange.controls['endDate'].value, 'MM-dd-yyyy').toString() : '';
  
    for (let element of varStore) {
      let elementDate = element.date !== undefined ? this.datePipe.transform(element.date, 'MM-dd-yyyy').toString() : this.datePipe.transform(element.billDate, 'MM-dd-yyyy').toString();
      if (new Date(startDate) <= new Date(elementDate) && new Date(endDate) >= new Date(elementDate)) {
        updatedRows.push(element);
      }
    }
    */

    if (varStore.length > 0) {
      this.fillData = varStore;
      this.calculateTotal();
    }
    else {
      this.fillData = varStore;
      this.subTotal = 0;
    }
    //console.log("filllDAta--->", this.fillData);
    
    this.fillData.forEach((d:any,i:any)=>{
      if(i==0){
        for (var j = 0; j < this.ItemData.length; j++) {
          if (this.ItemData[j].iCode == this.selectedLevel) {
            d.clcBalance = this.ItemData[j].iqty
            d.balance = this.ItemData[j].iqty
            break;
          }
        }
      }else{
        d.clcBalance = (this.fillData[i-1].clcBalance||0) + (this.fillData[i-1].in||0) - (this.fillData[i-1].out||0)        
      }

      return d
    })
    
    this.dataSource = new MatTableDataSource(this.fillData);
    this.totalRowCount = this.fillData.length;
    this.dataSource.paginator = this.paginator;
  }
}