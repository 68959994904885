import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { CompanyListComponent } from './company-list/company-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupFormComponent } from './dashboard/master/accounting-master/group-form/group-form.component';
import { LedgerFormComponent } from './dashboard/master/accounting-master/ledger-form/ledger-form.component';
import { SalesPersonComponent } from './dashboard/master/accounting-master/sales-person/sales-person.component';
import { VoucherFormComponent } from './dashboard/master/accounting-master/voucher-form/voucher-form.component';
import { GodownMasterComponent } from './dashboard/master/inventory-master/godown-master/godown-master.component';
import { HsnFormComponent } from './dashboard/master/inventory-master/hsn-form/hsn-form.component';
import { ItemFormInventoryComponent } from './dashboard/master/inventory-master/item-form-inventory/item-form-inventory.component';
import { StockGroupFormComponent } from './dashboard/master/inventory-master/stock-group-form/stock-group-form.component';
import { MasterComponent } from './dashboard/master/master.component';
import { FinalAccountComponent } from './dashboard/reports/final-account/final-account.component';
import { ItemWiseColumnarComponent } from './dashboard/reports/mis-report/item-wise-columnar/item-wise-columnar.component';
import { ItemWiseSalesComponent } from './dashboard/reports/mis-report/item-wise-sales/item-wise-sales.component';
import { MisReportComponent } from './dashboard/reports/mis-report/mis-report.component';
import { PartyWiseColumnarComponent } from './dashboard/reports/mis-report/party-wise-columnar/party-wise-columnar.component';
import { PartyWiseSalesComponent } from './dashboard/reports/mis-report/party-wise-sales/party-wise-sales.component';
import { RegisterReportComponent } from './dashboard/reports/register-report/register-report.component';
import { SalesComponent } from './dashboard/reports/register-report/sales/sales.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { StatementOfAccountComponent } from './dashboard/reports/statement-of-account/statement-of-account.component';
import { StockSummaryComponent } from './dashboard/reports/stock-summary/stock-summary.component';
import { ReceiptComponent } from './dashboard/transactions/accounting-transaction/receipt/receipt.component';
import { SaleBillComponent } from './dashboard/transactions/sale-bill/sale-bill.component';
import { TransactionsComponent } from './dashboard/transactions/transactions.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ExcelDownloadTableComponent } from './shared/excel-download-table/excel-download-table.component';
import { TaxInvoiceComponent } from './shared/invoices/tax-invoice/tax-invoice.component';
import { LedgerComponent } from './dashboard/reports/ledger/ledger.component';
import { CostCenterComponent } from './dashboard/master/accounting-master/cost-center/cost-center.component';
import { SalesPriceComponent } from './dashboard/master/inventory-master/sales-price/sales-price.component';
import { PurchaseCostComponent } from './dashboard/master/inventory-master/purchase-cost/purchase-cost.component';
import { BillAdjustmentComponent } from './dashboard/master/accounting-master/bill-adjustment/bill-adjustment.component';
import { PayablesComponent } from './dashboard/master/accounting-master/bill-adjustment/payables/payables.component';
import { StockgroupReportComponent } from './dashboard/reports/stockgroup-report/stockgroup-report.component';
import { CustomerLoginComponent } from './auth/customer-login/customer-login.component';
import { RegisterClientComponent } from './auth/register-client/register-client.component';
import { AuthGaurd } from 'src/services/authGaurd';
import {ProductionInvoiceComponent} from './shared/production-invoice/production-invoice.component';
import { TaxInvoice2Component } from './shared/invoices/tax-invoice2/tax-invoice2.component';
import { TaxInvoice3Component } from './shared/invoices/tax-invoice3/tax-invoice3.component';
import { TaxInvoice4Component } from './shared/invoices/tax-invoice4/tax-invoice4.component';
import { TaxInvoice5Component } from './shared/invoices/tax-invoice5/tax-invoice5.component';
import { UserPermissionComponent } from './setting/user-permission/user-permission.component';
import { DashboardMainComponent } from './revenue-dashboard/main-dashboard/main-dashboard.component';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', canActivate:[AuthGaurd], component: DashboardComponent },
  { path: 'dashboard/master', canActivate:[AuthGaurd], component: MasterComponent },
  { path: 'dashboard/transactions', canActivate:[AuthGaurd], component: TransactionsComponent},
  { path: 'dashboard/reports', canActivate:[AuthGaurd], component: ReportsComponent},
  { path: 'dashboard/master/inventory/stock-group', canActivate:[AuthGaurd], component:StockGroupFormComponent},
  { path: 'dashboard/master/inventory/item', canActivate:[AuthGaurd], component: ItemFormInventoryComponent},
  { path: 'dashboard/master/accounting/group', canActivate:[AuthGaurd], component: GroupFormComponent},
  { path: 'dashboard/master/accounting/voucher', canActivate:[AuthGaurd], component: VoucherFormComponent},
  { path: 'dashboard/master/accounting/ledger', canActivate:[AuthGaurd], component: LedgerFormComponent},
  { path: 'dashboard/master/accounting/salesmen', canActivate:[AuthGaurd], component: SalesPersonComponent},
  { path: 'dashboard/master/inventory/godown', canActivate:[AuthGaurd], component: GodownMasterComponent},
  { path: 'dashboard/master/inventory/hsn' , canActivate:[AuthGaurd], component:HsnFormComponent},
  { path: 'login', component: LoginComponent},
  { path: 'customer-login', component: CustomerLoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'register-client', component: RegisterClientComponent},
  { path: 'dashboard/company-list', canActivate:[AuthGaurd], component: CompanyListComponent},
  { path: 'dashboard/report/register',canActivate:[AuthGaurd], component: RegisterReportComponent},
  { path: 'dashboard/report/statementOfAccount', canActivate:[AuthGaurd], component:StatementOfAccountComponent },
  { path: 'dashboard/report/stock-item', canActivate:[AuthGaurd], component:ItemWiseSalesComponent },
  { path: 'dashboard/report/finalAccount', canActivate:[AuthGaurd], component:FinalAccountComponent },
  { path: 'dashboard/report/misReport',canActivate:[AuthGaurd], component: MisReportComponent},
  { path: 'dashboard/transactions/accounting/sale-bill/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/accounting/sale-bill/tax-invoice2', canActivate:[AuthGaurd], component: TaxInvoice2Component },
  { path: 'dashboard/transactions/accounting/sale-bill/tax-invoice3', canActivate:[AuthGaurd], component: TaxInvoice3Component },
  { path: 'dashboard/transactions/accounting/sale-bill/tax-invoice4', canActivate:[AuthGaurd], component: TaxInvoice4Component },
  { path: 'dashboard/transactions/accounting/sale-bill/tax-invoice5', canActivate:[AuthGaurd], component: TaxInvoice5Component },
  { path: 'dashboard/transactions/accounting/sale-bill/production-invoice', canActivate:[AuthGaurd], component: ProductionInvoiceComponent },
  { path: 'dashboard/transactions/accounting/creditNote/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/accounting/purchase/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/accounting/saleOrder/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/accounting/purchaseOrder/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/accounting/debitNote/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/inventory/material-in/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/inventory/material-out/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/inventory/production/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/transactions/inventory/consumption/tax-invoice', canActivate:[AuthGaurd], component: TaxInvoiceComponent },
  { path: 'dashboard/report/register/sales', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/mis-report/item-wise-sales', canActivate:[AuthGaurd], component:ItemWiseSalesComponent },
  { path: 'dashboard/report/mis-report/party-wise-sales', canActivate:[AuthGaurd], component:PartyWiseSalesComponent },
  { path: 'dashboard/report/ledger', canActivate:[AuthGaurd], component: LedgerComponent },
  { path: 'dashboard/report/register/item-wise-columnar', canActivate:[AuthGaurd], component: ItemWiseColumnarComponent },
  { path: 'dashboard/report/register/party-wise-columnar', canActivate:[AuthGaurd], component: PartyWiseColumnarComponent },
  { path: 'dashboard/transactions/accounting/sale-bill', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/accounting/creditNote', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/accounting/purchase', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/accounting/saleOrder', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/accounting/purchaseOrder', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/accounting/debitNote', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/inventory/material-in', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/inventory/material-out', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/inventory/production', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/transactions/inventory/consumption', canActivate:[AuthGaurd], component: SaleBillComponent},
  { path: 'dashboard/company-list', canActivate:[AuthGaurd], component: CompanyListComponent},
  { path: 'dashboard/transactions/accounting/receipt',canActivate:[AuthGaurd], component:ReceiptComponent},
  { path: 'dashboard/transactions/accounting/payment',canActivate:[AuthGaurd], component:ReceiptComponent},
  { path: 'dashboard/report/register/purchase', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/production', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/sales-order', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/material-out', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/material-in', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/debit-note', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/credit-note', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/consumption', canActivate:[AuthGaurd], component:SalesComponent },
  {path: 'dashboard/transactions/accounting/contra', canActivate:[AuthGaurd], component:ReceiptComponent},
  {path: 'dashboard/transactions/accounting/deposit', canActivate:[AuthGaurd], component:ReceiptComponent},
  {path: 'dashboard/transactions/accounting/withdraw', canActivate:[AuthGaurd], component:ReceiptComponent},
  {path: 'dashboard/report/register/receipt', canActivate:[AuthGaurd], component:SalesComponent },
  {path:'dashboard/report/register/payment', canActivate:[AuthGaurd], component:SalesComponent},
  { path: 'dashboard/report/register/contra', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/deposit', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/withdraw', canActivate:[AuthGaurd], component:SalesComponent },
  { path: 'dashboard/report/register/purchase-order', canActivate:[AuthGaurd], component:SalesComponent},
  { path: 'dashboard/report/stock-summary', canActivate:[AuthGaurd], component:StockSummaryComponent},
  { path: 'dashboard/report/cash-book', canActivate:[AuthGaurd], component:LedgerComponent},
  { path: 'dashboard/report/register/sales/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/cost-center', canActivate:[AuthGaurd], component:CostCenterComponent},
  { path: 'dashboard/master/inventory/sales-price', canActivate:[AuthGaurd], component:SalesPriceComponent},
  { path: 'dashboard/master/inventory/purchase-cost', canActivate:[AuthGaurd], component:PurchaseCostComponent},
  { path: 'dashboard/report/register/purchase/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/debit-note/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/credit-note/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/material-in/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/material-out/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/production/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/consumption/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/sales-order/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/receipt/excelDownloadTable', canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/payment/excelDownloadTable', canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/contra/excelDownloadTable', canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/deposit/excelDownloadTable', canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/report/register/withdraw/excelDownloadTable', canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/bill-adjustment',canActivate:[AuthGaurd], component:BillAdjustmentComponent},
  { path: 'dashboard/master/accounting/bill-adjustment/receivables',canActivate:[AuthGaurd], component:PayablesComponent},
  { path: 'dashboard/master/accounting/bill-adjustment/payables',canActivate:[AuthGaurd], component:PayablesComponent},
  { path: 'dashboard/report/stockgroup-report', canActivate:[AuthGaurd], component: StockgroupReportComponent},
  { path: 'dashboard/master/accounting/ledger/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/voucher/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/group/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/cost-center/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/salesmen/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/bill-adjustment/receivables/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/accounting/bill-adjustment/payables/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/item/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/stock-group/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/godown/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/sales-price/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/purchase-cost/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'dashboard/master/inventory/hsn/excelDownloadTable',canActivate:[AuthGaurd], component:ExcelDownloadTableComponent},
  { path: 'user-permission',canActivate:[AuthGaurd], component:UserPermissionComponent},
  { path: 'revenue-dashboard',canActivate:[AuthGaurd], component: DashboardMainComponent},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
