<div style="height: 100vh; padding-left: 0%;">
    <app-header></app-header>
    <app-side-nav></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>

                <div class="main-container">
                <mat-toolbar>
                    <nav class="headTitle">Credit Type</nav>
                </mat-toolbar>
            </div>

            <!-- Button -->
            <div class="btn-container">
                <button mat-raised-button (click)="openComponent('dashboard/master/accounting/bill-adjustment/receivables')"><span class="highlighter">R</span>eceivables</button>
                <button mat-raised-button (click)="openComponent('dashboard/master/accounting/bill-adjustment/payables')"><span class="highlighter">P</span>ayables</button>
            </div>
            <!-- Body content end -->
            
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>