import { LocationStrategy } from '@angular/common';
import { ElementRef } from '@angular/core';
import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { from } from 'rxjs';
import { CompanyListComponent } from '../company-list/company-list.component';
import { CompanyComponent } from '../company/company.component';
import { LoginComponent } from '../auth/login/login.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  disableRouting: boolean = false;
  sideNavStatus:boolean =false;



  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var EleTagName = document.getElementsByTagName("app-company-list");
    if(EleTagName.length > 0){
      this.disableRouting = true;
    }else{
      this.disableRouting = false;
    }
    var key=event.key?.toUpperCase();
    if(this.disableRouting){
      event.stopPropagation();
    }else{
      if(key == 'M'){
        this.openComponent('dashboard/master')
      }else if(key == 'T'){
        this.openComponent('dashboard/transactions')
      }else if(key=='R'){
        this.openComponent('dashboard/reports')
      }
    }
  }

  customerLogin:boolean;

  constructor(public dialog: MatDialog,private router: Router) {
   }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
