<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer-content style="min-height: 90vh;">

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">register</nav>
                </mat-toolbar>
            </div>
            <!-- Body Start -->
            <div class="container">

                <div #widgetsContent class="middle">

                    <div class="wrapper">
                        <div class="cardContainer scrolls" fxLayoutGap="5px">
                            <!-- Card Cantent -->
                            <!-- <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"

                                    (click)="openComponent('dashboard/report/register/ledger')">Ledger</mat-card-title>
                            </mat-card> -->
                            <!-- Card Cantent -->
                            <!-- <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/item-report')">Item Report
                                </mat-card-title>
                            </mat-card> -->

                            <!-- Sales -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/sales')"><span
                                        class="highlighter">S</span>ale
                                </mat-card-title>
                            </mat-card>

                            <!-- Purchase -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/purchase')"><span class="highlighter">P</span>urchase
                                </mat-card-title>
                            </mat-card>

                            <!-- Sales order -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/sales-order')">Sales <span
                                        class="highlighter">O</span>rder
                                </mat-card-title>
                            </mat-card>

                            <!-- Purchase Order -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle" (click)="openComponent('dashboard/report/register/purchase-order')">Purchase Or<span class="highlighter">d</span>er
                            </mat-card-title>
                            </mat-card>

                            <!-- Receipt -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/receipt')"><span class="highlighter">R</span>eceipt
                                </mat-card-title>
                            </mat-card>

                            <!-- Credit Note -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/credit-note')">Credit <span class="highlighter">N</span>ote
                                </mat-card-title>
                            </mat-card>

                            <!-- Debit Note -->
                            <mat-card class="cardProduct">
                            <mat-card-title class="cardTitle"
                            (click)="openComponent('dashboard/report/register/debit-note')">De<span class="highlighter">b</span>it Note
                        </mat-card-title>
                            </mat-card>

                            <!-- Contra -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                (click)="openComponent('dashboard/report/register/contra')">Contr<span class="highlighter">a</span></mat-card-title>
                            </mat-card>

                            <!-- Deposit -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/deposit')">D<span
                                        class="highlighter">e</span>posit
                                </mat-card-title>
                            </mat-card>

                            <!-- Withdraw -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/withdraw')"><span
                                        class="highlighter">W</span>ithdraw
                                </mat-card-title>
                            </mat-card>

                            <!-- Payment -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/payment')">Pa<span class="highlighter">y</span>ment
                                </mat-card-title>
                            </mat-card>

                            <!-- Cash book -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle" (click)="openComponent('dashboard/report/cash-book')">
                                    Cash Boo<span class="highlighter">k</span></mat-card-title>
                            </mat-card>

                            <!-- Material in -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/material-in')"><span
                                        class="highlighter">M</span>aterial In
                                </mat-card-title>
                            </mat-card>

                            <!-- Material Out -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/material-out')">Material O<span
                                        class="highlighter">u</span>t
                                </mat-card-title>
                            </mat-card>

                            <!-- Production -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/production')">Product<span
                                        class="highlighter">i</span>on
                                </mat-card-title>
                            </mat-card>

                            <!-- Consumption -->
                            <mat-card class="cardProduct">
                                <mat-card-title class="cardTitle"
                                    (click)="openComponent('dashboard/report/register/consumption')"><span
                                        class="highlighter">C</span>onsumption
                                </mat-card-title>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <!--  Left/Right icons for scroll controls -->
                <button (click)="scrollLeft()" class="prev" mat-fab color="primary"><span
                        class="arrowIcon">&#10094;</span></button>

                <button (click)="scrollRight()" class="next" mat-fab color="primary"><span
                        class="arrowIcon">&#10095;</span></button>
            </div>
            <!-- Body Content ends -->
            
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>
