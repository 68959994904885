import { LocationStrategy } from '@angular/common';
import { error } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { CompanyListComponent } from '../../company-list/company-list.component';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.css']
})
export class CustomerLoginComponent implements OnInit {
  hide = true;
  isLoading: boolean = false;


  loginForm= this.fb.group({
    'serialLabel':['',Validators.required],
    'userLabel':['',Validators.required],
    'passwordLabel':['',Validators.required],
  });
  distributorList: any;
  List: any=[];
  serial: any;


  constructor(private router: Router,
    private fb:FormBuilder,
    private masterService: MasterService,
    public dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr:ToastrService,
    ) {}
  ngOnInit(): void {
    this.resetLoginForm()
    this.getDistributor()
  }
  resetLoginForm(){
    this.isLoading=true;
    this.loginForm= this.fb.group({
      'serialLabel':['',Validators.required],
      'userLabel':['',Validators.required],
      'passwordLabel':['',Validators.required],
    });;
  }
  getDistributor(){
    this.isLoading=false
    this.masterService.getDistributor().subscribe(response=>{
      this.distributorList=response
      this.isLoading=true
    })
  }
  saveData(value:any){
    sessionStorage.setItem('Serial',this.distributorList.find(i => i.soCode === value).serial)
    sessionStorage.setItem('ServerVal',this.distributorList.find(i => i.soCode === value).serverVal)
    sessionStorage.setItem('CompanyCode',this.distributorList.find(i => i.soCode === value).companyCode)
    sessionStorage.setItem('companyName',this.distributorList.find(i => i.soCode === value).companyName)
  }
  login(component:string){
    if (this.loginForm.invalid) {
    }else{
      this.isLoading=false;
      const generatedData = {
        Serial:this.loginForm.controls['serialLabel'].value,
        Password:this.loginForm.controls['passwordLabel'].value,
        Email : this.loginForm.controls['userLabel'].value,
        IsSalesOrder : true,
      }
      this.masterService.LoginForm(generatedData).subscribe(response=>{
        sessionStorage.setItem("customerLogin","true")
        this.List=response.split("|");
        sessionStorage.setItem("Token",this.List[0]);
        sessionStorage.setItem("List[1]",this.List[1]);
        sessionStorage.setItem("List[2]",this.List[2]);
        sessionStorage.setItem("List[3]",this.List[3]);
        sessionStorage.setItem("startYear",this.List[4]);
        sessionStorage.setItem("startMonth",this.List[5]);
        this.router.navigate(['/'+component]);
        this.isLoading=true
      },
      (error=>{
        this.isLoading=true;
        this.toastr.error("Try to login again","Login Error");
      }))
    }
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
