import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MasterService } from 'src/services/master.service';
import { Router } from '@angular/router';
import { CompanyComponent } from 'src/app/company/company.component';
import { AppConstants } from 'src/services/AppConstants';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HeaderUpdateService } from 'src/services/header-update.service';
import { ConfirmDialogModel } from '../shared/confirmation-box/confirmDialog.model';
import { ConfirmationBoxComponent } from '../shared/confirmation-box/confirmation-box.component';




@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css'],
  providers: [DatePipe]
})
export class CompanyListComponent implements OnInit {
  companies: any;
  companiList: any;
  isLoading: boolean = false;
  companyData: any;
  date: Date;
  currentDate: string;
  close: string;
  companySearch: string = '';


  constructor(private router: Router,
    private masterService: MasterService, private dialog: MatDialog,
    private toastr: ToastrService, private appConstants: AppConstants, private datePipe: DatePipe,
    private newHeaderUpdate: HeaderUpdateService) { }

  ngOnInit() {
    this.getClistData();
    this.date = new Date();
    this.currentDate = this.datePipe.transform(this.date, 'yyyy-MM-dd');
  }

  getClistData() {
    let listOfItems = ['CompanyList'];
    let optionValList = ['0']
    this.masterService.getCompany(listOfItems, optionValList).subscribe(response => {
      this.companies = response;
      this.companiList = response;
      this.isLoading = true;
    },
      (error => {
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  closeCompany() {
    if (sessionStorage.getItem('companyName') != null) {
      this.dialog.closeAll();
    } else {
      this.toastr.warning("Select any company", "Do not redirect")
    }

  }
  openCompany() {
    const dialogRef = this.dialog.open(CompanyComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getClistData();
    });
  }

  selectCompany(saveData) {

    sessionStorage.setItem('companyName', saveData.companyName);
    sessionStorage.setItem('companyStateCode', saveData.companyStateCode);
    sessionStorage.setItem('companyState', saveData.companyState);
    sessionStorage.setItem('companyAddress', saveData.companyAddress);
    sessionStorage.setItem('CompanyCode', saveData.companyCode);
    sessionStorage.setItem('ServerVal', saveData.serverVal);
    sessionStorage.setItem('bank_name', saveData.bankName);
    sessionStorage.setItem('branch', saveData.branch);
    sessionStorage.setItem('account_no', saveData.accountNo);
    sessionStorage.setItem('ifsc_code', saveData.ifscCode);
    sessionStorage.setItem('startMonth', saveData.startMonth);
    sessionStorage.setItem('startYear', saveData.startYear);
    sessionStorage.setItem('gst_no', saveData.gstNo);
    sessionStorage.setItem('contact_no', saveData.contactNo);

    this.appConstants.companyStateCode = saveData.companyStateCode;

    this.date = saveData.planEndDate;
    let planEndDate = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    sessionStorage.setItem('planEndDate', planEndDate);
    if (this.currentDate > sessionStorage.getItem("planEndDate")) {
      this.toastr.warning("Can't add company", 'Your plan have expired. Please renew', {
        timeOut: 6000,
      }
      );
    }
    this.toastr.success("Company Selected"), {
      timeOut: 2000,
    }
    this.redirect('/dashboard');
    this.newHeaderUpdate.headerUpdate();
  }

  redirect(url: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]))
  }

  editCompany(rowData: any) {
    const dialogRef = this.dialog.open(CompanyComponent, { data: { 'companyForm': rowData, 'editFlow': true } });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Result", result)
      this.getClistData();
    });
  }
  viewCompany(rowData: any) {
    const dialogRef = this.dialog.open(CompanyComponent, { data: { 'companyForm': rowData, 'view': true } });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("Result",result)
      this.getClistData();
    });
  }

  deleteCompany(row) {
    let dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: "30%",
      height: "40%",
      data: ConfirmDialogModel.ForDelete()
    });

    dialogRef.afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.companies.forEach((value: any, index: any) => {
            if (value == row) {
              this.companies.splice(index, 1);
              const generatedData = {
                Serial: sessionStorage.getItem("Serial"),
                CompanyCode: row.companyCode
              }
              this.masterService.deleteCompany(generatedData).subscribe(response => {
                alert("Deleted Successfully")
              },
                (error => {
                  this.isLoading = true;
                  alert("Unable To Delete Item");
                })
              );
            }
          });
        }
      });
  }

  searchCompany() {
    if (this.companySearch?.length == 0) {
      this.companies = this.companiList
    }
    else {
      this.companies = this.companiList.filter((search: any) =>
        search.companyName.toLowerCase().includes(this.companySearch.toLowerCase())
      )
    }
  }
}
