import { Component, OnInit } from '@angular/core';
import { ItemSoldDetails } from 'src/app/model/itemSoldDetails';
import { DashboardService } from 'src/services/dashboard.service';

@Component({
  selector: 'product-sales-trend',
  templateUrl: './product-sales-trend.component.html',
  styleUrls: ['./product-sales-trend.component.css']
})
export class ProductSalesTrendComponent implements OnInit {

  selectedValue: string = ''
  data : ItemSoldDetails[] | undefined ;
  uniqueItemList : string[] = [];
  graphData: number[] = [] ;
  productSalesGraph: ApexCharts;

  constructor(private readonly dashboardService: DashboardService) { }

  ngOnInit(): void {

    this.dashboardService.getDetailsOfSoldItem().subscribe(result => {
      if(result.length != 0){
        this.data = result.monthly || []
        this.selectedValue = result.monthly[0]?.name;
        let itemList = (this.data).flatMap(item => item.name)
        this.uniqueItemList = [...new Set(itemList)];
        this.getMonthlySoldQtyOfItem(this.selectedValue);
      }

    });

  }

  genrateProductSalesGraph(monthlyData: number[] , item: string) {

    var options = {
      series: [{
        name: item,
        data: monthlyData
      }],
      chart: {
        height: 230,
        type: 'line',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      }
    };
    if (this.productSalesGraph) {
      this.productSalesGraph.updateSeries([{ data: monthlyData, name: item }]);
    }
    else {
      this.productSalesGraph = new ApexCharts(document.querySelector("#productSalesGraph"), options);
      this.productSalesGraph.render();
    }
  }


  getMonthlySoldQtyOfItem(itemName: string): void {

    const filteredData = this.data.filter((item) => item.name === itemName && item.qtySold !== null);
    let monthlySoldQty = []

    for (let i = 0; i < 12; i++) {
      monthlySoldQty[i] = { qtySold: 0 };
    }

    for (const item of filteredData) {
      const monthIndex = item.month - 1;
      monthlySoldQty[monthIndex].qtySold += item.qtySold;
    }

    this.graphData = monthlySoldQty.flatMap(item => item.qtySold)

    this.genrateProductSalesGraph(this.graphData, itemName);
  }

}
