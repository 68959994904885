import { AfterViewInit, Component, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-each-item-detail',
  templateUrl: './each-item-detail.component.html',
  styleUrls: ['./each-item-detail.component.css']
})
export class EachItemDetailComponent implements OnInit,AfterViewInit {
  @Output() public emitData = new EventEmitter();
  displayColumns!: any;
  data!:any;
  indexOfItem!: any;
  finalAdditionalItemData!: any;
  editIndex:any;
  @ViewChild('newItemForm') newItemForm! : TemplateRef<any>;

  itemForm = this.fb.group({
    ponoLabel:[''],
    poDateLabel:[''],
    challanLabel:[''],
    challanDateLabel:[''],
    freeQLabel:[''],
    godownLabel:[''],
    batchLabel:['']
  });

  constructor(private fb: FormBuilder,
              private masterService: MasterService,
              private dialog: MatDialog, private router: Router,
              @Inject(MAT_DIALOG_DATA) public inputData,
              private dialogRef: MatDialogRef<EachItemDetailComponent >) { }

  ngOnInit() {
    this.indexOfItem = this.inputData.index;
    this.resetItemForm();
  }

  resetItemForm(){
    this.itemForm.reset();
  }

  ngAfterViewInit(): void {

    this.editIndex = this.inputData?.index;
    if(this.inputData.viewMode && this.inputData?.previousAdditionalData && this.inputData?.previousAdditionalData[this.inputData?.index] ){
      this.itemForm.disable();
      this.itemForm.patchValue(this.inputData.previousAdditionalData[this.inputData?.index]);
      this.finalAdditionalItemData = this.inputData.previousAdditionalData[this.inputData?.index];
    }
    else if(this.inputData?.previousAdditionalData && this.inputData?.previousAdditionalData[this.inputData?.index]){
      this.itemForm.patchValue(this.inputData.previousAdditionalData[this.inputData?.index]);
      this.finalAdditionalItemData = this.inputData.previousAdditionalData[this.inputData?.index];
    }
    else{
      this.itemForm.reset();
    }
  }

  addItem(){
    if(this.itemForm.invalid){
    }else{
      const generatedData = {
        ponoLabel : this.itemForm.controls['ponoLabel'].value,
        poDateLabel : this.itemForm.controls['poDateLabel'].value,
        challanLabel : this.itemForm.controls['challanLabel'].value,
        challanDateLabel : this.itemForm.controls['challanDateLabel'].value,
        freeQLabel : this.itemForm.controls['freeQLabel'].value,
        godownLabel : this.itemForm.controls['godownLabel'].value,
        batchLabel : this.itemForm.controls['batchLabel'].value,
        'index' : this.indexOfItem
      }
      this.finalAdditionalItemData = generatedData;
      this.dialogRef.close('submit');

    }
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
