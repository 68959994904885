import { Component, Inject, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx'; 
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tax-invoice5',
  templateUrl: './tax-invoice5.component.html',
  styleUrls: ['./tax-invoice5.component.css']
})
export class TaxInvoice5Component implements OnInit {
  editData: any;
  ledger1Data: any;
  companyName: string;
  companyAddress: string;
  BankName: string;
  BranchLabel: string;
  AccountNoLabel: string;
  IFSCCodeLabel: string;
  qrdata: string;
  routerName: any;

  constructor(private route: ActivatedRoute, private router: Router) {    
  }

  ngOnInit(): void {
    this.BankName=sessionStorage.getItem('BankName')
    this.companyAddress=sessionStorage.getItem('companyAddress')
    this.companyName=sessionStorage.getItem('companyName')
    this.BranchLabel=sessionStorage.getItem('BranchLabel')
    this.AccountNoLabel=sessionStorage.getItem('AccountNoLabel')
    this.IFSCCodeLabel=sessionStorage.getItem('IFSCCodeLabel')
    this.route.paramMap.subscribe(() =>{
      this.editData=window.history.state,
      this.ledger1Data = window.history.state.extraData.ledger1Data;
      });
      this.qrdata = 
          '1. Bill No:' + this.editData.data.BillNo +'\n'+
          '2. Date: ' + this.editData.data.BillDate +'\n'+
          // '3. Item: ' + this.editData.data.INVData[0].Description +'\n'+
          // '4. Qty: ' + this.editData.data.INVData[0].ActualQty +'\n'+
          // '5. Rate: ' + this.editData.data.INVData[0].Rate +'\n'+
          // '6. Taxable: 'taxable
          '7. SGST: ' + this.editData.data.Sgst +'\n'+
          '8. CGST: ' + this.editData.data.Cgst +'\n'+
          '9. IGST: ' + this.editData.data.Igst +'\n'+
          '10. Grand Total: ' + this.editData.data.BillAmount
          // '11. Vendor Code: 'alias'
          ;
  }
  getPartyName(data){
    for(var i=0;i<this.ledger1Data.length;i++){
      if(this.ledger1Data[i].code == data){
        return this.ledger1Data[i].name
      }
    }
  }

  getPartyAdd(data){
    for(var i=0;i<this.ledger1Data.length;i++){
      if(this.ledger1Data[i].code == data){
        return this.ledger1Data[i].address
      }
    }
  }

  public exportToPDF():void {
    let DATA = document.getElementById('panel');
        
    html2canvas(DATA).then(canvas => {
        
        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;
        
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        
        PDF.save('saleBill.pdf');
    });     
  }

  public exportToExcel():void {
    /* table id is passed over here */   
    let element = document.getElementById('panel'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'saleBillExcel.xlsx');     
  }
  switchFormat(component: string) {
    console.log('switch is working');
    this.router.navigate(['/'+component]);
  }
}
