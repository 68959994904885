<form mat-form-field class="search">
  <input (keyup)="applyFilter($event)" class="search-input" placeholder="Search..." #input>
</form>
<i class="fa fa-file-excel-o" style="font-size:36px;color:green; cursor: pointer;" (click)="exportToExcel()" *ngIf="!errorValue"></i>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" style="margin-left: 3px;margin-right: 25px;">
    <ng-container *ngFor="let col of this.recievedData[0]">
      <ng-container matColumnDef="{{col}}" *ngIf="col != 'action'">
        <th class="tableHeading" mat-header-cell *matHeaderCellDef>{{renameColumn(col)}}</th>
        <td mat-cell *matCellDef="let element">{{renameData(element[col])}}</td>
      </ng-container>
      <ng-container matColumnDef="action" *ngIf="col === 'action'">
        <th class="tableHeading" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" style="text-align: right; padding-right: 2px;">
          <button mat-icon-button color="primary" (click)="view(row)" *ngIf="viewIcon">
            <mat-icon matTooltip="view">visibility</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="edit(row)">
            <mat-icon matTooltip="edit">edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(row)">
            <mat-icon matTooltip="delete">delete</mat-icon>
          </button>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="this.recievedData[0]"></tr>
    <tr mat-row *matRowDef="let row; columns: this.recievedData[0];"></tr>
  </table>
  <table class="errorTable" *ngIf="errorValue">
    <tr>Unable to retrieve data.<br><br>Please refresh your page or check your internet connection</tr>
  </table>
  <!-- paginator -->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Data">
  </mat-paginator>

</div>
