<div style="min-height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container" >
        <mat-drawer-content style="min-height: 100vh;" >
            <!-- ************* -->
            <!-- Receipt Work Body part start -->
            <!-- ************* -->
            <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
            <div *ngIf="isLoading">
                <!-- <div id="toggle" (click)="drawer.toggle()" #toggleBtn3 ><mat-icon>format_align_justify</mat-icon></div> -->
                <!-- Head Part -->
                <div class="main-containers page-header">
                    <mat-toolbar>
                        <nav class="headTitle">{{this.headTitle}}</nav>
                    </mat-toolbar>
                    <form [formGroup]="receiptFormGroup">
                        <mat-card class="divide">
                            <table>
                                <tr>
                                    <td *ngIf="showLabel" >
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label>Voucher No.</mat-label>
                                            <input type="text" matInput formControlName="billNo">
                                            <mat-error *ngIf="receiptFormGroup.get('billNo').hasError('duplicateValue')">Duplicate Voucher Number</mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label >Voucher</mat-label>
                                            <mat-select formControlName="voucherNo" (selectionChange)="updateBillDateAndNo($event)">
                                                <mat-option>Select</mat-option>
                                                <mat-option *ngFor="let voucher of voucherData;" [value]="voucher.code">{{voucher.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label>Date</mat-label>
                                            <input type="date" [min]="minDate" [max]="maxDate" matInput formControlName="date">
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr *ngIf="rShowLabel">
                                    <td style="text-align: center;">
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label>Reference Number</mat-label>
                                            <input type="text" matInput formControlName="refNo">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label>Reference Date</mat-label>
                                            <input type="date" matInput formControlName="refDate">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                      <mat-form-field class="example-full-width" appearance="fill">
                                        <mat-label>{{this.cash}}</mat-label>
                                      <input type="text" placeholder="Pick one" aria-label="Number" class="form-control" id="cashBank" formControlName="cashBank" matInput
                                      [matAutocomplete]="auto">
                                      <mat-autocomplete  #auto="matAutocomplete">
                                        <mat-option [value]="" disabled>-Select Option-</mat-option>
                                        <mat-option *ngFor="let cashBank of filteredOptions|async" [value]="cashBank.name">{{cashBank.name}}</mat-option>
                                        </mat-autocomplete>
                                        </mat-form-field>
                                        <mat-error>
                                          <div *ngIf="receiptFormGroup.get('cashBank').hasError('noMatch')">
                                            {{validationMsg}}
                                          </div>
                                        </mat-error>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="fill" class="example-full-width">
                                            <mat-label>Amount</mat-label>
                                            <input matInput formControlName="amount1" (change)="setTwoNumberDecimal($event)">
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                        </mat-card>
                        <mat-card class="divide mdiv">
                            <div class="fmiddle">
                                <span class="add" style="float: right;">+</span>
                                <table>
                                    <tr>
                                        <td>
                                          <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>{{this.particular}}</mat-label>
                                          <input type="text" placeholder="Pick one" aria-label="Number" class="form-control" id="particular" formControlName="particular" matInput
                                          [matAutocomplete]="autoParticular" >
                                          <mat-autocomplete  #autoParticular="matAutocomplete" >
                                            <mat-option [value]="" disabled>-Select Option-</mat-option>
                                            <mat-option *ngFor="let particular of filteredOptionsParticulars|async" [value]="particular.name">{{particular.name}}</mat-option>
                                            </mat-autocomplete>
                                            </mat-form-field>
                                            <mat-error>
                                              <div *ngIf="receiptFormGroup.get('particular').hasError('noMatch')">
                                                {{validationMsg}}
                                              </div>
                                            </mat-error>
                                        </td>

                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Amount</mat-label>
                                                <input matInput formControlName="amount2" (change)="setTwoNumberDecimal($event)">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-card>
                        <mat-card class="divide" >
                            <div class="fmiddle">
                                <table>
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Cheque No.</mat-label>
                                                <input type="text" matInput formControlName="chequeNo">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Cheque Date</mat-label>
                                                <input type="date" matInput formControlName="chequeDate">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Banker</mat-label>
                                                <input type="text" matInput formControlName="banker">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Narration</mat-label>
                                                <textarea type="text" matInput formControlName="narration"></textarea>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-form-field appearance="fill" class="example-full-width">
                                                <mat-label>Clear Date</mat-label>
                                                <input type="date" matInput formControlName="clearDate">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-card>
                    </form>
                    <mat-card fxFlex='100' class="mBtn" *ngIf="!forView">
                        <button mat-flat-button [disabled] = "disableSubmitButton" color="primary" class="sBtn {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="saveReceipt()">Submit</button>
                    </mat-card>
                    <mat-card fxFlex='100' class="mBtn" *ngIf="forView">
                        <button mat-flat-button color="primary" class="sBtn" (click)="backReceipt()">Back</button>
                    </mat-card>
                </div>
            <!-- ************* -->
            <!-- Receipt Work Body part end -->
            <!-- ************* -->
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
<!-- <app-footer></app-footer> -->
