import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MasterService } from 'src/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AppConstants } from 'src/services/AppConstants';
import { ConfirmationBoxComponent } from '../shared/confirmation-box/confirmation-box.component';
import { ConfirmDialogModel } from '../shared/confirmation-box/confirmDialog.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
})
export class CompanyComponent implements OnInit {
  @Output() newCompanyEvent = new EventEmitter<string>();
  disableSelect = new FormControl(false);
  @ViewChild('newcompanyForm') newCompanyForm!: TemplateRef<any>;

  showErrorAlert: boolean = false;
  isLoading: boolean = false;
  btn_loader: boolean = false;
  stateData: any;
  nrSelect = 'NOT APPLICABLE';
  selectedYear: number;
  years: number[] = [];
  editable = false;
  viewMode : boolean = false;
  months: any[] = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ]

  companyForm = this.fb.group({
    companyNameLabel: ['', Validators.required],
    addressLabel: [''],
    stateLabel: ['', Validators.required],
    cityLabel: [''],
    pinLabel: [''],
    stateCodeLabel: [''],
    contactLabel: ['',[Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
    gstTypeLabel: [''],
    gstNoLabel: [''],
    startYearLabel: [''],
    startMonthLabel: ['4'],
    BankName: [''],
    BranchLabel: [''],
    AccountNoLabel: [''],
    IFSCCodeLabel: [''],
  });

  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    private dialog: MatDialog,
    private router: Router,
    private appConstants: AppConstants,
    @Inject(MAT_DIALOG_DATA) public inputData,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<CompanyComponent>
  ) { }

  ngAfterViewInit(): void {
    if (this.inputData != null && this.inputData != undefined) {
      this.patchCompanyFormValues(this.inputData.companyForm);
    }
  }

  ngOnInit() {
    this.selectedYear = new Date().getFullYear();
    for (
      let year = this.selectedYear + 4;
      year >= this.selectedYear - 4;
      year--
    ) {
      this.years.push(year);
    }
    this.getStateData();
    this.viewMode = this.inputData?.view
  }

  getStateData() {
    this.isLoading = false;
    let listOfItems = ['stateList'];
    let optionValList = ['0'];
    this.masterService.getStateData(listOfItems, optionValList).subscribe(
      (response) => {
        sessionStorage.setItem('StateData', JSON.stringify(response));
        this.stateData = JSON.parse(sessionStorage.getItem('StateData'));
        if (this.inputData === undefined || this.inputData === null) return;
        this.patchCompanyFormValues(this.inputData.companyForm);
      },
      (error) => {
        this.isLoading = true;
        this.toastr.error('Unable To Retrieve Data', 'Data Retrieve Error');
      }
    );
  }

  resetCompanyForm() {
    this.showErrorAlert = false;
    this.companyForm.reset();
  }

  addCompany() {
    if (!this.companyForm.value.gstNoLabel) {
      const confirmDialog = this.dialog.open(ConfirmationBoxComponent, {
        data: ConfirmDialogModel.ForConfirm()
      });
      confirmDialog.afterClosed()
        .subscribe(confirmed => {
          if (confirmed == true) {
            confirmDialog.close()
            this.dialogRef.close();
          } else {
            confirmDialog.close()
          }
        });
    }
    this.showErrorAlert = true;
    if (this.companyForm.invalid) {
      this.showErrorAlert = true;
      this.toastr.error('Some Error is Occured')
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      if (
        this.inputData != null &&
        this.inputData != undefined &&
        this.inputData.editFlow
      ) {
        this.btn_loader = true;
        const generatedData = {
          companyName: this.companyForm.controls['companyNameLabel'].value,
          companyAddress: this.companyForm.controls['addressLabel'].value,
          companyState: this.companyForm.controls['stateLabel'].value,
          companyStateCode: Number(
            this.companyForm.controls['stateCodeLabel'].value
          ),
          pinCode: Number(this.companyForm.controls['pinLabel'].value),
          City: this.companyForm.controls['cityLabel'].value,
          contactNo: Number(this.companyForm.controls['contactLabel'].value),
          gstType: this.companyForm.controls['gstTypeLabel'].value,
          gstNo: this.companyForm.controls['gstNoLabel'].value,
          startYear: this.companyForm.controls['startYearLabel'].value,
          startMonth: Number(
            this.companyForm.controls['startMonthLabel'].value
          ),
          bankName: this.companyForm.controls['BankName'].value,
          branch: this.companyForm.controls['BranchLabel'].value,
          accountNo: this.companyForm.controls['AccountNoLabel'].value,
          ifscCode: this.companyForm.controls['IFSCCodeLabel'].value,
          Serial: sessionStorage.getItem('Serial'),
          companyCode: this.inputData.companyForm.companyCode,
          serverVal: sessionStorage.getItem('ServerVal'),
        };
        this.masterService.updateCompany(generatedData).subscribe(
          (response) => {
            this.btn_loader = false;
            this.toastr.success("Company is updated") ,{timeOut: 2000,
          }
          this.dialogRef.close();
          },
          (error) => {
            this.isLoading = true;
            this.btn_loader = false;
            this.toastr.error('Some Error is occured while updating a company')
          }
        );
      } else {
        this.btn_loader = true;
        const generatedData = {
          companyName: this.companyForm.controls['companyNameLabel'].value,
          companyAddress: this.companyForm.controls['addressLabel'].value,
          companyState: this.companyForm.controls['stateLabel'].value,
          companyStateCode: Number(
            this.companyForm.controls['stateCodeLabel'].value
          ),
          pinCode: Number(this.companyForm.controls['pinLabel'].value),
          City: this.companyForm.controls['cityLabel'].value,
          contactNo: Number(this.companyForm.controls['contactLabel'].value),
          gstType: this.companyForm.controls['gstTypeLabel'].value,
          gstNo: this.companyForm.controls['gstNoLabel'].value,
          startYear: this.companyForm.controls['startYearLabel'].value,
          startMonth: Number(
            this.companyForm.controls['startMonthLabel'].value
          ),
          bankName: this.companyForm.controls['BankName'].value,
          branch: this.companyForm.controls['BranchLabel'].value,
          accountNo: this.companyForm.controls['AccountNoLabel'].value,
          ifscCode: this.companyForm.controls['IFSCCodeLabel'].value,
          Serial: sessionStorage.getItem('Serial'),
          serverVal: sessionStorage.getItem('ServerVal'),
          companyCode: '',
        };
        this.masterService.addNewCompany(generatedData).subscribe(
          (response) => {
            this.btn_loader = false;
            if (response == 'InActive') {
              this.toastr.warning(
                'Your plan have expired. Please renew',
                "Can't add company",
                {
                  timeOut: 6000,
                }
              );
            }
            else{
              this.toastr.success("New company is created"), {
                timeOut: 2000,
              }
              this.dialogRef.close();
            }
          },
          (error) => {
            this.btn_loader = false;
            this.isLoading = true;
            this.toastr.error("Some Error is occured while adding new company")
          }
        );

      }
    }
    // this.dialogRef.close();
  }

  patchCompanyFormValues(row) {
    this.companyForm.patchValue({
      companyNameLabel: row.companyName,
      addressLabel: row.companyAddress,
      stateLabel: row.companyState,
      cityLabel: row.companyCity,
      pinLabel: row.pinCode,
      stateCodeLabel: row.companyStateCode,
      contactLabel: row.contactNo,
      gstTypeLabel: row.gstType,
      gstNoLabel: row.gstNo,
      startYearLabel: row.startYear,
      startMonthLabel: row.startMonth,
      BankName: row.bankName,
      BranchLabel: row.branch,
      AccountNoLabel: row.accountNo,
      IFSCCodeLabel: row.ifscCode,
    });
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  get contactLabel() {
    return this.companyForm.get('contactLabel');
  }

  get mobileLabel() {
    return this.companyForm.get('mobileLabel');
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get emailLabel() {
    return this.companyForm.get('emailLabel');
  }

  setStateCodeValues(event: Event) {
    let statename = (<HTMLInputElement>event.target).value;
    this.stateData.forEach((stateLabel: any) => {
      if (statename === stateLabel.stateName) {
        this.companyForm.patchValue({
          stateCodeLabel: stateLabel.stateCode,
        });
      }
    });
  }
}
