export class itemData {
    ActualQty : Number;
    BillDate : String;
    BilledQty : Number;
    Cgst : Number;
    Rate : Number;
    Amount : Number;
    Icode : String;
    Igst : Number;
    Godown : String;
    Pono : String;
    Sgst : Number;
    PoDate : String;
    ChallanNo : String;
    ChallanDate : String;
    DiscPer : Number;
    HSN : String;
    Unit : String;
    Description:any;
}
