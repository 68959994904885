<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>
<div class="container">
    <mat-dialog-content>
        <mat-icon class="cross" mat-dialog-close>close</mat-icon>
        <h1 mat-dialog-title align="start">Add Details</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="itemForm" class="mainForm">
                    <div class="form-group row" *ngIf="showLabel">
                        <div class="col-sm-4">
                            <label for="ponoLabel">Po No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="ponoLabel" placeholder="Po No."
                                id="ponoLabel">
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="showLabel">
                        <div class="col-sm-4">
                            <label for="poDateLabel">Po No Date</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="date" class="form-control" formControlName="poDateLabel"
                                placeholder="Choose Date" id="poDateLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="challanNoLabel">Challan No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="challanNoLabel" placeholder="Challan No."
                                id="challanNoLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="challanDateLabel">Challan Date</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="date" class="form-control" formControlName="challanDateLabel"
                                placeholder="Choose Date" id="challanDateLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="refNoLabel">Ref No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="refNoLabel" placeholder="Enter Ref No."
                                id="refNoLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="refDateLabel">Ref Date</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="date" class="form-control" formControlName="refDateLabel"
                                placeholder="Choose Date" id="refDateLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="salesmenLabel">Select Sales Person</label>
                        </div>
                        <div class="input-group col-sm-8">
                            <mat-select class="form-control" formControlName="salesmenLabel" id="salesmenLabel" placeholder="Select an
                                Option">
                                <mat-option [value]=''>Select</mat-option>
                                <mat-option *ngFor="let salesMen of inputData.salesMenData" [value]="salesMen.name">{{salesMen.name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="consigneeLabel">Select Consignee</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-select class="form-control" id="consigneeLabel" formControlName="consigneeLabel"
                                placeholder="Select an Option">
                                <mat-option [value]="" selected>-Select-</mat-option>
                                <mat-option *ngFor="let ledger of inputData.ledgerData" [value]="ledger.code">{{ledger.name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-dialog-content>

    <mat-dialog-actions align='end'>
        <button class="btn btn-primary col-sm-2" (click)="addItem()">Add</button>
    </mat-dialog-actions>
</div>
