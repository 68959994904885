import { Component, Input ,  OnInit,} from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { DashboardService } from 'src/services/dashboard.service';


@Component({
  selector: 'dashboard-main',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.css']
})
export class DashboardMainComponent implements OnInit {

  sales: number = 0;
  purchase: number = 0;
  profit: number = 0;
  saleGST: number = 0;
  purchaseGST: number = 0;
  profitGST : number = 0;

  @Input() selectedCompany: any = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dashboardService: DashboardService,
    ) { }


  ngOnInit(): void{

    this.dashboardService.getCompanyTransactionData().subscribe(result => {
      if(result.length != 0){
        this.sales = (result.sale[0]).total || 0 ;
        this.saleGST = (result!.sale[0]).gst || 0 ;

        this.purchase = (result!.purc[0]).total || 0 ;
        this.purchaseGST = (result!.purc[0]).gst || 0 ;

        this.profit = this.sales - this.purchase;
        this.profitGST = this.saleGST - this.purchaseGST

      }

    });

  }


  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {

      if (matches) {
        return {
          columns: 1,
          miniCards: { cols: 1, rows: 1 },
          purchaseSalesTrend: { cols: 1, rows: 2 },
          productSalesTrend: { cols: 1, rows: 2 },
          MostSoldItem: { cols: 1, rows: 1 },
          leastSoldItem: { cols: 1, rows: 1 },
          //outstandingBalance: { cols: 1, rows: 2 },
          //table: { cols: 1, rows: 2 },
        };
      }

      return {
        columns: 4,
        miniCards: { cols: 1, rows: 1 },
        purchaseSalesTrend: { cols: 2, rows: 2 },
        productSalesTrend: { cols: 2, rows: 2 },
        MostSoldItem: { cols: 1, rows: 2},
        leastSoldItem: { cols: 1, rows: 2},
        //outstandingBalance: { cols: 1, rows: 2 },
        //table: { cols: 3, rows: 2 },
      };

    })
  );

}

