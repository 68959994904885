import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConstants } from './AppConstants';


const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  public passData!: BehaviorSubject<any>;
  header = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization':sessionStorage.getItem('Token')
  });
  options = { headers: this.header };

  constructor(private http: HttpClient, private appConstants: AppConstants) { }
  // Error handlercode
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
  // Receipt Submit Api Call Code
  public receiptTransaction(receiptData:any): Observable<any>{
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':sessionStorage.getItem('Token')
    });
    let params = new HttpParams();
    return this.http.post(API_URL + '/api/MobileNXG/NewTranNoInv' , receiptData , {params,headers : this.header}).pipe(catchError(this.errorHandler));
  }

  public updateReceiptTransaction(receiptData:any):Observable<any>{
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':sessionStorage.getItem('Token')
    });
    let params = new HttpParams();
    return this.http.post(API_URL + '/api/MobileNXG/UpdateTranNoInv',receiptData,{params,headers : this.header}).pipe(catchError(this.errorHandler));
  }

}
