import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MostLeastSoldItemData } from 'src/app/shared/most-least-sold-tableData';
import { DashboardService } from 'src/services/dashboard.service';

@Component({
  selector: 'most-sold-items',
  templateUrl: './most-sold-items.component.html',
  styleUrls: ['./most-sold-items.component.css']
})
export class MostSoldItemsComponent implements OnInit {

  data: { name: string, qtySold: number }[] = [];
  passData: any[] = []
  columnName: string[] = ['ItemName', 'SoldQty'];
  dataSource!: MatTableDataSource<undefined> | any;

  constructor(private readonly dash: DashboardService) { }

  ngOnInit(): void {
    this.dash.getDetailsOfSoldItem().subscribe(response => {
      this.data = MostLeastSoldItemData.generateDataForTable(response.monthly);
      this.data.sort((a, b) => b.qtySold - a.qtySold);
      let mostSoldtableData = this.data.slice(0, 10);
      this.passData = [this.columnName, mostSoldtableData ]
      this.dataSource = new MatTableDataSource(this.passData[1]);
    })
  }
}
