<div style="height: 100vh; padding-left: 0%">
    <app-header (sideNavToggled)="sideNavStatus = $event"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus"
        [ngClass]="{ 'side-nav-open': sideNavStatus }"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="
          min-height: 93vh;
          width: 100vw;
          padding: 0 0 0 3rem;
          overflow: hidden;
        ">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous" />
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
                <div *ngIf="isLoading">
                    <!-- Head part -->
                    <div class="main-containers">
                        <mat-toolbar>
                            <nav class="headTitle">Item</nav>
                        </mat-toolbar>
                    </div>
                    <div *ngIf="isLoading">
                        <button mat-button class="add-btn" (click)="addNewitem()">
                            Add New
                        </button>
                        <button mat-button class="add-btn" (click)="upload()">
                            Upload File
                        </button>
                        <ng-template #newItemForm>
                            <mat-dialog-content>
                                <p class="actionIcon" mat-dialog-close>
                                    <mat-icon class="cross" matTooltip="close">clear</mat-icon>
                                </p>
                                <h1 mat-dialog-title class="companytitle">ITEM</h1>
                                <mat-card>
                                    <mat-card-content>
                                        <form [formGroup]="itemForm" class="mainForm">
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="itemNameTextArea"><span class="required">*</span>Item
                                                        Name</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <textarea class="form-control" formControlName="itemName"
                                                        placeholder="Item Name" id="itemNameTextArea"
                                                        [readonly]="!forView"></textarea>
                                                    <mat-error *ngIf="showErrorAlert && itemForm.get('itemName').hasError('required')">
                                                        Item Name is required
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="itemDescriptionTextArea">Description</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <textarea class="form-control" formControlName="itemDescription"
                                                        placeholder="Item Description" id="itemDescriptionTextArea"
                                                        [readonly]="!forView"></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="groupNameLabel">Group Name</label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <select formControlName="groupName" class="form-control"
                                                        (change)="setHSNGSTValues($event)">
                                                        <option value="" selected>-Select option-</option>
                                                        <option *ngFor="let stockGroup of stockGroupData"
                                                            [value]="stockGroup?.code">
                                                            {{ stockGroup?.name }}
                                                        </option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"
                                                            (click)="openStockGroup()">+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="unitLabel">Unit</label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <select formControlName="unit" class="form-control">
                                                        <option value="" selected>-Select option-</option>
                                                        <option *ngFor="let unit of unitGroupData" [value]="unit?.name">
                                                            {{ unit?.name }}
                                                        </option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"
                                                            (click)="openUnitDialog()">+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="hsnLabel">H.S.N. </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <select formControlName="hsnNo" class="form-control"
                                                        (change)="setGSTValues($event)">
                                                        <option value="" selected>-Select option-</option>
                                                        <option *ngFor="let hsn of hsnGroupData" [value]="hsn?.code">
                                                            {{ hsn?.name }}
                                                        </option>
                                                    </select>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" (click)="openHSN()">+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="igstLabel">I.G.S.T. </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control"
                                                        (change)="setOneNumberDecimalForIGST($event)"
                                                        formControlName="igst" placeholder="IGST" id="igstLabel"
                                                        [readonly]="!forView" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="igst.touched && igst.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="igst.errors.pattern">
                                                            Enter value between 0 - 100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="cgstLabel">C.G.S.T. </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" (change)="setOneNumberDecimal($event)"
                                                        formControlName="cgst" placeholder="CGST" id="cgstLabel"
                                                        [readonly]="!forView" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="cgst.touched && cgst.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="cgst.errors.pattern">
                                                            Enter value between 0 - 100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="sgstLabel">S.G.S.T. </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" (change)="setOneNumberDecimal($event)"
                                                        formControlName="sgst" placeholder="SGST" id="sgstLabel"
                                                        [readonly]="!forView" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="sgst.touched && sgst.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="sgst.errors.pattern">
                                                            Enter value between 0 - 100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="qtyLabel">Quantity</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input class="form-control"
                                                        (keypress)="keyPressNumbersDecimal($event)"
                                                        (change)="setThreeNumberDecimal($event)" formControlName="qty"
                                                        placeholder="Qty" id="qtyLabel" [readonly]="!forView" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="rateLabel">Rate</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input class="form-control"
                                                        (keypress)="keyPressNumbersDecimal($event)"
                                                        (change)="setTwoNumberDecimal($event)" formControlName="rate"
                                                        placeholder="Rate" id="rateLabel" [readonly]="!forView" />
                                                </div>
                                            </div>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                                <mat-dialog-actions align="center" *ngIf="forView">
                                    <button class="btn btn-primary col-sm-2 {{
                      isLoading ? '' : 'loader'
                    }}" [disabled]="!isLoading" (click)="
                      addItem('dashboard/master/inventory-form/item-form')
                    ">
                                        Submit
                                    </button>
                                    <button class="btn btn-primary col-sm-2" mat-dialog-close>
                                        Cancel
                                    </button>
                                </mat-dialog-actions>
                            </mat-dialog-content>
                        </ng-template>
                        <app-table *ngIf="itemGroupData?.length" [recievedData]="passData"
                            (itemFormComp)="addNewitem($event)"></app-table>
                    </div>
                    <div *ngIf="itemGroupData?.length == 0" style="
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            ">
                        <h1>No Items Present</h1>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
    <!-- <app-footer></app-footer> -->
</div>