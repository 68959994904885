import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { EventEmitter } from 'events'
import { AppConstants } from 'src/services/AppConstants'
import { Action } from 'rxjs/internal/scheduler/Action'
import { MasterService } from 'src/services/master.service'
import { isNullOrUndefined } from 'util'
import { Router } from '@angular/router'
import { NewUnitComponent } from '../new-unit/new-unit.component'
import { ToastrService } from 'ngx-toastr'
import { DataUpload } from "../../../../shared/dataUpload";
import { UploadMasterDialogComponent } from '../../upload-master-dialog/upload-master-dialog.component'
@Component({
  selector: 'app-item-form-inventory',
  templateUrl: './item-form-inventory.component.html',
  styleUrls: ['./item-form-inventory.component.css'],
})
export class ItemFormInventoryComponent implements OnInit {
  sideNavStatus: boolean = false;
  customerLogin: boolean
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.dialog.closeAll()
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component])
  }

  unit: string

  @Output() public emitData = new EventEmitter()
  showErrorAlert: boolean = false
  isLoading: boolean = false
  passData!: any
  data!: any
  stockGroupData!: any
  itemGroupData!: any
  hsnGroupData!: any
  unitGroupData!: any
  isEditFlow: boolean = false
  isDeleteFlow: boolean = false
  forView: boolean = true
  one_attribute: ' '
  selectedValue: 'null'
  @ViewChild('newItemForm') newItemForm!: TemplateRef<any>

  itemForm = this.fb.group({
    itemName: ['', Validators.required],
    itemDescription: [''],
    groupName: [],
    unit: [''],
    hsnNo: [],
    /*sgst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],
    cgst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],
    igst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],*/
    sgst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],  //Added by Priyanka
    cgst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],  //Added by Priyanka
    igst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],   //Added by Priyanka
    qty: [''],
    rate: [''],
  })
  code: any
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.resetItemForm()
    this.getData()
    if (sessionStorage.getItem('customerLogin') == "true") {
      this.customerLogin = true;
    } else {
      this.customerLogin = false;
    }
  }

  upload() {
    this.dialog.open(UploadMasterDialogComponent, {
      data: {
        headerFields: '"Name", "Unit", "Rate", "Quantity", "Description", "IGST", "CGST", "SGST"',
        masterName: "Item"
      }
    });
  }

  getData() {
    this.isLoading = false
    let listOfItems = ['StockGroup', 'HSN', 'Item', 'Unit']
    let optionValList = ['0', '0', '0', '0']
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message");
      this.stockGroupData = response.stockGroup0;
      this.hsnGroupData = response.hsN0;
      this.itemGroupData = response.item0;
      this.unitGroupData = response.unit0;
      //this.replaceCodeWithNameForStockGroup(this.itemGroupData, this.stockGroupData);
      //this.replaceCodeWithNameForHSN(this.itemGroupData, this.hsnGroupData);
      this.passData = [this.appConstants.itemFormColumnNames, this.itemGroupData, "itemFormInventory", this.appConstants.itemExcelColumnNames]; // pass data
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.itemFormColumnNames, false, "itemFormInventory"];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }
  replaceCodeWithNameForStockGroup(item, stockGroup) {
    item.forEach((itemEntry) => {
      stockGroup.forEach((group) => {
        if (itemEntry.stock_Group === group.code) {
          itemEntry.Stock_Group = group.name;
        }
      })
    })
  }

  replaceCodeWithNameForHSN(item, hsn) {
    item.forEach((itemEntry) => {
      hsn.forEach((hsnEntry) => {
        if (itemEntry.hsn === hsnEntry.code) {
          itemEntry.HSN = hsnEntry.name;
        }
      })
    })
  }
  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {
    var charCode = event.which ? event.which : event.keyCode
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault()
      return false
    }
    return true
  }

  resetItemForm() {
    this.showErrorAlert = false
    this.itemForm = this.fb.group({
      itemName: ['', Validators.required],
      itemDescription: [''],
      groupName: [],
      unit: [''],
      hsnNo: [],
      /*sgst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],
      cgst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],
      igst: ['', Validators.pattern('^[0-9][0-9]?$|^100$')],*/
      sgst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],  //Added by Priyanka
      cgst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],  //Added by Priyanka
      igst: ['', Validators.pattern('^[0-9]*(\.[0-9]{0,1})?$')],   //Added by Priyanka
      qty: [''],
      rate: [''],
    })
  }

  addItem(component: string) {
    if (this.itemForm.invalid) {
      this.showErrorAlert = true
    } else {
      this.showErrorAlert = false
      this.isLoading = false
      const generatedData = {
        Name: this.itemForm.controls['itemName'].value,
        Description: this.itemForm.controls['itemDescription'].value,
        StockGroup: this.itemForm.controls['groupName'].value,
        UnitName: this.itemForm.controls['unit'].value,
        Hsn: this.itemForm.controls['hsnNo'].value,
        Igst: Number(this.itemForm.controls['igst'].value),
        Cgst: Number(this.itemForm.controls['cgst'].value),
        Sgst: Number(this.itemForm.controls['sgst'].value),
        Qty: Number(this.itemForm.controls['qty'].value) || 0,
        Rate: Number(this.itemForm.controls['rate'].value),
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: Number(sessionStorage.getItem('ServerVal')),
        Code: !this.isEditFlow ? '' : this.code,
      }
      this.dialog.closeAll()
      if (this.isEditFlow) {
        this.masterService.updateItem(generatedData).subscribe(
          (response) => {
            this.getData()
            this.toastr.success('Data Updated Successfully', 'Update Success')
          },
          (error) => {
            this.isLoading = true
            this.toastr.error('Unable To Update Data', 'Update Error')
          },
        )
      } else {
        this.masterService.addNewItem(generatedData).subscribe(
          (response) => {
            this.getData()
            this.toastr.success('Data added Successful', 'Add Success')
          },
          (error) => {
            this.isLoading = true
            this.toastr.error('Unable To Add New Data', 'Add Error')
          },
        )
      }
    }
  }

  setTwoNumberDecimal(event) {
    if (event.target.value) {
      event.target.value = parseFloat(event.target.value).toFixed(2);
    }
    else {
      event.target.value = 0;
    }

  }

  setThreeNumberDecimal(event) {
    if (event.target.value) {
      event.target.value = parseFloat(event.target.value).toFixed(3);
    }
    else {
      event.target.value = 0;
    }

  }
  // upload(event: any) {
  //   const files = event.target.files
  //   const formData = new FormData()
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('files', files[i], files[i].name)
  //   }
  //   this.masterService.uploadMasterData(formData, 'Item').subscribe(
  //     (response) => {
  //       if (response.statusCode === 200) {
  //         this.getData()
  //         this.toastr.success('Data added Successful', 'Add Success')
  //       }
  //     },
  //     (error) => {
  //       this.toastr.error('Unable To Upload Data', 'Add Error')
  //     },
  //   )
  // }
  addNewitem(rowData?: any) {
    this.resetItemForm()
    if (rowData === null || rowData === undefined) {
      this.code = ''
      this.forView = true
      this.isEditFlow = false
      let dialogRef = this.dialog.open(this.newItemForm, {
        disableClose: true,
        width: '60%',
      })
      dialogRef.afterClosed().subscribe((result) => { })
    } else if (
      (rowData != null || rowData != undefined) &&
      rowData.editFlow &&
      rowData.row.isMain == 0
    ) {
      this.isEditFlow = rowData.editFlow ? true : false
      this.forView = true
      this.patchItemFormValues(rowData.row)
      this.code = rowData.row.code
      let dialogRef = this.dialog.open(this.newItemForm, {
        disableClose: true,
        width: '80%',
      })
      dialogRef.afterClosed().subscribe((result) => { })
    } else if (
      (rowData != null || rowData != undefined) &&
      rowData.deleteFlow
    ) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false
      this.isLoading = false
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code,
      }
      this.masterService.deleteItem(generatedData).subscribe(
        (response) => {
          this.isLoading = true
          this.toastr.success('Deleted Successfully', 'Delete Success', {
            closeButton: true,
          })
        },
        (error) => {
          this.isLoading = true
          this.toastr.error('Unable to Delete Data', 'Delete Unsuccess', {
            closeButton: true,
          })
        },
      )
    } else if (
      (rowData != null || rowData != undefined) &&
      rowData.editFlow &&
      rowData.row.isMain != 0
    ) {
      this.toastr.warning(
        "You don't have sufficient privilege to Edit this Data",
        'Edit Alert',
      )
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchItemFormValues(rowData.row)
      this.code = rowData.row.code
      let dialogRef = this.dialog.open(this.newItemForm, {
        disableClose: true,
        minWidth: '60%',
      })
      dialogRef.afterClosed().subscribe((result) => { })
    }
  }

  patchItemFormValues(row: any) {
    this.itemForm.patchValue({
      'itemName': row.name,
      'itemDescription': row.description,
      'groupName': row.stockGroupCode,
      'unit': row.unit,
      'hsnNo': row.hsnCode,
      'sgst': row.sgst,
      'igst': row.igst,
      'cgst': row.cgst,
      'qty': row.qty || row.opening,
      'rate': row.rate
    });
  }

  setHSNGSTValues(event: Event) {
    let groupName = (<HTMLInputElement>event.target).value
    this.stockGroupData.forEach((stockGroup: any) => {
      if (
        groupName === stockGroup.name &&
        groupName.toLowerCase() != 'primary'
      ) {
        this.itemForm.patchValue({
          hsnNo: stockGroup.hsn,
          sgst: stockGroup.sgst,
          igst: stockGroup.igst,
          cgst: stockGroup.cgst,
        })
      }
    })
  }

  setGSTValues(event: Event) {
    let hsnName = (<HTMLInputElement>event.target).value
    this.hsnGroupData.forEach((hsn: any) => {
      if (hsnName === hsn.name) {
        this.itemForm.patchValue({
          sgst: hsn.sgst,
          igst: hsn.igst,
          cgst: hsn.cgst,
        })
      }
    })
  }
  openUnitDialog(): void {
    const dialogRef = this.dialog.open(NewUnitComponent, {
      width: '250px'
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.unitGroupData.push({ name: result })
      }
    })
  }

  openHSN(): void {
    this.dialog.closeAll();
    this.router.navigateByUrl('/dashboard/master/inventory/hsn');
  }

  openStockGroup(): void {
    this.dialog.closeAll();
    this.router.navigateByUrl('dashboard/master/inventory/stock-group');
  }

  setOneNumberDecimalForIGST(event) {

    if (this.itemForm.get('igst').value && event.target.value !== "") {
      event.target.value = parseFloat(event.target.value).toFixed(1);
      this.itemForm.patchValue({
        sgst: (parseFloat(event.target.value) / 2).toFixed(1),
        cgst: (parseFloat(event.target.value) / 2).toFixed(1)
      })
    }
    else {
      event.target.value = 0;
    }
  }

  setOneNumberDecimal(event) {
    if ((this.itemForm.get('sgst').value || this.itemForm.get('cgst').value) && event.target.value !== "") {
      event.target.value = parseFloat(event.target.value).toFixed(1);
    }
    else {
      event.target.value = 0;
    }
  }

  get igst() {
    return this.itemForm.get('igst')
  }
  get cgst() {
    return this.itemForm.get('cgst')
  }
  get sgst() {
    return this.itemForm.get('sgst')
  }
}
