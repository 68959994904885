<div style="height: 100vh; padding-left: 0%">
  <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
  <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus"
    [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
  <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
      <mat-drawer-content style="min-height: 93vh; width: 96vw; padding: 0 2rem; overflow: hidden">
        <mat-toolbar>
          <nav class="headTitle">{{ headTitle }}</nav>
        </mat-toolbar>
        <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
        <div *ngIf="isLoading">
          <form [formGroup]="partyForm" class="mainform">
            <table>
              <tr>

                <td>
                  <div class="col-sm-2" class="frst">
                    <label for="ledgerlable1" id="ldg">Ledger :</label>
                  </div>
                </td>
                <td class="scnd">
                  <div class="input-wrapper">
                    <input type="text" placeholder="Pick Ledger" class="form-control" formControlName="ledgerlable1"
                      id="ledgerLabel" matInput [matAutocomplete]="auto" />
                    <mat-icon class="clear-icon" (click)="clearInput()">cancel</mat-icon>
                  </div>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tableFill($event.option.value)">
                    <mat-option [value]="" selected>
                      <div class="option-row">
                        <div>-Select option-</div>
                        <div class="opening">[Opening]</div>
                        <div class="debit">[Debit]</div>
                        <div class="credit">[Credit]</div>
                      </div>
                    </mat-option>
                    <mat-option *ngFor="let option of filteredOptions|async" [value]="option">
                      <div class="option-row">
                        <div>{{ option.name }}</div>
                        <div class="opening">[{{ option.opening }}]</div>
                        <div class="debit">[{{ option.debit }}]</div>
                        <div class="credit">[{{ option.credit }}]</div>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td><label for="openingBalance" class="extraLabel" id="ldg"> Opening: {{openingBalance}}</label></td>
                <td><label for="debit" id="ldg" class="extraLabel"> Debit:{{debit}}</label></td>
                <td><label for="credit" id="ldg" class="extraLabel"> Credit:{{credit}}</label></td>


                <td>
                  <div style="float: right" class="input-group col-sm-6">
                    <mat-form-field [formGroup]="dateRange" appearance="fill">
                      <mat-label> Enter the start date and end date </mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" formControlName="startDate" />
                        <input matEndDate placeholder="End date" formControlName="endDate"
                          (keydown.enter)="filterBasedOnDates()" (dateChange)="filterBasedOnDates()" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-date-range-picker #picker> </mat-date-range-picker>
                    </mat-form-field>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <!-- upper form containing dropdown and date closed  -->

        <div id="ttlRow">
          <p>Total rows: {{totalRowCount}}</p>
        </div>

        <div *ngIf="isLoading" style="width:100vw">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- bill date Column -->

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Bill Date </th>
              <td mat-cell *matCellDef="let ledger"> {{ledger.date | date : "dd/MM/YYYY" }} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher Name Column -->
            <ng-container matColumnDef="voucher">
              <th mat-header-cell *matHeaderCellDef> Voucher </th>
              <td mat-cell *matCellDef="let ledger"> {{ledger.voucher}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher No Column -->
            <ng-container matColumnDef="bill No">
              <th mat-header-cell *matHeaderCellDef> Voucher No </th>
              <td mat-cell *matCellDef="let ledger"> {{ledger['bill No']}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Debit Column -->
            <ng-container matColumnDef="debit">
              <th mat-header-cell *matHeaderCellDef> Debit </th>
              <td mat-cell *matCellDef="let ledger"> {{ledger.debit}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Credit Column -->
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef> Credit </th>
              <td mat-cell *matCellDef="let ledger"> {{ledger.credit}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
            <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr> -->
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
          aria-label="Select page of Data"></mat-paginator>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>