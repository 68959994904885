<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
  crossorigin="anonymous"
/>
<script
  src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
  integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
  crossorigin="anonymous"
></script>
<script
  src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
  integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
  crossorigin="anonymous"
></script>

<mat-dialog-content>
  <p class="cross" mat-dialog-close>&#10005;</p>
  <div class="container">
    <h1 mat-dialog-title class="companytitle">COMPANY</h1>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="companyForm" class="mainForm">
          <!-- <div class="form-group row">
            <div class="col-sm-4">
              <label for="codeLabel"> Company Code </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control"
                 [readOnly]="viewMode" formControlName="codeLabel"
                placeholder="eg. A00001" id="codeLabel">
            </div>
            <span id="spn">
              <mat-error *ngIf="showErrorAlert &&
                companyForm.get('codeLabel').hasError('required')">
                Code is required
              </mat-error></span>
          </div> -->
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="companyNameLabel"><span style="color: red;"> * </span>Name</label>
            </div>

            <div class="col-sm-8">
              <input class="form-control"  [readOnly]="viewMode" formControlName="companyNameLabel" placeholder="Name" id="companyNameLabel">
            </div>
            <span id="spn">
              <mat-error
                *ngIf="companyForm.get('companyNameLabel').invalid && companyForm.get('companyNameLabel').touched  && companyForm.get('companyNameLabel').hasError('required')"
              >
                Name is required field
              </mat-error>
            </span>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="addressLabel">Address </label>
            </div>
            <div class="col-sm-8">
              <textarea class="form-control"  [readOnly]="viewMode" formControlName="addressLabel" placeholder="Address"
                id="addressLabel"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="stateLabel"><span style="color: red;"> * </span>State </label>
            </div>
            <div class="input-group col-sm-8">
              <select
                class="form-control"
                formControlName="stateLabel"
                (change)="setStateCodeValues($event)"
                id="stateLabel"
                placeholder="Select an Option"
              >
                <option selected>Select</option>
                <option
                  *ngFor="let stateLabel of stateData | sort : ['stateName']"
                  [value]="stateLabel.stateName"
                >
                  {{ stateLabel.stateName }}
                </option>
              </select>
            </div>
            <span id="spn">
              <mat-error
                *ngIf="
                companyForm.get('companyNameLabel').invalid && companyForm.get('companyNameLabel').touched  &&
                  companyForm.get('stateLabel').hasError('required')
                "
              >
                State is required field
              </mat-error>
            </span>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="cityLabel">City </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" id="cityLabel"  [readOnly]="viewMode" formControlName="cityLabel" placeholder="Enter City Name">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="pinLabel">Pin Code </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control"  [readOnly]="viewMode" formControlName="pinLabel" placeholder="Enter Pin code" id="pinLabel">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="stateCodeLabel">State Code </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control"  [readOnly]="viewMode" formControlName="stateCodeLabel" placeholder="Enter State code"
                id="stateCodeLabel" [readonly]="!editable">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="contactLabel"><span style="color: red;"> * </span>Contact No. </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control"  [readOnly]="viewMode" formControlName="contactLabel" placeholder="Enter Contact Number"
                id="contactLabel">
            </div>
            <span id="spn">
              <mat-error
                *ngIf="
                companyForm.get('companyNameLabel').invalid && companyForm.get('companyNameLabel').touched  &&
                  companyForm.get('contactLabel').hasError('required')
                "
              >
                Mobile Number is required field
              </mat-error>
              <mat-error
                *ngIf="
                  showErrorAlert &&
                  companyForm.get('contactLabel').hasError('pattern')
                "
              >
                Enter a valid Mobile Number
              </mat-error>
            </span>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="gstTypeLabel">GST Type </label>
            </div>
            <div class="col-sm-8">
              <select
                class="form-control"
                id="gstTypeLabel"
                formControlName="gstTypeLabel"
                [disabled]="disableSelect?.value"
                [(ngModel)]="nrSelect"
              >
                <option value="NOT APPLICABLE">NOT APPLICABLE</option>
                <option value="REGULAR">REGULAR</option>
                <option value="UNREGISTERED">UNREGISTERED</option>
                <option value="COMPOSITION">COMPOSITION</option>
                <option value="EXTRA">EXTRA</option>
              </select>
            </div>
            <!-- <span id="spn">
              <mat-error
                *ngIf="
                  showErrorAlert &&
                  companyForm.get('gstTypeLabel').hasError('required')
                "
              >
                GST Type is required
              </mat-error>
            </span> -->
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="gstNoLabel">GST No. </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control"  [readOnly]="viewMode" formControlName="gstNoLabel" placeholder="Enter GST No." id="gstNoLabel">
            </div>
            <!-- <span id="spn">
              <mat-error
                *ngIf="
                  showErrorAlert &&
                  companyForm.get('gstNoLabel').hasError('required') &&
                  nrSelect !== 'NOT APPLICABLE' &&
                  nrSelect !== 'EXTRA'
                "
              >
                GST No. is required
              </mat-error>
            </span> -->
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="startYearLabel">Start Year </label>
            </div>
            <div class="col-sm-8">
              <select
                [(ngModel)]="selectedYear"
                formControlName="startYearLabel"
                id="startYearLabel"
                class="form-control"
              >
                <option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4">
              <label for="startMonthLabel">Start Month</label>
            </div>
            <div class="col-sm-8">
              <select class="form-control" id="startMonthLabel" formControlName="startMonthLabel"
                [disabled]="disableSelect.value">
                <option value='' selected> -Select option- </option>
                <option *ngFor="let item of months" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <label for="BankName">Bank Name </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" id="BankName"  [readOnly]="viewMode" formControlName="BankName" placeholder="Enter Bank Name">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <label for="BranchLabel">Branch </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" id="BranchLabel"  [readOnly]="viewMode" formControlName="BranchLabel"
                placeholder="Enter Branch Name">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <label for="AccountNoLabel">Account No. </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" id="AccountNoLabel"  [readOnly]="viewMode" formControlName="AccountNoLabel"
                placeholder="Enter Account No.">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <label for="IFSCCodeLabel">IFSC Code </label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" id="IFSCCodeLabel"  [readOnly]="viewMode" formControlName="IFSCCodeLabel"
                placeholder="Enter IFSC Code">
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>

<mat-dialog-actions align='center'>
  <button class="btn btn-primary col-sm-2 {{btn_loader ? 'loader':'' }}" [disabled]="btn_loader" (click)="addCompany()"
    *ngIf="!viewMode">Submit</button>
  <button class="btn btn-primary col-sm-2" mat-dialog-close>{{viewMode? 'Ok':'Cancel'}}</button>
</mat-dialog-actions>
