<!-- <div class="main-container-box">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
        <mat-drawer-content style="min-height: 90vh;">

            <div class="btn-container" *ngIf="!customerLogin">
                <button mat-raised-button (click)="openComponent('dashboard/master')"><span class="highlighter">M</span>aster</button>
                <button mat-raised-button (click)="openComponent('dashboard/transactions')"><span class="highlighter">T</span>ransactions</button>
                <button mat-raised-button (click)="openComponent('dashboard/reports')"><span class="highlighter">R</span>eports</button>
            </div>

            <div #widgetsContent class="middle" *ngIf="customerLogin">
                <div class="wrapper">
                    <div class="cardContainer scrolls" fxLayoutGap="5px">
                        <mat-card class="cardProduct">
                            <mat-card-title class="cardTitle"
                                (click)="openComponent('dashboard/transactions/accounting/saleOrder')">Sale <span
                                    class="highlighter">O</span>rder
                            </mat-card-title>
                            <mat-card-content class="cardContent">
                                <mat-icon matTooltip="Sale Order reports" (click)="openComponent('dashboard/report/register/sales-order')">analytics</mat-icon>
                            </mat-card-content>
                            <mat-card-actions align="end">
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
<app-footer></app-footer>
</div> -->
<app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
<app-side-nav  *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
<dashboard-main></dashboard-main>
<app-footer></app-footer>
