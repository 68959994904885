import { itemData } from './itemData.model';
import { ledgerData } from './ledgerData.model';

export class salesData {
    MName : String;
    BillNo : String;
    Vcode : String;
    Serial : String;
    CompanyCode : String;
    ServerVal : Number;
    BillDate : String;
    Acode : String;
    BillAmount : String;
    Bcode : String;
    Reference : Number;
    RefDate : String;
    Pono : Number;
    Podate : String;
    TrackNo : Number;
    TrackDate : String;
    Vehicle : String;
    Transport : String;
    Desti : String;
    Dispatch : String;
    Sgst : Number;
    Cgst : Number;
    Igst : Number;
    Cess : Number;
    Utgst : Number;
    IsIGST : boolean;
    DocumentNo : String;
    UID : String;
    Narration : String;
    Salesmen : String;
    Bdays : Number;
    Roundoff : Number;
    SubTot : Number;
    ChallanNo : String;
    KM : Number;
    Ucode : String;
    ChallanDate : String;
    INVData : itemData[];
    LedData : ledgerData[];
    Consignee: any;

    constructor(){
        this.INVData = [];
        this.LedData = [];
    }
}