import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { AddDetailComponent } from './add-detail/add-detail.component';
import { AddItemComponent } from './add-item/add-item.component';
import { AddLedgerComponent } from './add-ledger/add-ledger.component';
import { EachItemDetailComponent } from './each-item-detail/each-item-detail.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MasterService } from 'src/services/master.service';
import { AppConstants } from 'src/services/AppConstants';
import { salesData } from 'src/app/model/salesData.model';
import { ledgerData } from 'src/app/model/ledgerData.model';
import { itemData } from 'src/app/model/itemData.model';
import { UtilityService } from 'src/services/utility.service';
import { DatePipe, Location } from '@angular/common';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { CostCenterComponent } from '../../master/accounting-master/cost-center/cost-center.component';
import { catchError, map, startWith } from 'rxjs/operators';
import { AutoCompleteValidator } from 'src/app/shared/autocomplete-validation';

@Component({
  selector: 'app-sale-bill',
  templateUrl: './sale-bill.component.html',
  styleUrls: ['./sale-bill.component.css'],
})
export class SaleBillComponent implements OnInit {

  companyStateCode: string = sessionStorage.getItem('companyStateCode');
  sideNavStatus: boolean = false;
  routerName: string;
  finalLedgerData: any;
  dialogRef: any;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key) {
      var key = event.key.toUpperCase();
      if (
        event.key == 'Escape' &&
        sessionStorage.getItem('customerLogin') == 'false'
      ) {
        this.dialog.closeAll();
        this.openComponent('dashboard/transactions');
      } else if (
        event.key == 'Escape' &&
        sessionStorage.getItem('customerLogin') == 'true'
      ) {
        this.openComponent('dashboard');
      }
    }
  }



  disableSubmitButton: boolean = false;
  isLoading: boolean = false;
  billNo: string = '';
  currentDate: Date = new Date();
  preSalesFormGroup: FormGroup;
  extraInfoSalesFormGroup: FormGroup;
  ledgerDisplayFlag: boolean = false;
  narrationDisplayFlag: boolean = false;

  disableSelect = new FormControl(false);
  ledger1Data: any;
  ledger2Data: any;
  itemData: any;
  salesMenData: any;
  godownData: any;
  voucherData: any;
  ledger1StateCode: any;
  stateSelected: any;
  daysSelected: any;

  additionalDataForBill: any = [];
  itemCardData: any = [];
  additionalItemData: any = [];
  ledgerCardData: any = [];

  subTotal: number = 0;
  total: number = 0;
  igstTotal: number = 0;
  cgstTotal: number = 0;
  sgstTotal: number = 0;
  isIGST: boolean;
  showLabel: boolean = false;
  showContainer: boolean = true;
  showLabel2: boolean = true;
  headTitle: string;
  filteredOptions: Observable<string[] | any>;
  validationMsg = "Please Select Correct Option"


  editSalesData: any;
  editItemData: any;
  editItemMode: boolean = false;
  editLedgerData: any;
  editFlow: boolean = true;
  viewOnly: boolean = false;
  finalSalesData: salesData;
  url: any = window.location.href;
  minDate: any;
  maxDate: any;
  startMonth: any;
  startYear: any;
  customerLogin: boolean;
  ledgerInputDisable: boolean = false;

  @ViewChild('itemTable', { static: false }) content: ElementRef;
  MName: string;

  constructor(
    public dialog: MatDialog,
    private masterService: MasterService,
    private router: Router,
    public appConstants: AppConstants,
    private fb: FormBuilder,
    private utilityService: UtilityService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private readonly locationService: Location
  ) {
    if (
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.editSalesData =
        this.router.getCurrentNavigation().extras.state.data[0].sales;
      this.editItemData =
        this.router.getCurrentNavigation().extras.state.data[1].items;
      this.editLedgerData =
        this.router.getCurrentNavigation().extras.state.data[2].ledger;
      this.editFlow = this.router.getCurrentNavigation().extras.state.editFlow;
      this.viewOnly = this.router.getCurrentNavigation().extras.state.viewOnly;
      this.editItemMode =
        this.router.getCurrentNavigation().extras.state.editFlow;
    }
  }

  ngOnInit(): void {

    if (sessionStorage.getItem('customerLogin') == 'true') {
      this.customerLogin = true;
    } else {
      this.customerLogin = false;
    }
    this.preSalesFormGroup = this.fb.group({
      voucherNo: [''],
      billDate: [''],
      partyName: ['', [AutoCompleteValidator.autocompleteValidator(this.checkPartyNameExists.bind(this))]],
      billNo: [''],
    });
    this.extraInfoSalesFormGroup = this.fb.group({
      transport: [''],
      vehicleNo: [''],
      destination: [''],
      dispatch: [''],
      narration: [''],
      km: [''],
      cess: [''],
      utgst: [''],
      igst: [''],
      cgst: [''],
      sgst: [''],
      uid: [''],
    });
    this.getData();
    this.startMonth = sessionStorage.getItem('startMonth');
    this.startYear = sessionStorage.getItem('startYear');
    this.minDate = this.datePipe.transform(
      new Date(this.startYear, this.startMonth - 1, 1),
      'yyyy-MM-dd'
    );
    this.maxDate = this.datePipe.transform(
      new Date(this.startYear, this.startMonth - 1 + 12, 1 - 1),
      'yyyy-MM-dd'
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ledger1Data.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  private checkPartyNameExists(value): boolean {
    return this.ledger1Data?.some(item => item.name === value);
  }

  openLadger(): void {
    this.router.navigate(['dashboard/master/accounting/ledger'])
  }

  getData(): any {

    let listOfLedgers = ['Ledger', 'Ledger', 'Item', 'SalesMen', 'Godown'];
    let optionValList = ['1', '2', '0', '0', '0'];
    this.masterService
      .getSalesDetails(listOfLedgers, optionValList)
      .subscribe((response) => {
        this.ledger1Data = response.ledger1;
        this.ledger2Data = response.ledger2;
        this.itemData = response.item0;
        this.salesMenData = response.salesMen0;
        this.godownData = response.godown0;
        this.additionalDataForBill = {
          ponoLabel: this.editSalesData?.pono,
          poDateLabel: this.editSalesData?.poDate?.startsWith('1900-01-01')? "" :this.datePipe.transform(
            this.editSalesData?.poDate,
            'yyyy-MM-dd'
          ),
          challanNoLabel: this.editSalesData?.challanNo,
          challanDateLabel: this.editSalesData?.challanDate?.startsWith('1900-01-01')? "" : this.datePipe.transform(
            this.editSalesData?.challanDate,
            'yyyy-MM-dd'
          ),
          refNoLabel: this.editSalesData?.reference,
          refDateLabel: this.editSalesData?.refDate?.startsWith('1900-01-01')? "" : this.datePipe.transform(
            this.editSalesData?.refDate,
            'yyyy-MM-dd'
          ),
          salesmenLabel: this.salesMenData.filter(
            (s) => s.code == this.editSalesData?.salesmen
          )[0]?.name,
          consigneeLabel: this.editSalesData?.bCode,
        };
        if (this.editItemData && this.editItemData.length > 0) {
          for (let i = 0; i < this.editItemData.length; i++) {
            let itemPODetails = {
              ponoLabel: this.editItemData[i].pono,
              poDateLabel: this.datePipe.transform(
                this.editItemData[i].poDate,
                'yyyy-MM-dd'
              ),
              challanLabel: this.editItemData[i].challanNo,
              challanDateLabel: this.datePipe.transform(
                this.editItemData[i].challanDate,
                'yyyy-MM-dd'
              ),
              godownLabel: this.editItemData[i].godown,
              batchLabel: '',
              freeQLabel: 1,
            };
            this.additionalItemData.push(itemPODetails);
          }
        }

        if (this.customerLogin == true) {
          var LedgerData: any = [];
          for (var i = 0; i < this.ledger1Data.length; i++) {
            if (this.ledger1Data[i].code == sessionStorage.getItem('List[3]')) {
              LedgerData[0] = this.ledger1Data[i];
              this.ledger1Data = LedgerData;
            }
          }
          this.preSalesFormGroup.controls.partyName.setValue(
            this.ledger1Data[0].name
          );
          this.saveStateCode(this.ledger1Data[0].name);

        }
        let listofVoucher;
        if (this.url.endsWith('transactions/accounting/sale-bill')) {
          this.ledger1Data = response.ledger1
          listofVoucher = 'sales';
          this.headTitle = 'Sales';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/sale-bill/tax-invoice';
        } else if (this.url.endsWith('transactions/accounting/saleOrder')) {
          listofVoucher = 'salesOrder';
          this.headTitle = 'Sales Order';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/saleOrder/tax-invoice';
        } else if (this.url.endsWith('transactions/accounting/purchase')) {
          listofVoucher = 'purchase';
          this.headTitle = 'Purchase';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/purchase/tax-invoice';
        } else if (this.url.endsWith('transactions/accounting/purchaseOrder')) {
          listofVoucher = 'purchaseOrder';
          this.headTitle = 'Purchase Order';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/purchaseOrder/tax-invoice';
        } else if (this.url.endsWith('transactions/accounting/creditNote')) {
          listofVoucher = 'creditNote';
          this.headTitle = 'Credit Note';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/creditNote/tax-invoice';
        } else if (this.url.endsWith('transactions/accounting/debitNote')) {
          listofVoucher = 'debitNote';
          this.headTitle = 'Debit Note';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/accounting/debitNote/tax-invoice';
        } else if (this.url.endsWith('transactions/inventory/material-in')) {
          listofVoucher = 'MaterialIn';
          this.headTitle = 'Material In';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/inventory/material-in/tax-invoice';
          this.showContainer = false;
        } else if (this.url.endsWith('transactions/inventory/material-out')) {
          listofVoucher = 'MaterialOut';
          this.headTitle = 'Material Out';
          this.ledgerDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/inventory/material-out/tax-invoice';
          this.showContainer = false;
        } else if (this.url.endsWith('transactions/inventory/production')) {
          listofVoucher = 'Production';
          this.headTitle = 'Production';
          this.narrationDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/inventory/production/tax-invoice';
          this.showContainer = false;
          this.showLabel2 = false;
        } else if (this.url.endsWith('transactions/inventory/consumption')) {
          listofVoucher = 'consumption';
          this.headTitle = 'Consumption';
          this.narrationDisplayFlag = true;
          this.routerName =
            'dashboard/transactions/inventory/consumption/tax-invoice';
          this.showContainer = false;
          this.showLabel2 = false;
        }

        this.filteredOptions = this.preSalesFormGroup.get('partyName').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
        this.masterService
          .getVoucherForSales(listofVoucher)
          .subscribe((response) => {


            this.voucherData = response;
            this.preSalesFormGroup.controls.voucherNo.setValue(
              this.voucherData[0].code
            );
            this.preSalesFormGroup.controls.billDate.setValue(
              this.datePipe.transform(Date(), 'yyyy-MM-dd')
            );

            this.showBillNoFiled(
              this.voucherData,
              this.preSalesFormGroup.get('voucherNo').value
            );

            this.isLoading = true;
            if (this.editSalesData?.voucherNo) {
              this.showLabel = true;
              this.preSalesFormGroup.controls['voucherNo'].disable();
            }
            if (this.editFlow && this.editItemMode) {
              this.patchValuesForEditFlow(this.editSalesData);
              this.preSalesFormGroup.controls['billNo'].disable();
            }
            else if (this.viewOnly) {
              this.patchValuesForEditFlow(this.editSalesData);
              this.disableAllFormControls();
            }
            this.filteredOptions = this.preSalesFormGroup.get('partyName').valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value || '')),
            );
          });
      });
  }

  disableAllFormControls() {
    this.preSalesFormGroup.disable();
    this.extraInfoSalesFormGroup.disable();
    this.ledgerInputDisable = true;
    this.disableSubmitButton = true;
  }


  patchValuesForEditFlow(data) {

    if (data == undefined) return;
    this.preSalesFormGroup.patchValue({
      partyName:
        data.aCode != ''
          ? this.ledger1Data.find((item) => item.code === data.aCode)?.name ??
          ''
          : '',
      billDate: this.datePipe.transform(data.billDate, 'yyyy-MM-dd'),
      billNo: data.voucherNo,
      voucherNo: this.voucherData.find((i) => i.code === data.voucher).code,
    });
    this.igstTotal = data.igst;
    this.cgstTotal = data.cgst;
    this.sgstTotal = data.sgst;

    this.extraInfoSalesFormGroup.patchValue({
      cgst: this.cgstTotal.toString(),
      sgst: this.sgstTotal.toString(),
      igst: this.igstTotal.toString(),
      uid: data.uid,
    });

    //Save appropriate State Code
    if (data.aCode != '') {
      this.saveStateCode(
        this.ledger1Data.find((item) => item.code === data.aCode)?.name
      );
    }
    this.editItemData.forEach((item) => {
      this.itemCardData.push(this.setItemData(item));
    });

    this.editLedgerData.forEach((item) => {
      this.ledgerCardData.push(this.setLedgerData(item));
    });

    this.calculateSubTotal();
    this.calculateGSTTypes();
    this.updateLedgerAmount();
    this.calculateTotal();
  }

  setItemData(itemData) {
    let itemDisplayData: any = {};
    if (itemData.iCode != '') {
      itemDisplayData.selectItemLabel = this.itemData.find(
        (item) => item.code === itemData.iCode
      ).name;
    }
    itemDisplayData.description = itemData.iDescription;
    itemDisplayData.hsnLabel = itemData.hsn;
    itemDisplayData.quantityLabel = itemData.actualQty;
    itemDisplayData.rateLabel = itemData.rate;
    itemDisplayData.discount = itemData.discPer;
    itemDisplayData.unit = itemData.unit;
    itemDisplayData.amountLabel = itemData.amount;
    itemDisplayData.igstLabel = itemData.igst;
    itemDisplayData.cgstLabel = itemData.cgst;
    itemDisplayData.sgstLabel = itemData.sgst;

    return itemDisplayData;
  }

  setLedgerData(ledgerData) {
    let ledgerDisplayData: any = {};
    if (ledgerData != undefined) {
      ledgerDisplayData.ledgerLabel = this.ledger2Data.find(
        (ledger) => ledger.code === ledgerData.acode
      ).name;
      ledgerDisplayData.amount = ledgerData.amt;
      ledgerDisplayData.percentLabel = ledgerData.rate;
      ledgerDisplayData.sgstLabel = this.ledger2Data.find(
        (ledger) => ledger.code === ledgerData.acode
      ).sgst;
      ledgerDisplayData.cgstLabel = this.ledger2Data.find(
        (ledger) => ledger.code === ledgerData.acode
      ).cgst;
      ledgerDisplayData.igstLabel = this.ledger2Data.find(
        (ledger) => ledger.code === ledgerData.acode
      ).igst;
    }
    return ledgerDisplayData;
  }

  saveStateCode(value: any) {
    this.ledger1StateCode = this.ledger1Data.find(
      (i) => i.name === value
    )?.stateCode;
    this.stateSelected = this.ledger1Data.find((i) => i.name === value)?.state;
    this.daysSelected = this.ledger1Data.find((i) => i.name === value)?.days;
    this.calculateGSTTypes();
    this.finalLedgerData = this.ledger1Data.find((i) => i.name === value);


  }

  openAddDetail() {
    const dialogRef = this.dialog.open(AddDetailComponent, {
      data: {
        ledgerData: this.ledger1Data,
        salesMenData: this.salesMenData,
        previousAdditionalData: this.additionalDataForBill,
        consigneeData: this.finalLedgerData
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && result != '') {
        this.additionalDataForBill = result;
      }

    });
  }

  openAddItem() {
    if (!this.editFlow) return;
    if (
      this.ledger1StateCode === null ||
      (this.ledger1StateCode === undefined &&
        this.headTitle != 'Production' &&
        this.headTitle != 'Consumption')
    ) {
      this.toastr.warning('Please select ledger name');
    } else {
      const dialogRef = this.dialog.open(AddItemComponent, {
        data: { itemList: this.itemData, isEditFlow: this.editFlow },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (
          (dialogRef.componentInstance.newItemData !== null &&
            dialogRef.componentInstance?.newItemData?.selectItemLabel !=
            null) ||
          dialogRef.componentInstance.newItemData?.selectItemLabel != undefined
        ) {
          this.itemCardData.push(dialogRef.componentInstance.newItemData);
          this.calculateSubTotal();
          this.calculateGSTTypes();
          this.updateLedgerAmount();
          this.calculateTotal();
        }
      });
    }
  }
  openEachItemDetail(itemData) {
    const dialogRef = this.dialog.open(EachItemDetailComponent, {
      data: {
        goDown: this.godownData,
        previousAdditionalData: this.additionalItemData,
        viewMode: this.viewOnly,
        index: this.itemCardData.findIndex(
          (i) => i.selectItemLabel.trim() === itemData.selectItemLabel.trim()
        ),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'submit') {
        if (this.editItemMode) {
          const index = dialogRef.componentInstance.editIndex;
          this.additionalItemData[index] =
            dialogRef.componentInstance.finalAdditionalItemData;
        } else {
          this.additionalItemData.push(
            dialogRef.componentInstance.finalAdditionalItemData
          );
        }
      }
    });
  }

  openAddLedger() {
    if (!this.editFlow) return;

    if (this.itemCardData.length === 0) {
      alert('Please add atleast one item');
    } else {
      const dialogRef = this.dialog.open(AddLedgerComponent, {
        data: {
          ledger: this.ledger2Data,
          subTotal: this.subTotal,
          isIGST: this.isIGST,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (
          dialogRef.componentInstance.finalLedgerData != null ||
          dialogRef.componentInstance.finalLedgerData != undefined
        ) {
          this.ledgerCardData.push(dialogRef.componentInstance.finalLedgerData);
          this.calculateSubTotal();
          this.calculateGSTTypes();
          this.updateLedgerAmount();
          this.calculateTotal();
        }
      });
    }
  }

  editItem(item, index) {
    const dialogRef = this.dialog.open(AddItemComponent, {
      data: {
        itemList: this.itemData,
        item: item,
        isEditFlow: this.editFlow,
        index: index,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (
        (dialogRef.componentInstance.newItemData !== null &&
          dialogRef.componentInstance?.newItemData?.selectItemLabel != null) ||
        dialogRef.componentInstance.newItemData?.selectItemLabel != undefined
      ) {
        this.itemCardData.splice(
          this.itemCardData.findIndex(
            (i) =>
              i !== null &&
              i?.id === dialogRef.componentInstance.newItemData?.id
          ),
          1
        );

        this.itemCardData.push(dialogRef.componentInstance.newItemData);
        this.calculateSubTotal();
        this.calculateGSTTypes();
        this.updateLedgerAmount();
        this.calculateTotal();
      }
    });
  }

  deleteItem(item, index) {
    if (!this.editFlow) return;
    this.itemCardData.splice(index, 1);

    if (this.itemCardData.length === 0) {
      this.ledgerCardData = [];
      this.subTotal = 0;
      this.total = 0;
      this.igstTotal = 0;
      this.cgstTotal = 0;
      this.sgstTotal = 0;
      this.extraInfoSalesFormGroup.controls['igst'].setValue(0);
      this.extraInfoSalesFormGroup.controls['cgst'].setValue(0);
      this.extraInfoSalesFormGroup.controls['sgst'].setValue(0);
    }
    this.calculateSubTotal();
    this.calculateGSTTypes();
    this.updateLedgerAmount();
    this.calculateTotal();
  }

  calculateSubTotal() {
    this.subTotal = 0;
    this.itemCardData.forEach((item) => {
      if (item.amountLabel) {
        this.subTotal += item.amountLabel;
      }
    });
  }

  updateLedgerAmount() {
    this.ledgerCardData.forEach((ledger) => {
      if (ledger.isRoundOff) {
        ledger.amount = Math.round(this.subTotal);
      } else {
        ledger.amount = (this.subTotal * Number(ledger.percentLabel)) / 100;
      }
    });
  }

  updateBillDateAndNo(event: any) {
    let groupValue = event.value;
    if (groupValue != undefined) {
      this.preSalesFormGroup.patchValue({
        billDate: this.datePipe.transform(Date(), 'yyyy-MM-dd'),
      })
    }
    this.showBillNoFiled(this.voucherData, this.preSalesFormGroup.get('voucherNo').value);
  }

  calculateTotal() {
    this.total = 0;
    this.itemCardData.forEach((item) => {
      if (item.amountLabel) {
        this.total += parseInt(item.amountLabel);
      }
    });
    this.ledgerCardData.forEach((ledger) => {
      if (ledger.amount) {
        if (ledger.isRoundOff && this.subTotal === this.total) {
          this.total = parseInt(ledger.amount);
        } else {
          this.total += parseInt(ledger.amount);
        }
      }
    });
    this.total += parseInt(this.extraInfoSalesFormGroup.get('igst').value) ?? 0;
    this.total += parseInt(this.extraInfoSalesFormGroup.get('cgst').value) ?? 0;
    this.total += parseInt(this.extraInfoSalesFormGroup.get('sgst').value) ?? 0;
  }

  calculateGSTTypes() {
    let gstNumber = sessionStorage.getItem('gst_no');

      this.igstTotal = 0;
      this.sgstTotal = 0;
      this.cgstTotal = 0;

    if (

      this.ledger1StateCode === null ||
      this.ledger1StateCode === undefined ||
      this.ledger1StateCode === 0

    ) {
      this.igstTotal = 0;
      this.sgstTotal = 0;
      this.cgstTotal = 0;
    }

    else if (!gstNumber) {
      if (parseInt(this.companyStateCode) !== this.ledger1StateCode) {
        this.isIGST = true;
      }
      this.igstTotal = this.extraInfoSalesFormGroup.get('igst').value !== '' ? parseInt(this.extraInfoSalesFormGroup.get('igst').value) : 0;
      this.sgstTotal = this.extraInfoSalesFormGroup.get('sgst').value !== '' ? parseInt(this.extraInfoSalesFormGroup.get('sgst').value) : 0;
      this.cgstTotal = this.extraInfoSalesFormGroup.get('cgst').value !== '' ? parseInt(this.extraInfoSalesFormGroup.get('cgst').value) : 0;
    }

    else {

      if (parseInt(this.companyStateCode) === this.ledger1StateCode) {
        this.itemCardData.forEach((item) => {
          this.cgstTotal +=
            (Number(item.amountLabel) * Number(item.cgstLabel)) / 100;
          this.sgstTotal +=
            (Number(item.amountLabel) * Number(item.sgstLabel)) / 100;
        });

        this.ledgerCardData.forEach((ledger) => {
          this.cgstTotal += ledger.cgstLabel ? Number(ledger.cgstLabel) : 0;
          this.sgstTotal += ledger.sgstLabel ? Number(ledger.sgstLabel) : 0;
        });
      }

      else {
        this.isIGST = true;
        this.itemCardData.forEach((item) => {
          this.igstTotal +=
            (Number(item.amountLabel) * Number(item.igstLabel)) / 100;
        });
        this.ledgerCardData.forEach((ledger) => {
          this.igstTotal += Number(ledger.igstLabel);
        });
      }
    }

    this.extraInfoSalesFormGroup.controls['igst'].setValue(this.igstTotal);
    this.extraInfoSalesFormGroup.controls['cgst'].setValue(this.cgstTotal);
    this.extraInfoSalesFormGroup.controls['sgst'].setValue(this.sgstTotal);

  }

  submitSalesData() {
    this.disableSubmitButton = true;

    const extraDetails = {
      isIGST: this.isIGST,
      sgst: parseInt(this.extraInfoSalesFormGroup.get('sgst').value),
      cgst: parseInt(this.extraInfoSalesFormGroup.get('cgst').value),
      igst: parseInt(this.extraInfoSalesFormGroup.get('igst').value),
      ledger1Data: this.ledger1Data,
      ledger2Data: this.ledger2Data,
      salesMenData: this.salesMenData,
      total: this.total,
      subTotal: this.subTotal,
      itemList: this.itemData,
    };

    if (this.url.endsWith('transactions/accounting/sale-bill')) {
      this.MName = 'Sales';
    } else if (this.url.endsWith('transactions/accounting/saleOrder')) {
      this.MName = 'SalesOrder';
    } else if (this.url.endsWith('transactions/accounting/purchase')) {
      this.MName = 'Purchase';
    } else if (this.url.endsWith('transactions/accounting/purchaseOrder')) {
      this.MName = 'PurchaseOrder';
    } else if (this.url.endsWith('transactions/accounting/creditNote')) {
      this.MName = 'CreditNote';
    } else if (this.url.endsWith('transactions/accounting/debitNote')) {
      this.MName = 'DebitNote';
    } else if (this.url.endsWith('transactions/inventory/material-in')) {
      this.MName = 'MaterialIn';
    } else if (this.url.endsWith('transactions/inventory/material-out')) {
      this.MName = 'MaterialOut';
    } else if (this.url.endsWith('transactions/inventory/production')) {
      this.MName = 'Production';
    } else if (this.url.endsWith('transactions/inventory/consumption')) {
      this.MName = 'consumption';
    }

    this.finalSalesData = this.utilityService.setSalesData(
      this.MName,
      this.preSalesFormGroup,
      this.additionalDataForBill,
      this.extraInfoSalesFormGroup,
      this.itemCardData,
      this.ledgerCardData,
      this.additionalItemData,
      extraDetails
    );
    if (this.editItemMode) {
      this.masterService.salesEditTransactions(this.finalSalesData).subscribe(
        (response) => {
          this.toastr.success('Data Updated Successfully', 'Submit Success', {
            closeButton: true,
          });
          this.disableSubmitButton = false;
        },
        (error) => {
          this.toastr.error('Unable To Submit Data', 'Submit Error', {
            closeButton: true,
          });
          this.disableSubmitButton = false;
        }
      );
    } else {
      this.masterService.salesTransactions(this.finalSalesData).subscribe(
        (response) => {
          this.preSalesFormGroup.controls['billNo'].setValue(response);

          this.showLabel = true;
          this.toastr.success('Data Submitted Successfully', 'Submit Success', {
            closeButton: true,
          });

          this.disableAllFormControls();
        },
        (error) => {
          if (error.error === 'Duplicate Voucher Number') {
            this.preSalesFormGroup.controls['billNo'].setErrors({ 'duplicateValue': true });
            this.toastr.error('Duplicate Voucher Number', 'Submit Error', {
              closeButton: true,
              timeOut: 6000,
            });
          }
          else {
            this.toastr.error('Unable To Submit Data', 'Submit Error', {
              closeButton: true,
            });
          }

          this.disableSubmitButton = false;
        }
      );
    }
  }

  resetFormControls() {
    this.total = 0;
    this.preSalesFormGroup.reset();
    this.extraInfoSalesFormGroup.reset();
    this.itemCardData = [];
    this.ledgerCardData = [];
  }

  pdfWork() {
    if (this.preSalesFormGroup.controls['partyName'].value == '' && this.showLabel2 == true) {
      this.toastr.info(
        'Please add Ledger and try again',
        'You have not added ledger.'
      );
      // }else if(this.preSalesFormGroup.controls["billNo"].value==''){
      //   this.toastr.info("Bill Number will not be displayed in the pdf","You haven't saved your bill yet.");
    } else {
      const extraDetails = {
        isIGST: this.isIGST,
        sgst: this.sgstTotal,
        cgst: this.cgstTotal,
        igst: this.igstTotal,
        ledger1Data: this.ledger1Data,
        ledger2Data: this.ledger2Data,
        salesMenData: this.salesMenData,
        total: this.total,
        subTotal: this.subTotal,
        itemList: this.itemData,
        // billNo : this.preSalesFormGroup.value.voucherNo
      };

      this.finalSalesData = this.utilityService.setSalesData(
        'Sales',
        this.preSalesFormGroup,
        this.additionalDataForBill,
        this.extraInfoSalesFormGroup,
        this.itemCardData,
        this.ledgerCardData,
        this.additionalItemData,
        extraDetails
      );

      this.router.navigate([this.routerName], {
        state: {
          data: this.finalSalesData,
          saleData: this.itemCardData,
          extraData: extraDetails,
          ledgerData: this.finalLedgerData,
        },
      });
    }
  }

  public exportToPDF(): void {
    let DATA = document.getElementById('itemTable');

    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

      PDF.save('saleBill.pdf');
    });
  }

  backClicked(): void {
    this.locationService.back();
  }

  showBillNoFiled(objArray: any, selectedValue: string): void {

    const foundObj = objArray.find((obj) => obj.code === selectedValue);


    if (foundObj) {
      if (foundObj.numbering === 'M') {
        this.showLabel = true;
      } else if (foundObj.numbering === 'A') {
        this.showLabel = false;
      }
    }
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

  displayFn(value?: any) {
    return value?.name;
  }

}
