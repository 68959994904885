<div mat-dialog-content>
    <p>Add new Unit</p>
    <form [formGroup]="form">

            <mat-form-field appearance="outline" [hideRequiredMarker]="hideRequiredMarker">
                <mat-label for="new_unit">New unit</mat-label>
                <input matInput formControlName="new_unit" id="new_unit">
                <mat-error
                    *ngIf="(form.get('new_unit')?.dirty || form.get('new_unit')?.touched) && form.get('new_unit')?.hasError('required') ">
                    Category is required</mat-error>
                <!-- <mat-error
                            *ngIf="form.controls['new_unit'].dirty  && form.controls['new_unit'].hasError('maxlength')">
                            Maximum of 50 characters</mat-error> -->
            </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button type="button" class="btn save" (click)="submit()" [disabled]="loader">Submit </button>
    <button mat-button type="button" class="btn save" (click)="cancel()">Cancel </button>
</div>
