import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderUpdateService {

  companyDetails$: Observable<any|undefined> = new BehaviorSubject<any|undefined>(undefined);

  constructor() {
    this.headerUpdate();
  }

  headerUpdate():void {
    let companyDetail = {
      companyName: sessionStorage.getItem('companyName'),
      companyCode: sessionStorage.getItem('CompanyCode'),
      };
    (this.companyDetails$ as BehaviorSubject<any | undefined>).next(companyDetail);
  }
}
