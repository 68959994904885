import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { ConfirmDialogModel } from '../confirmation-box/confirmDialog.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  @Input() sideNavStatus: boolean = false;

  itemList = [
    {
      id: 1,
      name: 'Home',
      icon: 'assets/img/home.svg',
      path:'dashboard',
      child: [],
    },
    {
      id: 2,
      name: 'Master',
      icon: 'assets/img/master.svg',
      path:'dashboard/master',
      child: [
        {
          id: 21,
          name: 'Accounting',
          icon: '',
          path:'dashboard/master',
          child: [
            {
              id: 211,
              name: 'Ledger',
              icon: '',
              path:'dashboard/master/accounting/ledger',
              child: [],
            },
            {
              id: 212,
              name: 'Voucher',
              icon: '',
              path:'dashboard/master/accounting/voucher',
              child: [],
            },
            {
              id: 213,
              name: 'Group',
              icon: '',
              path:'dashboard/master/accounting/group',
              child: [],
            },
            {
              id: 214,
              name: 'Cost Center',
              icon: '',
              path:'dashboard/master/accounting/cost-center',
              child: [],
            },
            {
              id: 215,
              name: 'Sales person',
              icon: '',
              path:'dashboard/master/accounting/salesmen',
              child: [],
            },
            {
              id: 216,
              name: 'Bill adjustment',
              icon: '',
              path:'dashboard/master/accounting/bill-adjustment',
              child: [],
            },
          ],
        },
        {
          id: 22,
          name: 'Inventory',
          icon: '',
          path:'dashboard/master',
          child: [
            {
              id: 221,
              name: 'Item',
              icon: '',
              path:'dashboard/master/inventory/item',
              child: [],
            },
            {
              id: 222,
              name: 'Stock Group',
              icon: '',
              path:'dashboard/master/inventory/stock-group',
              child: [],
            },
            {
              id: 223,
              name: 'Godown',
              icon: '',
              path:'dashboard/master/inventory/godown',
              child: [],
            },
            {
              id: 224,
              name: 'Sales price',
              icon: '',
              path:'dashboard/master/inventory/sales-price',
              child: [],
            },
            {
              id: 225,
              name: 'Purchase Cost',
              icon: '',
              path:'dashboard/master/inventory/purchase-cost',
              child: [],
            },
            {
              id: 226,
              name: 'HSN',
              icon: '',
              path:'dashboard/master/inventory/hsn',
              child: [],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: 'Transaction',
      icon: 'assets/img/transaction.svg',
      path:'dashboard/transactions',
      child: [
        {
          id: 31,
          name: 'Accounting',
          icon: '',
          path:'dashboard/transactions',
          child: [
            {
              id: 311,
              name: 'Sale bill',
              icon: '',
              path:'dashboard/transactions/accounting/sale-bill',
              child: [],
            },
            {
              id: 312,
              name: 'Sale Order',
              icon: '',
              path:'dashboard/transactions/accounting/saleOrder',
              child: [],
            },
            {
              id: 313,
              name: 'Receipt',
              icon: '',
              path:'dashboard/transactions/accounting/receipt',
              child: [],
            },
            {
              id: 314,
              name: 'Debit Note',
              icon: '',
              path:'dashboard/transactions/accounting/debitNote',
              child: [],
            },
            {
              id: 315,
              name: 'Deposit',
              icon: '',
              path:'dashboard/transactions/accounting/deposit',
              child: [],
            },
            {
              id: 316,
              name: 'Payment',
              icon: '',
              path:'dashboard/transactions/accounting/payment',
              child: [],
            },
            {
              id: 317,
              name: 'Purchase',
              icon: '',
              path:'dashboard/transactions/accounting/purchase',
              child: [],
            },
            {
              id: 318,
              name: 'Purchase Order',
              icon: '',
              path:'dashboard/transactions/accounting/creditNote',
              child: [],
            },
            {
              id: 319,
              name: 'Credit Note',
              icon: '',
              path:'dashboard/transactions/accounting/receipt',
              child: [],
            },
            {
              id: 320,
              name: 'Contra',
              icon: '',
              path:'dashboard/transactions/accounting/contra',
              child: [],
            },
            {
              id: 321,
              name: 'Withdraw',
              icon: '',
              path:'dashboard/transactions/accounting/withdraw',
              child: [],
            },
          ],
        },
        {
          id: 32,
          name: 'Inventory',
          icon: '',
          path:'dashboard/transactions',
          child: [
            {
              id: 322,
              name: 'Material In',
              icon: '',
              path:'dashboard/transactions/inventory/material-in',
              child: [],
            },
            {
              id: 323,
              name: 'Material Out',
              icon: '',
              path:'dashboard/transactions/inventory/material-out',
              child: [],
            },
            {
              id: 324,
              name: 'Production',
              icon: '',
              path:'dashboard/transactions/inventory/production',
              child: [],
            },
            {
              id: 325,
              name: 'Consumption',
              icon: '',
              path:'dashboard/transactions/inventory/consumption',
              child: [],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: 'Reports',
      icon: 'assets/img/report.svg',
      path:'dashboard/reports',
      child: [
        {
          id: 41,
          name: 'Register',
          icon: '',
          path:'dashboard/report/register',
          child: [
            {
              id: 411,
              name: 'Sale',
              icon: '',
              path:'dashboard/report/register/sales',
              child: [],
            },
            {
              id: 412,
              name: 'Sale Order',
              icon: '',
              path:'dashboard/report/register/sales-order',
              child: [],
            },
            {
              id: 413,
              name: 'Receipt',
              icon: '',
              path:'dashboard/report/register/receipt',
              child: [],
            },
            {
              id: 414,
              name: 'Debit Note',
              icon: '',
              path:'dashboard/report/register/debit-note',
              child: [],
            },
            {
              id: 415,
              name: 'Deposit',
              icon: '',
              path:'dashboard/report/register/deposit',
              child: [],
            },
            {
              id: 416,
              name: 'Payment',
              icon: '',
              path:'dashboard/report/register/payment',
              child: [],
            },
            {
              id: 417,
              name: 'Purchase',
              icon: '',
              path:'dashboard/report/register/purchase',
              child: [],
            },
            {
              id: 418,
              name: 'Purchase Order',
              icon: '',
              path:'dashboard/report/register/purchase-order',
              child: [],
            },
            {
              id: 419,
              name: 'Credit Note',
              icon: '',
              path:'dashboard/report/register/credit-note',
              child: [],
            },
            {
              id: 420,
              name: 'Contra',
              icon: '',
              path:'dashboard/report/register/contra',
              child: [],
            },
            {
              id: 421,
              name: 'Withdraw',
              icon: '',
              path:'dashboard/report/register/withdraw',
              child: [],
            },
            {
              id: 422,
              name: 'Cash Book',
              icon: '',
              path:'dashboard/report/register/cash-book',
              child: [],
            },
            {
              id: 423,
              name: 'Material In',
              icon: '',
              path:'dashboard/report/register/material-in',
              child: [],
            },
            {
              id: 424,
              name: 'Material Out',
              icon: '',
              path:'dashboard/report/register/material-out',
              child: [],
            },
            {
              id: 425,
              name: 'Production',
              icon: '',
              path:'dashboard/report/register/production',
              child: [],
            },
          ],
        },
        {
          id: 42,
          name: 'Statement Of Account',
          icon: '',
          path:'dashboard/report/statementOfAccount',
          child: [],
        },
        {
          id: 43,
          name: 'Final Account',
          icon: '',
          path:'dashboard/report/finalAccount',
          child: [],
        },
        {
          id: 44,
          name: 'MIS Report',
          icon: '',
          path:'dashboard/report/misReport',
          child: [],
        },
        {
          id: 45,
          name: 'Stock Item',
          icon: '',
          path:'dashboard/report/stock-item',
          child: [],
        },
        {
          id: 46,
          name: 'Stockgroup Report',
          icon: '',
          path:'dashboard/report/stockgroup-report',
          child: [],
        },
        {
          id: 46,
          name: 'Ledger Report',
          icon: '',
          path:'dashboard/report/ledger',
          child: [],
        },
        {
          id: 46,
          name: 'Stock Summary',
          icon: '',
          path:'dashboard/report/stock-summary',
          child: [],
        },
      ],
    },
    {
      id: 5,
      name: 'Register Client',
      icon: 'assets/img/register.svg',
      path:'register-client',
      child: [],
    },
    {
      id: 6,
      name: 'Settings',
      icon: 'assets/img/settings.svg',
      path:'',
      child: [],
    },
    {
      id: 7,
      name: 'User Permission',
      icon: 'assets/img/settings.svg',
      path:'user-permission',
      child: [],
    },
    {
      id: 8,
      name: 'Logout',
      icon: 'assets/img/power.svg',
      path:'logout',
      child: [],
    },
  ];

  openChild(item: any, index) {
    console.log(item.name);
  }

  // acc: any;
  status: boolean = false;
  nextElementSibling: any;

  constructor(private router: Router, private readonly dialog: MatDialog) {}

  ngOnInit(): void {}

  logOut() {
    let dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: "30%",
      height: "40%",
      data : ConfirmDialogModel.ForLogout()
    });

    dialogRef.afterClosed()
      .subscribe(confirmed => {
        if(confirmed)
          this.logOutConfirmed();
      });
  }

  private logOutConfirmed()
  {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }


  toggleList() {
    this.status = !this.status;
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
