import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn : 'root'
})
export class AuthGaurd implements CanActivate{

    constructor(private router :Router){}

    canActivate(): boolean {
        if(sessionStorage.getItem('Token') && sessionStorage.getItem('Token')!=null){
            return true
        }

        this.router.navigate(['/login'])
        return false
    }
}
