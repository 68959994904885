import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-register-report',
  templateUrl: './register-report.component.html',
  styleUrls: ['./register-report.component.css']
})
export class RegisterReportComponent implements OnInit {
  sideNavStatus:boolean =false;
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'L'){
      this.openComponent('dashboard/report/register/ledger')
    }else if(key == 'P'){
      this.openComponent('dashboard/report/register/purchase')
    }else if(key == 'S'){
      this.openComponent('dashboard/report/register/sales')
    }else if(key == 'B'){
      this.openComponent('dashboard/report/register/debit-note')
    }else if(key == 'N'){
      this.openComponent('dashboard/report/register/credit-note')
    }else if(key == 'O'){
      this.openComponent('dashboard/report/register/sales-order')
    }else if(key == 'D'){
      this.openComponent('dashboard/report/register/purchase-order')
    }else if(key == 'Y'){
      this.openComponent('dashboard/report/register/payment')
    }else if(key == 'R'){
      this.openComponent('dashboard/report/register/receipt')
    }else if(key == 'A'){
      this.openComponent('dashboard/report/register/contra')
    }else if(key == 'E'){
      this.openComponent('dashboard/report/register/deposit')
    }else if(key == 'W'){
      this.openComponent('dashboard/report/register/withdraw')
    }else if(key == 'M'){
      this.openComponent('dashboard/report/register/material-in')
    }else if(key == 'U'){
      this.openComponent('dashboard/report/register/material-out')
    }else if(key == 'I'){
      this.openComponent('dashboard/report/register/production')
    }else if(key == 'C'){
      this.openComponent('dashboard/report/register/consumption')
    }else if(key == 'K'){
      this.openComponent('dashboard/report/cash-book')
    }else if(event.key=="Escape"){
      this.openComponent('dashboard/reports')
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  constructor(private router: Router,) { }

  openComponent(component: string){
    this.router.navigate(['/'+component]);    
  }
  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }

}
