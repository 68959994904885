import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GroupFormComponent } from 'src/app/dashboard/master/accounting-master/group-form/group-form.component';
import { LedgerFormComponent } from 'src/app/dashboard/master/accounting-master/ledger-form/ledger-form.component';
import { SalesPersonComponent } from 'src/app/dashboard/master/accounting-master/sales-person/sales-person.component';
import { VoucherFormComponent } from 'src/app/dashboard/master/accounting-master/voucher-form/voucher-form.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BillAdjustmentComponent } from './bill-adjustment/bill-adjustment.component';


@Component({
  selector: 'app-accounting-master',

  templateUrl: './accounting-master.component.html',

  styleUrls: ['./accounting-master.component.css']
})
export class AccountingMasterComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key=event.key?.toUpperCase();
    if(key == 'L'){
      this.openComponent('dashboard/master/accounting/ledger')
    }else if(key == 'V'){
      this.openComponent('dashboard/master/accounting/voucher')
    }else if(key=='G'){
      this.openComponent('dashboard/master/accounting/group')
    }else if(key == 'C'){
      this.getError()
    }else if(key=='S'){
      this.openComponent('dashboard/master/accounting/salesmen')
    }else if(key == 'B'){
      this.openComponent('dashboard/master/accounting/bill-adjustment')
    }
  }


  constructor(public dialog: MatDialog, private router: Router,private toastr:ToastrService) { }

  ngOnInit(): void {
  }


  openLedger() {
    const dialogRef = this.dialog.open(LedgerFormComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openVoucher() {
    const dialogRef = this.dialog.open(VoucherFormComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openGroup() {
    const dialogRef = this.dialog.open(GroupFormComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openSales() {
    const dialogRef = this.dialog.open(SalesPersonComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openBillAdjustment() {
    const dialogRef = this.dialog.open(BillAdjustmentComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }


  //Function for alert box
  getError(){
      this.toastr.info('This feature will be available soon!','',{
        timeOut:3000
      });
    }

}

