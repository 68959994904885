import { Component, HostListener, Inject, Injectable, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MasterService } from 'src/services/master.service';
import { EventEmitter } from 'events';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/services/AppConstants';
import { ToastrService } from 'ngx-toastr';

import { Router } from '@angular/router';

@Component({
  selector: 'app-hsn-form',
  templateUrl: './hsn-form.component.html',
  styleUrls: ['./hsn-form.component.css']
})

export class HsnFormComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @Output() public emitData = new EventEmitter();
  passData!: any;
  isLoading: boolean = false;
  showErrorAlert: boolean = false;
  hsnGroupData: any;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  @ViewChild('newHsnForm') newHsnForm!: TemplateRef<any>;

  hsnForm = this.fb.group({
    'hsnNo': ['', Validators.required],
    /*'sgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
    'cgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
    'igst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],*/
    'sgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
    'cgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
    'igst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
  });
  code: any;


  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private http: HttpClient,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.resetHsnForm();
    this.getHsnData();
    console.clear()
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getHsnData() {
    this.isLoading = false;
    let listOfItems = ['HSN'];
    let optionValList = ['0']
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message");
      this.hsnGroupData = response.hsN0;
      this.passData = [this.appConstants.hsnFormColumnNames, this.hsnGroupData, "hsnGroupForm", this.appConstants.hsnExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.hsnFormColumnNames, false, "hsnGroupForm"];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }


  resetHsnForm() {
    this.showErrorAlert = false;
    this.hsnForm = this.fb.group({
      'hsnNo': ['', Validators.required],
      /*'sgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
      'cgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
      'igst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],*/

      'sgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
      'cgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
      'igst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]], //Added by Priyanka
    });
  }

  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }


  addHsn() {
    if (this.hsnForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      const generatedData = {
        HSN: this.hsnForm.controls['hsnNo'].value,
        Igst: Number(this.hsnForm.controls['igst'].value),
        Cgst: Number(this.hsnForm.controls['cgst'].value),
        Sgst: Number(this.hsnForm.controls['sgst'].value),
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateHsn(generatedData).subscribe(response => {
          this.getHsnData();
          this.toastr.success("Data Updated Successfully", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewHsn(generatedData).subscribe(response => {
          this.getHsnData();
          this.toastr.success("Data added Successful", "Add Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }


  addNewhsn(rowData?: any) {
    this.resetHsnForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newHsnForm, {
        disableClose: true,
        width: '60%',
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchHsnFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newHsnForm, {
        disableClose: true,
        width: '80%',
        height: '80%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteHsn(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchHsnFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newHsnForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchHsnFormValues(row) {
    this.hsnForm.patchValue({
      'hsnNo': row.name,
      'sgst': row.sgst,
      'igst': row.igst,
      'cgst': row.cgst,
    })
  }

  setOneNumberDecimalForIGST(event) {

    if(this.hsnForm.get('igst').value && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
      this.hsnForm.patchValue({
        sgst: (parseFloat(event.target.value)/2).toFixed(1),
        cgst: (parseFloat(event.target.value)/2).toFixed(1)
      })
    }
  else{
      event.target.value = 0;
      }
  }

  setOneNumberDecimal(event) {
    if((this.hsnForm.get('sgst').value || this.hsnForm.get('cgst').value) && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
    }
  else{
      event.target.value = 0;
      }
  }

  get igst() {
    return this.hsnForm.get('igst');
  }
  get cgst() {
    return this.hsnForm.get('cgst');
  }
  get sgst() {
    return this.hsnForm.get('sgst');
  }

}
