<div style="height: 100vh; padding-left: 0%">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
  <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
      <mat-drawer-content
        style="min-height: 93vh; width: 97vw; padding: 0 2rem; overflow: hidden"
      >
        <mat-toolbar>
          <nav class="headTitle">{{ headTitle }}</nav>
        </mat-toolbar>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="!isLoading"
        ></mat-progress-bar>
        <div *ngIf="isLoading">
          <!-- <form [formGroup]="searchBar" class="search alignSearch">
                        <input class="search-input" (keypress)="search($event)" formControlName="searchLabel"
                            placeholder="Search...">
                    </form> -->
          <form [formGroup]="partyForm" class="mainform">
            <table>
              <tr>
                <td>
                  <div class="col-sm-4" class="frst">
                    <label for="ledgerlable1" id="ldg">Ledger :</label>
                  </div>
                </td>
                <td>
                  <div class="input-group col-sm-4" id="cselect">
                    <select
                      class="form-control"
                      formControlName="ledgerlable1"
                      [(ngModel)]="selectedLevel"
                      (change)="tableFill()"
                      id="ledgerLabel"
                      placeholder="Select an Option"
                    >
                      <!-- <option disabled>Select</option> -->
                      <option
                        [ngValue]="ledgerlable1"
                        *ngFor="let ledgerlable1 of ledger1Data"
                        [value]="ledgerlable1.aCode"
                      >
                        {{ ledgerlable1.aName }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div style="float: right" class="input-group col-sm-6">
                    <mat-form-field [formGroup]="dateRange" appearance="fill">
                      <mat-label> Enter the start date and end date </mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input
                          matStartDate
                          placeholder="Start date"
                          formControlName="startDate"
                        />
                        <input
                          matEndDate
                          placeholder="End date"
                          formControlName="endDate"
                          (keydown.enter)="filterBasedOnDates()"
                          (dateChange)="filterBasedOnDates()"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-date-range-picker #picker> </mat-date-range-picker>
                    </mat-form-field>
                  </div>
                </td>
              </tr>
            </table>
          </form>
          <!-- upper form containing dropdown and date closed  -->

          <div id="ttlRow">
            <p>Total rows: {{totalRowCount}}</p>
          </div>
       </div>
       <div *ngIf="isLoading">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- bill date Column -->
          <ng-container matColumnDef="billDate">
            <th mat-header-cell *matHeaderCellDef> Bill Date </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.billDate | date : "dd/MM/YYYY" }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Voucher Name Column -->
          <ng-container matColumnDef="voucherName">
            <th mat-header-cell *matHeaderCellDef> Voucher Name</th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.voucherName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Voucher No Column -->
          <ng-container matColumnDef="voucherNo">
            <th mat-header-cell *matHeaderCellDef> Voucher No </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.voucherNo}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- ITEM Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Item </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.name}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Quantity Column -->
          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.qty}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Rate Column -->
          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef> Rate </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.rate}} </td>
            <td  class="subTotal" mat-footer-cell *matFooterCellDef>Total</td>
          </ng-container>

          <!-- Amount Column -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let ledger"> {{ledger.amount}} </td>
            <td  class="subTotal" mat-footer-cell *matFooterCellDef>{{total}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
          <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
          <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr>

        </table>
      </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of Data"></mat-paginator>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
