
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // API endpoint to search for taxpayer details
  private API_ENDPOINT = 'https://services.gst.gov.in/taxpayerapi/v0.2/search';

  constructor(private http: HttpClient) { }

  // Function to fetch company details using GSTIN
  getCompanyDetails(gstin: string): Observable<any> {
    // Request headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    // Request body
    const body = {
      'gstin': gstin
    };

    // Send POST request to API endpoint with headers and body
    return this.http.post<any>(this.API_ENDPOINT, body, { headers });
  }
}
