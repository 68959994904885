import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/services/master.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  isLoading: boolean = false;
  registerForm= this.fb.group({
    // 'serialLabel':['',Validators.required],
    'cNameLabel':['',Validators.required],
    'mailLabel':['',Validators.required],
    'passwordLabel':['',Validators.required],
    'fNameLabel':['',Validators.required],
    'lNameLabel':['',Validators.required],
  });;

  constructor(private router: Router,
    private fb:FormBuilder,
    private masterService: MasterService,
    private toastr:ToastrService,
      
    ) { }
  ngOnInit(): void {
    this.resetregisterForm();
  }
  resetregisterForm(){
    this.isLoading=true;
    this.registerForm= this.fb.group({
      // 'serialLabel':['',Validators.required],
      'cNameLabel':['',Validators.required],
      'mailLabel':['',Validators.required],
      'passwordLabel':['',Validators.required],
      'fNameLabel':['',Validators.required],
      'lNameLabel':['',Validators.required],
    });;
  }
  register(component:string){
    if (this.registerForm.invalid) {
      console.log("registerForm is Invalid"); 
    } else {
      this.isLoading=false;
      const generatedData = {
        // Serial : this.registerForm.controls['serialLabel'].value,
        CompanyName : this.registerForm.controls['cNameLabel'].value,
        Email : this.registerForm.controls['mailLabel'].value,
        Password : this.registerForm.controls['passwordLabel'].value,
        FirstName : this.registerForm.controls['fNameLabel'].value,
        LastName : this.registerForm.controls['lNameLabel'].value,
    }
  this.masterService.registerForm(generatedData).subscribe(response=> {
    this.router.navigate(['/'+component]);
    this.toastr.info(response,'Serial No. :',{
      timeOut:3000
    } )
  })
  }
}
  openComponent(component: string){
    this.router.navigate(['/'+component]);    
  }

}
