import { Component, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { MasterService } from 'src/services/master.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css']
})
export class AddItemComponent implements OnInit {
  @Output() public emitData = new EventEmitter();
  displayColumns!: any;
  showErrorAlert: boolean = false;
  data!: any;
  newItemData!: any;
  @ViewChild('newItemForm') newItemForm!: TemplateRef<any>;
  // myControl = new FormControl();
  options: any = [];
  filteredOptions: Observable<any[]>;
  editMode: boolean = false;
  isGst: boolean =false;

  itemForm = this.fb.group({
    'selectItemLabel': [''],
    'quantityLabel': ['', Validators.required],
    'rateLabel': [''],
    'hsnLabel': [''],
    'igstLabel': [''],
    'cgstLabel': [''],
    'sgstLabel': [''],
    'amountLabel': [''],
    'discountLabel': [''],
    'descriptionLabel': ['']
  });;
  constructor(private fb: FormBuilder,
    private masterService: MasterService,
    private dialog: MatDialog, private router: Router,
    @Inject(MAT_DIALOG_DATA) public inputData) { }

  ngOnInit() {
    this.resetItemForm();
    this.editMode = this.inputData.isEditFlow;
    if (this.inputData?.item) {
      this.updateValues(this.inputData.item, this.inputData.isEditFlow);

      if (!this.editMode) {
        this.itemForm.controls['selectItemLabel'].disable();
        this.itemForm.controls['descriptionLabel'].disable();
        this.itemForm.controls['quantityLabel'].disable();
        this.itemForm.controls['rateLabel'].disable();
        this.itemForm.controls['discountLabel'].disable();
        this.itemForm.controls['amountLabel'].disable();
      }
    }
    for (var i = 0; i < this.inputData.itemList.length; i++) {
      let optionObject = {
        name: this.inputData.itemList[i].name,
        balance: this.inputData.itemList[i].balance
      }
      this.options.push(optionObject);
    }

    this.filteredOptions = this.itemForm.controls['selectItemLabel'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  resetItemForm() {
    this.itemForm = this.fb.group({
      'selectItemLabel': [null],
      'quantityLabel': ['', Validators.required],
      'rateLabel': [''],
      'hsnLabel': [''],
      'igstLabel': [''],
      'cgstLabel': [''],
      'sgstLabel': [''],
      'amountLabel': [''],
      'discountLabel': [''],
      'descriptionLabel': ['']
    });
  }
  updateValues(event, isEditFlow?){
    if(isEditFlow =='new'){
      let currentItem = this.inputData.itemList.find(i => i.name.trim() === event.option.value.trim());
      this.itemForm.patchValue({
        'rateLabel': currentItem.rate,
        'discountLabel': currentItem.discount != null && currentItem.discount != undefined ? currentItem.discount : 0,
        'hsnLabel': currentItem.hsn,
        //'amountLabel' : this.calculateAmount(currentItem),
        'descriptionLabel': currentItem.description,
        'quantityLabel': currentItem.quantityLabel,
        'id': currentItem.id != null && currentItem.id != undefined ? currentItem.id : null
      });
      if (sessionStorage.getItem('gst_no')) {
        this.isGst = true
        this.itemForm.patchValue({
          'igstLabel': currentItem.igst,
          'cgstLabel': currentItem.cgst,
          'sgstLabel': currentItem.sgst,
        },
        )
      }

    } else {
      this.itemForm.patchValue({
        'rateLabel': event.rateLabel,
        'quantityLabel': event.quantityLabel,
        'discountLabel': event.discount,
        'hsnLabel': event.hsnLabel,
        'amountLabel': event.amountLabel,
        'descriptionLabel': event.description,
        'selectItemLabel': event.selectItemLabel,
        'id': this.inputData.index
      });

      if (sessionStorage.getItem('gst_no')) {
        this.isGst = true
        this.itemForm.patchValue({
          'igstLabel': event.igstLabel,
          'cgstLabel': event.cgstLabel,
          'sgstLabel': event.sgstLabel,
        },
        )
      }
    }
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value != undefined) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  calculateAmount(currentItem?: any) {
    let qtyLabel = this.itemForm.controls['quantityLabel'].value
    let rateLabel = this.itemForm.controls['rateLabel'].value
    let discountLabel = this.itemForm.controls['discountLabel'].value
    if (this.isNumber(qtyLabel)) {
      qtyLabel = Number(qtyLabel);
    } else {
      qtyLabel = 0
    }

    if (this.isNumber(rateLabel)) {
      rateLabel = Number(rateLabel);
    } else {
      rateLabel = 0
    }

    if (this.isNumber(discountLabel)) {
      discountLabel = Number(discountLabel);
    } else {
      discountLabel = 0
    }
    const qty: Number = this.itemForm.controls['quantityLabel'].value;
    if (currentItem != null || currentItem != undefined) {
      if ((currentItem.discount != undefined && currentItem.discount != null)) {
        return (((currentItem.qty * currentItem.rate) - (currentItem.qty * currentItem.rate * currentItem.discount)) / 100);
      } else {
        return (currentItem.qty * currentItem.rate);
      }
    } else if ((currentItem === undefined || currentItem === null)) {
      this.itemForm.controls['amountLabel'].setValue((((qtyLabel * rateLabel) - ((qtyLabel * rateLabel * Number(discountLabel)) / 100))));
      return '';
    } else {
      this.itemForm.controls['amountLabel'].setValue(qtyLabel * rateLabel);
      return '';
    }
  }

  addItem() {
    if (this.itemForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      let id = new Date();
      const generatedData = {
        description: this.itemForm.controls['descriptionLabel'].value,
        selectItemLabel: this.itemForm.controls['selectItemLabel'].value,
        quantityLabel: this.itemForm.controls['quantityLabel'].value,
        rateLabel: this.itemForm.controls['rateLabel'].value != null && this.itemForm.controls['rateLabel'].value != undefined && this.itemForm.controls['rateLabel'].value != '' ? this.itemForm.controls['rateLabel'].value : 0, //changed by Priyanka for issue #470
        hsnLabel: this.itemForm.controls['hsnLabel'].value,
        igstLabel: this.itemForm.controls['igstLabel'].value,
        cgstLabel: this.itemForm.controls['cgstLabel'].value,
        sgstLabel: this.itemForm.controls['sgstLabel'].value,
        amountLabel: Number(this.itemForm.controls['amountLabel'].value),
        discount: this.itemForm.controls['discountLabel'].value != null && this.itemForm.controls['discountLabel'].value != undefined ? this.itemForm.controls['discountLabel'].value : 0,
        unit: this.inputData.itemList.find(i => i.name.trim() === (this.itemForm.controls['selectItemLabel'].value).trim()) != null ? this.inputData.itemList.find(i => i.name.trim() === (this.itemForm.controls['selectItemLabel'].value).trim()).unit : null,
        id: this.inputData.index
      }

      this.newItemData = generatedData;
      this.dialog.closeAll();
    }
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

  openMasterItem() {
    this.dialog.closeAll();
    this.router.navigateByUrl('dashboard/master/inventory/item');
  }

  //Added by Priyanka for issue #470
  clearRateIfZero() {
    if (this.itemForm.controls['rateLabel'].value === 0)
      this.itemForm.controls['rateLabel'].setValue('');
  }

}
