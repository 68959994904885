import { Inject, Injectable } from '@angular/core'
import { environment } from '../environments/environment'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http'
import { AppConstants } from './AppConstants'
import { BehaviorSubject, Observable, throwError } from 'rxjs'

import { catchError } from 'rxjs/operators'

const API_URL = environment.apiUrl

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public passData!: BehaviorSubject<any>

  header = new HttpHeaders({
    'Content-Type': 'application/json',
  })

  options = { headers: this.header }

  getVoucher: any
  serverval: any = sessionStorage.getItem('ServerVal')
  companycode: any = sessionStorage.getItem('CompanyCode')
  serial: any = sessionStorage.getItem('Serial')

  constructor(private http: HttpClient, private appConstants: AppConstants) {}

  //mis party wise
  public getPartyDetails(MName: any): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('code', this.appConstants.code)
    return this.http.get(API_URL + '/api/MobileNXG/PartyWiseReport', {
      params,
      headers: this.header,
    })
  }

  //mis  item wise
  public getItemDetails(MName: any): Observable<any> {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('Code', this.appConstants.code)
    return this.http.get(API_URL + '/api/MobileNXG/ItemWiseReport', {
      params,
      headers: this.header,
    })
  }

  // report-register-ledger
  public getLedgerDetails(MName: any): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('ServerVal', this.serverval)
      .append('CompanyCode', this.companycode)
      .append('code', this.appConstants.code)
    return this.http.get(API_URL + '/api/MobileNXG/LedgerReport', {
      params,
      headers: this.header,
    })
  }

  // report-register-Misreport-partywise columnar
  public getPartyWiseColmnSales(MName: any): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('ServerVal', this.serverval)
      .append('CompanyCode', this.companycode)
    return this.http.get(API_URL + '/api/MobileNXG/PartyItemColumnarReport', {
      params,
      headers: this.header,
    })
  }

  // fetch data for receipt
  public getReportSales(
    MName: any,
    pageNumber: number,
    pageSize: number,
    startDate: string,
    endDate: string,
  ): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('PageNumber', pageNumber.toString())
      .append('PageSize', pageSize.toString())
      .append('StartDate', startDate)
      .append('EndDate', endDate)
    return this.http
      .get(API_URL + '/api/MobileNXG/FetchTranNoInv', {
        params,
        headers: this.header,
      })
      .pipe(catchError(this.errorHandler))
  }

  public searchTranNoInvReport(MName: any,
    pageNumber: number,
    pageSize: number,
    searchFilter: string,
    searchText: string,
    filterBy: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
      this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('MName', MName)
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
      .append('PageNumber', pageNumber.toString())
      .append('PageSize', pageSize.toString())
      .append('SearchField', searchFilter)
      .append('SearchText', searchText)
      .append('FilterBy', filterBy)
      .append('StartDate', startDate)
      .append('EndDate', endDate)
      return this.http
      .get(API_URL + '/api/MobileNXG/SearchTranNoInv', {
        params,
        headers: this.header,
      })
      .pipe(catchError(this.errorHandler))
  }

  public deleteSalesData(salesData: any): Observable<any> {
    this.options = { headers: this.header }
    return this.http
      .post(API_URL + '/api/MobileNXG/DeleteTran ', salesData, {
        headers: this.header,
        responseType: 'text',
      })
      .pipe(catchError(this.errorHandler))
  }

  //stock-summary
  public getStockSummary(): Observable<any> {
    this.serial = sessionStorage.getItem('Serial')
    this.companycode = sessionStorage.getItem('CompanyCode')
    this.serverval = sessionStorage.getItem('ServerVal')
    let params = new HttpParams()
      .append('Serial', this.serial)
      .append('CompanyCode', this.companycode)
      .append('ServerVal', this.serverval)
    return this.http.get(API_URL + '/api/MobileNXG/StockSummary', {
      params,
      headers: this.header,
    })
  }

  // Error handlercode
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server error.')
  }
}
