import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingComponent } from 'src/app/setting/setting.component';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { ConfirmDialogModel } from '../confirmation-box/confirmDialog.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  @Input() item;
  expanded: boolean = false;

  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {}

  onItemSelected(item: any) {
    this.router.navigate(['/' + item.path]);
    if (item.name == 'Settings') {
      const dialogRef = this.dialog.open(SettingComponent);
    }
    if (item.name == 'Logout') {
      let dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        disableClose: true,
        width: '30%',
        height: '40%',
        data: ConfirmDialogModel.ForLogout(),
      });

      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) this.logOutConfirmed();
      });
    }
  }

  logOutConfirmed() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  expandOn(item: any) {
    if (item?.child && item?.child?.length) {
      this.expanded = !this.expanded;
      // this.expanded = true;
      // console.log("exp", this.expanded);
    }
    else {
      this.onItemSelected(item)
    }
  }
  expandOff(item: any) {
    // if (item.child && item.child.length) {
    //   this.expanded = !this.expanded;
    //   // console.log("exp", this.expanded);
    // }
  }
}
