<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

            <!-- Head part -->
            <div class="main-containers">
                <mat-toolbar>
                    <nav class="headTitle">Sales Person</nav>
                </mat-toolbar>
            </div>
            <div *ngIf="isLoading">
                <button mat-button class="add-btn" (click)="addNewsalesPerson()">Add New</button>
                <ng-template #newSalesPersonForm>
                    <mat-dialog-content>
                        <p class="cross" mat-dialog-close>&#10005;</p>
                        <h1 class="companytitle" mat-dialog-title>SALES PERSON</h1>
                        <mat-card>
                            <mat-card-content>
                                <form [formGroup]="salesPersonForm" class="mainForm">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="nameLabel"><span class="required">*</span>Sales Person Name </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="nameLabel"
                                                placeholder="Enter Name" id="nameLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && salesPersonForm.get('nameLabel').hasError('required')">
                                                Sales Person Name is required
                                            </mat-error>
                                        </span>

                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-dialog-actions align='center' *ngIf="forView">
                            <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addSalesPerson()">Submit</button>
                            <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                        </mat-dialog-actions>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (salesMenFormComp)="addNewsalesPerson($event)"></app-table>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>