<header>
    <div class="topLeft">
        <p><i class="ham-burger icon fa-2x fa" (click)="sidenavToggle()" *ngIf="!customerLogin"><img *ngIf="menuStatus"
                    src="assets/img/close.svg" alt=""><img *ngIf="!menuStatus" src="assets/img/burger.svg"
                    alt=""></i><a class="serial">Serial : <span>{{Serial}}</span></a></p>
    </div>
    <!-- <p class="serial"><i class="ham-burger icon fa-2x fa"></i>Serial : <span>{{Serial}}</span></p> -->

    <h2 class="anugata fontStyle" id="appName">Gateway of Accounting</h2>
    <div class="topRight">
        <!-- menu box -->
        <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            (mouseenter)="menuTrigger.openMenu()" class="menuIcon">
            <mat-icon>info</mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">

            <div class="menuItem" (mouseleave)="menuTrigger.closeMenu()">
                <p><span class="companyAbout">Company Name : </span> <span class="cValue">
                        {{companyName}}</span>
                </p>
                <p><span class="companyAbout">Company Code : </span> <span class="cValue">
                        {{CompanyCode}}</span>
                </p>
                <button class="changeCompany" mat-stroked-button (click)="changeCompany()" *ngIf="!customerLogin">
                    <mat-icon>swap_horizontal_circle</mat-icon> Change
                    Company
                </button>
            </div>

        </mat-menu>
        <button mat-button (click)="logOut()" class="logOut">Log Out</button>
    </div>
</header>