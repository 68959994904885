import { LocationStrategy } from '@angular/common';
import { error } from '@angular/compiler/src/util';
import { Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { CompanyListComponent } from '../../company-list/company-list.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  hide = true;
  isLoading: boolean = false;


  loginForm= this.fb.group({
    'serialLabel':['',Validators.required],
    'userLabel':['',Validators.required],
    'passwordLabel':['',Validators.required],
  });


  constructor(private router: Router,
    private fb:FormBuilder,
    private masterService: MasterService,
    public dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr:ToastrService,
    ) {}
  ngOnInit(): void {
    if(sessionStorage.getItem('Token')){
      this.router.navigate(['/dashboard'])
    }else{
      this.resetLoginForm();
    }
  }
  resetLoginForm(){
    this.isLoading=true;
    this.loginForm= this.fb.group({
      'serialLabel':['',Validators.required],
      'userLabel':['',Validators.required],
      'passwordLabel':['',Validators.required],
    });;
  }
  login(component:string){
    if (this.loginForm.invalid) {
    } else {
      this.isLoading=false;
      const generatedData = {
        Serial : this.loginForm.controls['serialLabel'].value,
        Email : this.loginForm.controls['userLabel'].value,
        password : this.loginForm.controls['passwordLabel'].value,
        IsSalesOrder : false,
    }
  this.masterService.LoginForm(generatedData).subscribe(response=> {
    const token: string = response.split("|")[0];
    const serialNo: string = response.split("|")[1];
    sessionStorage.setItem("Token",token);
    sessionStorage.setItem("customerLogin","false")
    sessionStorage.setItem('Serial',serialNo);
    this.router.navigate(['/'+component]);
    const dialogRef = this.dialog.open(CompanyListComponent,{ disableClose: true });
    },
  (error=>{
    this.isLoading=true;
    this.toastr.error("Try to login again","Login Error");
  }))}
}
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
