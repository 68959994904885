<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="push">
        </mat-drawer>
        <mat-drawer-content style="min-height: 100vh;">

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">Reports</nav>
                </mat-toolbar>
            </div>

            <!-- Body Start -->
            <div class="btn-container">

                <table>
                    <tr>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/register')"><span class="highlighter">R</span>egister</button>
                        </td>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/statementOfAccount')">Statement of
                                <span class="highlighter">A</span>ccount</button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/stock-item')">Stock <span class="highlighter">I</span>tem</button>
                        </td>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/finalAccount')"><span class="highlighter">F</span>inal
                                Account</button>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/misReport')"><span class="highlighter">M</span>IS Report</button>
                        </td>
                        <td>
                            <button mat-raised-button (click)="InProgressDialog()">S<span class="highlighter">t</span>ockgroup Report</button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/ledger')"><span class="highlighter">L</span>edger Report</button>
                        </td>
                        <td>
                            <button mat-raised-button (click)="openComponent('dashboard/report/stock-summary')"><span class="highlighter">S</span>tock Summary</button>
                        </td>
                    </tr>


                </table>
                <!-- <button mat-raised-button (click)="openComponent('dashboard/report/register')">Register</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/statementOfAccount')">Statement of
                    <span class="highlighter">A</span>ccount</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/statementOfInventory')">Statement of
                    <span class="highlighter">I</span>nventory</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/finalAccount')"><span class="highlighter">F</span>inal
                    Account</button>

                <button mat-raised-button (click)="openComponent('dashboard/report/misReport')">MIS Report</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/register/item-report')">Item Report</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/register/ledger')">Ledger</button>
                <button mat-raised-button (click)="openComponent('dashboard/report/register/ledger')">Stock Summary</button>   -->
            </div>


        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>
