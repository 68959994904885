import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-person',
  templateUrl: './sales-person.component.html',
  styleUrls: ['./sales-person.component.css']
})
export class SalesPersonComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newSalesPersonForm') newSalesPersonForm!: TemplateRef<any>;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isLoading: boolean = false;
  passData!: any;
  data!: any;
  salesMenGroupData: any;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;

  salesPersonForm = this.fb.group({
    'nameLabel': ['', Validators.required],
  }); code: any;
  ;
  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstant: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getData()
    this.resetSalePersonForm();
    console.clear()
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }


  getData() {
    this.isLoading = false;
    let listOfItems = ['SalesMen'];
    let optionValList = ['0']
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.salesMenGroupData = response.salesMen0;
      this.passData = [this.appConstant.salesMenGroupFormColumnNames, this.salesMenGroupData, 'salesPersonGroupForm', this.appConstant.salesMenGroupExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstant.salesMenGroupFormColumnNames, false, 'salesPersonGroupForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  resetSalePersonForm() {
    this.showErrorAlert = false;
    this.salesPersonForm = this.fb.group({
      'nameLabel': ['', Validators.required],
    });
  }

  addSalesPerson() {
    if (this.salesPersonForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      const generatedData = {
        Name: this.salesPersonForm.controls['nameLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateSalesMen(generatedData).subscribe(response => {
          this.getData();
          this.toastr.success("Data Updated Successful", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewSalesPerson(generatedData).subscribe(response => {
          this.getData();
          this.toastr.success("Data added Successful", "Add Success")
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }

  addNewsalesPerson(rowData?: any) {
    this.resetSalePersonForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newSalesPersonForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchSalesPersonForm(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newSalesPersonForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteSalesMen(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchSalesPersonForm(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newSalesPersonForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchSalesPersonForm(row) {
    this.salesPersonForm.patchValue({
      'nameLabel': row.name,
    });
  }
}



