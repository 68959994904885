import { Component, OnInit } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { MonthlyPurchaseSale } from 'src/app/model/monthly-purchase-sale';
import { DashboardService } from 'src/services/dashboard.service';

@Component({
  selector: 'purchase-sales-trend',
  templateUrl: './purchase-sales-trend.component.html',
  styleUrls: ['./purchase-sales-trend.component.css']
})
export class PurchaseSalesTrendComponent implements OnInit {

  data: MonthlyPurchaseSale[] | undefined;
  graphData: { sale: number, purchase: number }[];


  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.getCompanyTransactionData().subscribe(response => {
      this.data = response.monthly;
      this.graphData = this.generateDataForGraph(this.data);
      this.genratePurchaseSalesGraph();
    })
  }

  genratePurchaseSalesGraph() {
    const chartOptions = {
      series: [
        {
          name: "purchase",
          data: this.graphData.flatMap(item => item.purchase)
        },
        {
          name: "sales",
          data: this.graphData.flatMap(item => item.sale)
        }
      ],
      chart: {
        type: "bar",
        height: 250,
        width: 670
      },
      plotOptions: {
        bar: {
          vertical: true,
          dataLabels: {
            orientation: 'vertical',
            position: 'center'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: 15,
        style: {
          fontSize: "12px",
          colors: ['#000000']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        offsetY: 0,
        offsetX: -10
      },
    };

    const purchaseSalesGraph = new ApexCharts(document.querySelector('#purchase-sales-graph'), chartOptions);
    purchaseSalesGraph.render();
  }

  generateDataForGraph(data: MonthlyPurchaseSale[]): { sale: number, purchase: number }[] {
    var monthlyData = [];

    for (let i = 0; i < 12; i++) {
      monthlyData[i] = { sale: 0, purchase: 0 };
    }

    if (data) {
      for (const entry of data) {
        const month = entry.month - 1;
        monthlyData[month].sale += entry.sale || 0;
        monthlyData[month].purchase += entry.purchase || 0;
      }
    }
    return monthlyData;
  }


}
