<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>
<div class="container component-class">
    <mat-dialog-content>
        <p class="actionIcon" mat-dialog-close>
            <mat-icon class="cross" matTooltip="close">clear</mat-icon>
        </p>
        <h1 mat-dialog-title align="start">Add Details</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="itemForm" class="mainForm">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="ledgerLabel">Select Ledger</label>
                        </div>
                        <div class="input-group col-sm-8">
                            <select class="form-control" formControlName="ledgerLabel" id="ledgerLabel" placeholder="Select an
                                Option" (change)="calculateAmount()">
                                <option [value]=''>Select</option>
                                <option *ngFor="let ledger of ledgerData.ledger">{{ledger.name}}</option>
                            </select>
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="openMasterLedger()">+</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="percentLabel">Percent</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="percentLabel" placeholder="percent"
                                id="percentLabel" (keyup)="calculateAmount($event)">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="amountLabel">Amount</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="amountLabel" placeholder="amount"
                                id="amountLabel" [(ngModel)]="amount">
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-dialog-content>

    <mat-dialog-actions align='center'>
        <button class="btn btn-primary col-sm-2" (click)="addItem()">Add</button>
        <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>