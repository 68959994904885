<link href="https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;700&display=swap" rel="stylesheet">
<button (click)="exportToPDF()">Export to PDF</button>
<button (click)="exportToExcel()">Export to Excel</button>
<button (click)="switchFormat('dashboard/transactions/accounting/sale-bill/tax-invoice3')">Change Format</button>
<div id="panel" class="invoice-box">
    <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb">
        <tbody>
            <!-- title -->
            <tr height="65">
                <!-- qr code starts -->
                <td align="ceter" class="txt cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
                </td>
            </tr>
        <tr>
                <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
            </tr>
            <tr>
                <td width="50%">V No.</td>
                <td>Date</td>
            </tr>
           
            <tr>
                <!-- <td width="3%">&nbsp;</td> -->
                <td colspan="12">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                        <tbody>
                            <!-- first table box starts -->
                            
                            <!-- first table box ends -->
                            <!-- second table box starts -->
                           
                            <!-- second table box ends -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                            <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                                                <strong>Sr NO.</strong>
                                            </td>
                                            <td width="57%" align="center" class="borderLeft borderBottom">
                                                <strong>Item</strong>
                                            </td>
                                            
                                            <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty
                                                </strong></td>
                                            <td width="15%" align="center" class="borderLeft borderBottom"><strong>Rate
                                                </strong></td>
                                            <td width="15%" align="center" class="borderLeft borderBottom">
                                                <strong>Amount</strong>
                                            </td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">1.</td>
                                            <td align="start" class="borderLeft">
                                                {{editData.data.INVData[0].Description}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].ActualQty}}
                                            </td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Unit}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Rate}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Amount}}
                                            </td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                         </tr>
                                    </tbody>
                                </table>
                            </tr>

                            <tr class="outerTable">
                                <table width="70%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="100%"><span class="tdData">Narration</span></td>
                                        </tr>
                                        <tr>
                                            <td><span class="tdData">{{editData.data.company}}</span></td>
                                            <!-- <td><span class="tdData">S T ELECTRICAL PVT LTD</span></td> -->
                                        </tr>
                                        <tr>
                                            <!-- <td><span class="tdData">MIDC, BHOSARI, PUNE</span></td> -->
                                            <td><span class="tdData">{{editData.data.ADDRESS}}</span></td>
                                        </tr>
                                        <tr>
                                            <!-- <td width="50%"><strong><span class="tdData">State</span></strong>
                                            </td> -->
                                            <td width="50%"><span class="tdData">{{editData.data.state}}</span></td>
                                        </tr>
                                        <tr>
                                            <!-- <td width="50%"><strong><span class="tdData">State Code</span></strong>
                                            </td> -->
                                            <td width="50%"><span class="tdData">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Amount in Words</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            
                                            <td><span>{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                               
                            


                        
                            
                               
                                <table width="40%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr height="25">
                                            <td width="40%"><strong><span
                                                        class="tdData">Total</span></strong></td>
                                            <td width="60%"><span
                                                    class="tdData colon">{{editData.data.CGST}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span
                                                        class="tdData"></span></strong></td>
                                            <td width="60%"><span
                                                    class="tdData">{{editData.data.SGST}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span class="tdData">
                                                        </span></strong></td>
                                            <td width="60%"><span
                                                    class="tdData">{{editData.data.roundOff}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span class="tdData">
                                                        </span></strong></td>
                                            <td width="60%"><span>{{editData.data.roundOff}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span class="tdData"></span></strong>
                                            </td>
                                            <td width="60%"><span
                                                    class="tdDate">{{editData.data.BillAmount}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span class="tdData"></span></strong>
                                            </td>
                                            <td width="60%"><span
                                                    class="tdData">{{editData.data.BillAmount}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- third table box ends -->


                            <!-- fourth table box starts -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                           <td>
                                             <tr>
                                                <td><strong><span class="tdData"></span></strong>
                                                </td>
                                                <td><span class="tdData">{{editData.data.Bill}}</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><strong><span class="tdData"></span></strong>
                                                </td>
                                                <td><span class="tdData">{{editData.data.Bill}}</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><strong><span class="tdData"></span></strong>
                                                </td>
                                                <td><span class="tdData">{{editData.data.Bill}}</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><strong><span class="tdData"></span></strong>
                                                </td>
                                                <td><span class="tdData">{{editData.data.Bill}}</span>
                                                </td>
                                             </tr>
                                           </td>
                                            <td width="20%" align="center">
                                                <strong></strong>
                                            </td>
                                            <td width="35%" align="center">
                                                <strong>For Anu Computer Consultancy <br><br>Authorised
                                                    Signature</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- fourth table box ends -->
                            <!-- Fifth table box starts -->
                            <tr>
                                <!-- qr code starts -->
                                <td align="start" class="txt" id="qrCode">
                                    <qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode>
                                </td>
                                <!-- qr code ends -->

                            </tr>
                            <!-- fifth table box ends -->

                            <tr>
                                <td colspan="4">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>