<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="push">
        </mat-drawer>
        <mat-drawer-content style="min-height: 90vh;">

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">Statement of Account</nav>
                </mat-toolbar>
            </div>
            <div class="btns-container">
                <div class="btn-container">
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">L</span>edger Outstanding</button>
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">G</span>roup Outstanding</button>
                </div>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>