import { AfterViewInit, HostListener, Inject, ViewChild } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { ConfirmationBoxComponent } from 'src/app/shared/confirmation-box/confirmation-box.component'
import { AppConstants } from 'src/services/AppConstants'
import { MasterService } from 'src/services/master.service'
import { ReportsService } from 'src/services/reports.service'
import { DatePipe } from '@angular/common'
import { ChangeDetectorRef } from '@angular/core'
import { ApplicationRef } from '@angular/core'
import { SortByOrder } from 'src/app/shared/sortByOrder'
import { ConfirmDialogModel } from 'src/app/shared/confirmation-box/confirmDialog.model'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { TableFilter } from 'src/app/shared/tableFilter'

export interface Transaction {
  VoucherNo: number
  LedgerName: string
  billDate: any
  billAmount: number
  voucherName: string
  name: string
}

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
})
export class SalesComponent implements OnInit {
  sideNavStatus: boolean = false
  companycode: string
  varStore: any

  applyFilter(event) {
    this.dataSource.filter = event.target.value.trim().toLowerCase()
  }
  @ViewChild(MatPaginator) paginator: MatPaginator
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase()
    if (
      event.key == 'Escape' &&
      sessionStorage.getItem('customerLogin') == 'false'
    ) {
      this.openComponent('dashboard/report/register')
    } else if (
      event.key == 'Escape' &&
      sessionStorage.getItem('customerLogin') == 'true'
    ) {
      this.openComponent('dashboard')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component])
  }
  salesGroupData: any
  salesItemData: any
  salesLedgerData: any
  passData: any[]
  dataSource: MatTableDataSource<any>
  isLoading: boolean = false
  errorValue: boolean = false
  listOfItems: string[]
  url: any = window.location.href
  editurl: string
  rName: string
  selectedColumn: string
  selectedCondtion: string
  searchValue: string
  columnName: any
  IsSales: string
  dateRange: FormGroup
  excelColumn: any = []
  excelColumnData: any = []
  excelData: any = []
  headTitle: string
  routerName: string
  customerLogin: boolean
  sortfields = [
    { orderBy: 'billDate', order: 'desc' },
    { orderBy: 'voucherName', order: 'asc' },
    { orderBy: 'voucherNo', order: 'desc' },
  ]
  showParticularCol = true
  filterConditionsList: string[] = []
  disableSelectionDropDown: boolean = false
  serachUnFilterData: any = []
  filterDataCount: number = 0
  pageSize = 10
  pageNumber = 1
  totalPages = 3
  totalRecords = 0

  constructor(
    private masterService: MasterService,
    private reportService: ReportsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('customerLogin') == 'true') {
      this.customerLogin = true
    } else {
      this.customerLogin = false
    }
    let startYear: number = Number(sessionStorage.getItem('startYear'))
    let startMonth: number = Number(sessionStorage.getItem('startMonth'))

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()

    let financialYearStartDate: Date
    let financialYearEndDate: Date

    if (currentDate >= new Date(currentYear, 3, 1)) {
      financialYearStartDate = new Date(currentYear, 3, 1)
      financialYearEndDate = new Date(currentYear + 1, 2, 31)
    } else {
      financialYearStartDate = new Date(currentYear - 1, 3, 1)
      financialYearEndDate = new Date(currentYear, 2, 31)
    }

    this.dateRange = this.fb.group({
      startDate: [financialYearStartDate],
      endDate: [financialYearEndDate],
    })
    this.getData()
  }

  /** Gets the total cost of all transactions. */
  getTotalCost() {
    return this.dataSource.filteredData
      .map((t) => t.billAmount)
      .reduce((acc, value) => acc + value, 0)
  }

  columnSelectionFn(value: any) {
    this.dataSource.data.filter((item) => {
      if (typeof item[value] !== 'string') {
        this.disableSelectionDropDown = false
      } else {
        this.disableSelectionDropDown = true
      }
    })
  }

  valueSearchEvent(value: string) {
    if (value == '') {
      this.pageNumber = 1
      this.callSpecificApi()
      return
    }
    console.log(true)

    let startDate =
      this.dateRange.controls['startDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['startDate'].value, 'yyyy-MM-dd')
            .toString()
        : ''
    let endDate =
      this.dateRange.controls['endDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['endDate'].value, 'yyyy-MM-dd')
            .toString()
        : ''
    if (this.url.endsWith('report/register/sales')) {
      this.masterService
        .searchTranReport(
          'sales',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.salesGroupData = response.salesTran
          this.salesItemData = response.salesInv
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.dataSource = new MatTableDataSource(this.salesGroupData)
        })
    } else if (this.url.endsWith('report/register/purchase')) {
      this.masterService
        .searchTranReport(
          'purchase',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.salesGroupData = response.purchaseTran
          this.salesItemData = response.purchaseInv
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.dataSource = new MatTableDataSource(this.salesGroupData)
        })
    } else if (this.url.endsWith('report/register/sales-order')) {
      this.masterService
        .searchTranReport(
          'salesorder',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          if (this.customerLogin == false) {
            this.salesGroupData = response.salesorderTran
          } else if (this.customerLogin == true) {
            var j = 0
            let saleData: any = []
            for (var i = 0; i < response.salesorderTran.length; i++) {
              if (
                response.salesorderTran[i].aCode ==
                sessionStorage.getItem('List[3]')
              ) {
                saleData[j] = response.salesorderTran[i]
                j = j + 1
                this.salesGroupData = saleData
              }
            }
          }
          this.salesItemData = response.salesorderInv
          this.salesLedgerData = response.salesorderLed
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/purchase-order')) {
      this.masterService
        .searchTranReport(
          'purchaseorder',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.purchaseorderTran
          this.salesItemData = response.purchaseorderInv
          this.salesLedgerData = response.purchaseorderLed
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/debit-note')) {
      this.masterService
        .searchTranReport(
          'debitnote',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.debitnoteTran
          this.salesItemData = response.debitnoteInv
          this.salesLedgerData = response.debitnoteLed
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/credit-note')) {
      this.masterService
        .searchTranReport(
          'creditnote',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.creditnoteTran
          this.salesItemData = response.creditnoteInv
          this.salesLedgerData = response.creditnoteLed
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('/report/register/material-in')) {
      this.masterService
        .searchTranReport(
          'materialin',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.materialinTran
          this.salesItemData = response.materialinInv
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/material-out')) {
      this.masterService
        .searchTranReport(
          'materialout',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.materialoutTran
          this.salesItemData = response.materialoutInv
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/production')) {
      this.masterService
        .searchTranReport(
          'production',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.productionTran
          this.salesItemData = response.productionInv
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/consumption')) {
      this.masterService
        .searchTranReport(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          console.log(this.totalRecords)

          this.salesGroupData = response.consumptionTran
          this.salesItemData = response.consumptionInv
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/receipt')) {
      this.reportService
        .searchTranNoInvReport(
          'receipt',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.receipt
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/payment')) {
      this.reportService
        .searchTranNoInvReport(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.payment
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/contra')) {
      this.reportService
        .searchTranNoInvReport(
          'contra',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.contra
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/deposit')) {
      this.reportService
        .searchTranNoInvReport(
          'deposit',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.deposit
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/cash-book')) {
      this.reportService
        .getLedgerDetails(this.listOfItems)
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.cashbook
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    } else if (this.url.endsWith('report/register/withdraw')) {
      this.reportService
        .searchTranNoInvReport(
          'withdraw',
          this.pageNumber,
          this.pageSize,
          this.selectedColumn,
          value,
          this.selectedCondtion,
          startDate,
          endDate,
        )
        .subscribe((response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.withdraw
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
        })
    }
  }

  getData() {
    this.columnName = [
      {
        name: 'Voucher Name',
        value: 'voucherName',
      },
      {
        name: 'Voucher No',
        value: 'voucherNo',
      },

      {
        name: 'Bill Amount',
        value: 'billAmount',
      },
    ]
    if (
      !this.url.endsWith('report/register/production') &&
      !this.url.endsWith('report/register/consumption')
    ) {
      this.columnName.push({
        name: 'Particulars',
        value: 'name',
      })
    }

    this.selectedColumn = this.columnName[0].value

    this.filterConditionsList = this.appConstants.filterCondtions

    this.selectedCondtion = this.appConstants.filterCondtions[0]
    this.callSpecificApi()
    // this.filterBasedOnDates()
  }

  callSpecificApi(): void {
    let startDate =
      this.dateRange.controls['startDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['startDate'].value, 'yyyy-MM-dd')
            .toString()
        : ''
    let endDate =
      this.dateRange.controls['endDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['endDate'].value, 'yyyy-MM-dd')
            .toString()
        : ''

    if (this.url.endsWith('report/register/sales')) {
      this.listOfItems = ['sales']
      this.headTitle = 'Sales'
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.salesTran
            this.salesItemData = response.salesInv
            this.salesLedgerData = response.salesLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            this.dataSource.filterPredicate = function (
              data,
              filter: string,
            ): boolean {
              return data.voucherNo.toLowerCase().includes(filter)
            }

            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)

            // Passing Data For excelsheet
            //sessionStorage.setItem('responseInv', JSON.stringify(response.salesInv))//saving responseInv for Excel Use
            // Merging the Inv and Tran data
            if (
              response.salesInv.length === undefined ||
              response.salesInv.length === null
            )
              return
            for (var i = 0; i < response.salesInv.length; i++) {
              for (var j = 0; j < response.salesTran.length; j++) {
                if (response.salesInv[i].uid == response.salesTran[j].uid) {
                  this.excelData[i] = {
                    ...response.salesInv[i],
                    ...response.salesTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/sales/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/purchase')) {
      this.headTitle = 'Purchase'
      this.listOfItems = ['purchase']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.purchaseTran
            this.salesItemData = response.purchaseInv
            this.salesLedgerData = response.purchaseLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.purchaseInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.purchaseInv.length; i++) {
              for (var j = 0; j < response.purchaseTran.length; j++) {
                if (
                  response.purchaseInv[i].uid == response.purchaseTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.purchaseInv[i],
                    ...response.purchaseTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/purchase/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/sales-order')) {
      this.headTitle = 'Sales Order'
      this.listOfItems = ['salesorder']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            if (this.customerLogin == false) {
              this.salesGroupData = response.salesorderTran
            } else if (this.customerLogin == true) {
              var j = 0
              let saleData: any = []
              for (var i = 0; i < response.salesorderTran.length; i++) {
                if (
                  response.salesorderTran[i].aCode ==
                  sessionStorage.getItem('List[3]')
                ) {
                  saleData[j] = response.salesorderTran[i]
                  j = j + 1
                  this.salesGroupData = saleData
                }
              }
            }
            this.salesItemData = response.salesorderInv
            this.salesLedgerData = response.salesorderLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.salesorderInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            var k = 0
            for (var i = 0; i < response.salesorderInv.length; i++) {
              for (var j = 0; j < this.salesGroupData?.length; j++) {
                if (
                  response.salesorderInv[i].uid == this.salesGroupData[j]?.uid
                ) {
                  this.excelData[k] = {
                    ...response.salesorderInv[i],
                    ...this.salesGroupData[j],
                  }
                  k = k + 1
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/sales-order/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/purchase-order')) {
      this.columnName = this.appConstants.salesFormName
      this.selectedColumn = this.appConstants.salesFormName[0]
      this.headTitle = 'Purchase Order'
      this.listOfItems = ['purchaseorder']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.purchaseorderTran
            this.salesItemData = response.purchaseorderInv
            this.salesLedgerData = response.purchaseorderLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/debit-note')) {
      this.headTitle = 'Debit Note'
      this.listOfItems = ['debitnote']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.debitnoteTran
            this.salesItemData = response.debitnoteInv
            this.salesLedgerData = response.debitnoteLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.debitnoteInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.debitnoteInv.length; i++) {
              for (var j = 0; j < response.debitnoteTran.length; j++) {
                if (
                  response.debitnoteInv[i].uid == response.debitnoteTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.debitnoteInv[i],
                    ...response.debitnoteTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/debit-note/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/credit-note')) {
      this.headTitle = 'Credit Note'
      this.listOfItems = ['creditnote']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.creditnoteTran
            this.salesItemData = response.creditnoteInv
            this.salesLedgerData = response.creditnoteLed
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.creditnoteInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.creditnoteInv.length; i++) {
              for (var j = 0; j < response.creditnoteTran.length; j++) {
                if (
                  response.creditnoteInv[i].uid ==
                  response.creditnoteTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.creditnoteInv[i],
                    ...response.creditnoteTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/credit-note/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('/report/register/material-in')) {
      this.headTitle = 'Material In'
      this.listOfItems = ['materialin']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.materialinTran
            this.salesItemData = response.materialinInv
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.materialinInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.materialinInv.length; i++) {
              for (var j = 0; j < response.materialinTran.length; j++) {
                if (
                  response.materialinInv[i].uid ==
                  response.materialinTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.materialinInv[i],
                    ...response.materialinTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/material-in/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/material-out')) {
      this.headTitle = 'Material Out'
      this.listOfItems = ['materialout']
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.materialoutTran
            this.salesItemData = response.materialoutInv
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            // Passing Data For excelsheet
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.materialoutInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.materialoutInv.length; i++) {
              for (var j = 0; j < response.materialoutTran.length; j++) {
                if (
                  response.materialoutInv[i].uid ==
                  response.materialoutTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.materialoutInv[i],
                    ...response.materialoutTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/material-out/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/production')) {
      this.headTitle = 'Production'
      this.listOfItems = ['production']
      this.showParticularCol = false
      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.productionTran
            this.salesItemData = response.productionInv
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.productionInv),
            ) //saving responseInv for Excel Use
            // Merging the Inv and Tran data
            for (var i = 0; i < response.productionInv.length; i++) {
              for (var j = 0; j < response.productionTran.length; j++) {
                if (
                  response.productionInv[i].uid ==
                  response.productionTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.productionInv[i],
                    ...response.productionTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/production/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/consumption')) {
      this.headTitle = 'Consumption'
      this.listOfItems = ['consumption']
      this.showParticularCol = false

      this.masterService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            console.log(this.totalRecords)

            this.salesGroupData = response.consumptionTran
            this.salesItemData = response.consumptionInv
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])

            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            console.log(this.dataSource)

            this.columnSelectionFn(this.selectedColumn) //saving responseInv for Excel Use // Merging the Inv and Tran data
            // Passing Data For excelsheet
            /*sessionStorage.setItem(
              'responseInv',
              JSON.stringify(response.consumptionInv),
            )*/ for (
              var i = 0;
              i < response.consumptionInv.length;
              i++
            ) {
              for (var j = 0; j < response.consumptionTran.length; j++) {
                if (
                  response.consumptionInv[i].uid ==
                  response.consumptionTran[j].uid
                ) {
                  this.excelData[i] = {
                    ...response.consumptionInv[i],
                    ...response.consumptionTran[j],
                  }
                }
              }
            }
            this.excelColumnData = [
              this.appConstants.ExcelSalesColumnNames,
              this.excelData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/consumption/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/receipt')) {
      this.headTitle = 'Receipt'
      this.listOfItems = ['receipt']
      this.reportService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.receipt
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            this.excelColumnData = [
              this.appConstants.ExcelReceiptColumnNames,
              this.salesGroupData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/receipt/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/payment')) {
      this.headTitle = 'Payment'
      this.listOfItems = ['payment']
      this.reportService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.payment
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            this.excelColumnData = [
              this.appConstants.ExcelReceiptColumnNames,
              this.salesGroupData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/payment/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/contra')) {
      this.headTitle = 'Contra'
      this.listOfItems = ['contra']
      this.reportService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.contra
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            this.excelColumnData = [
              this.appConstants.ExcelReceiptColumnNames,
              this.salesGroupData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/contra/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/register/deposit')) {
      this.headTitle = 'Deposit'
      this.listOfItems = ['deposit']
      this.reportService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.deposit
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            this.excelColumnData = [
              this.appConstants.ExcelReceiptColumnNames,
              this.salesGroupData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/deposit/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    } else if (this.url.endsWith('report/cash-book')) {
      this.headTitle = 'Cash Book'
      this.listOfItems = ['cashbook']
      this.appConstants.code = 'A2'
      this.reportService.getLedgerDetails(this.listOfItems).subscribe(
        (response) => {
          this.totalPages = response.paginationData[0].totalPages
          this.totalRecords = response.paginationData[0].totalRecords
          this.salesGroupData = response.cashbook
          this.passData = [this.appConstants.salesFormName, this.salesGroupData]
          this.dataSource = new MatTableDataSource(this.passData[1])
          SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
          this.columnSelectionFn(this.selectedColumn)
          this.isLoading = true
        },
        (error) => {
          this.passData = [this.appConstants.salesFormName, false]
          this.errorValue = true
          console.log('error message:-', error)
          this.isLoading = true
        },
      )
    } else if (this.url.endsWith('report/register/withdraw')) {
      this.headTitle = 'Withdraw'
      this.listOfItems = ['withdraw']
      this.reportService
        .getReportSales(
          this.listOfItems,
          this.pageNumber,
          this.pageSize,
          startDate,
          endDate,
        )
        .subscribe(
          (response) => {
            this.totalPages = response.paginationData[0].totalPages
            this.totalRecords = response.paginationData[0].totalRecords
            this.salesGroupData = response.withdraw
            this.passData = [
              this.appConstants.salesFormName,
              this.salesGroupData,
            ]
            this.dataSource = new MatTableDataSource(this.passData[1])
            SortByOrder.sortByOrder(this.dataSource.data, this.sortfields)
            this.columnSelectionFn(this.selectedColumn)
            // Passing Data For excelsheet
            this.excelColumnData = [
              this.appConstants.ExcelReceiptColumnNames,
              this.salesGroupData,
            ]
            // Saving the Url in which I have to navigate to the excel
            this.routerName =
              'dashboard/report/register/withdraw/excelDownloadTable'
            // Stoping the progressbar after evrything is done
            this.isLoading = true
          },
          (error) => {
            this.passData = [this.appConstants.salesFormName, false]
            this.errorValue = true
            console.log('error message:-', error)
            this.isLoading = true
          },
        )
    }
  }
  exportToExcel() {
    this.router.navigate([this.routerName])
    sessionStorage.setItem(
      'excelColumnData',
      JSON.stringify(this.excelColumnData),
    )
  }

  //Isedit = 1 => Edit flow else view flow
  viewR(rowData, Isedit) {
    let salesData: any = []
    this.IsSales = 'N'
    if (this.url.endsWith('report/register/receipt')) {
      this.rName = '/dashboard/transactions/accounting/receipt'
    } else if (this.url.endsWith('report/register/payment')) {
      this.rName = '/dashboard/transactions/accounting/payment'
    } else if (this.url.endsWith('report/register/contra')) {
      this.rName = '/dashboard/transactions/accounting/contra'
    } else if (this.url.endsWith('report/register/deposit')) {
      this.rName = '/dashboard/transactions/accounting/deposit'
    } else if (this.url.endsWith('report/register/withdraw')) {
      this.rName = '/dashboard/transactions/accounting/withdraw'
    } else if (this.url.endsWith('report/register/sales')) {
      this.rName = '/dashboard/transactions/accounting/sale-bill'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/sales-order')) {
      this.rName = '/dashboard/transactions/accounting/saleOrder'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/purchase')) {
      this.rName = '/dashboard/transactions/accounting/purchase'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/purchase-order')) {
      this.rName = '/dashboard/transactions/accounting/purchaseOrder'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/debit-note')) {
      this.rName = '/dashboard/transactions/accounting/debitNote'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/credit-note')) {
      this.rName = '/dashboard/transactions/accounting/creditNote'
      this.IsSales = 'Y'
    } else if (this.url.endsWith('report/register/material-in')) {
      this.rName = '/dashboard/transactions/inventory/material-in'
      this.IsSales = 'Inv'
    } else if (this.url.endsWith('report/register/material-out')) {
      this.rName = '/dashboard/transactions/inventory/material-out'
      this.IsSales = 'Inv'
    } else if (this.url.endsWith('report/register/production')) {
      this.rName = '/dashboard/transactions/inventory/production'
      this.IsSales = 'Inv'
    } else if (this.url.endsWith('report/register/consumption')) {
      this.rName = '/dashboard/transactions/inventory/consumption'
      this.IsSales = 'Inv'
    }
    if (this.IsSales == 'Y') {
      salesData.push({ sales: rowData })
      salesData.push({
        items: this.salesItemData.filter((item) => item.uid === rowData.uid),
      })
      salesData.push({
        ledger: this.salesLedgerData.filter((item) => item.uid === rowData.uid),
      })
    } else if (this.IsSales == 'Inv') {
      salesData.push({ sales: rowData })
      salesData.push({
        items: this.salesItemData.filter((item) => item.uid === rowData.uid),
      })
      salesData.push({ ledger: [] })
    }

    this.router.navigate([this.rName], {
      state: {
        data:
          this.IsSales == 'Y' || this.IsSales == 'Inv' ? salesData : rowData,
        editFlow: Isedit != 0,
        viewOnly: Isedit == 0,
      },
    })
  }

  delete(row: any) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: '35%',
      height: '40%',
      data: {
        title: 'Delete Confirmation',
        message: 'Do you want to DELETE this record?',
        dialogData: row,
      },
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.isLoading = false
        this.salesGroupData.forEach((value: any, index: any) => {
          if (value == row) {
            this.salesGroupData.splice(index, 1)
            this.dataSource = new MatTableDataSource(this.salesGroupData)
            const generatedData = {
              MName: this.listOfItems[0],
              Serial: sessionStorage.getItem('Serial'),
              CompanyCode: sessionStorage.getItem('CompanyCode'),
              ServerVal: sessionStorage.getItem('ServerVal'),
              UID: row.uid,
            }
            this.reportService.deleteSalesData(generatedData).subscribe(
              (response) => {
                this.isLoading = true
                this.toastr.success('Deleted Successfully', 'Delete Success', {
                  closeButton: true,
                })
              },
              (error) => {
                this.isLoading = true
                this.toastr.error('Unable to Delete Data', 'Delete Unsuccess', {
                  closeButton: true,
                })
              },
            )
          }
        })
      }
    })
  }

  public getRowsValue(flag) {
    if (flag === null) {
      return this.dataSource.filteredData.length
    } else {
      return 'hello'
    }
  }

  filterBasedOnDates() {
    let startDate = null
    let endDate = null
    let updatedRows = []
    let varStore =
      this.passData != undefined &&
      this.passData.length > 1 &&
      this.passData[1] !== undefined
        ? this.passData[1]
        : []

    startDate =
      this.dateRange.controls['startDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['startDate'].value, 'MM-dd-yyyy')
            .toString()
        : ''
    endDate =
      this.dateRange.controls['endDate'].value != null
        ? this.datePipe
            .transform(this.dateRange.controls['endDate'].value, 'MM-dd-yyyy')
            .toString()
        : ''
    for (let element of varStore) {
      let elementDate = this.datePipe
        .transform(element.billDate, 'MM-dd-yyyy')
        .toString()
      if (
        new Date(startDate) <= new Date(elementDate) &&
        new Date(endDate) >= new Date(elementDate)
      ) {
        updatedRows.push(element)
      }
    }

    if (updatedRows.length > 0) {
      this.dataSource = new MatTableDataSource(updatedRows)
      this.serachUnFilterData = this.dataSource.data
      this.dataSource.paginator = this.paginator
    } else {
      this.dataSource = new MatTableDataSource(updatedRows)
      this.serachUnFilterData = this.dataSource.data
      this.dataSource.paginator = this.paginator
    }
  }

  redirectToTransaction(): void {
    if (this.url.endsWith('report/register/sales')) {
      this.router.navigate(['dashboard/transactions/accounting/sale-bill'])
    } else if (this.url.endsWith('report/register/purchase')) {
      this.router.navigate(['dashboard/transactions/accounting/purchase'])
    } else if (this.url.endsWith('report/register/sales-order')) {
      this.router.navigate(['dashboard/transactions/accounting/saleOrder'])
    } else if (this.url.endsWith('report/register/purchase-order')) {
      this.router.navigate(['dashboard/transactions/accounting/purchaseOrder'])
    } else if (this.url.endsWith('report/register/receipt')) {
      this.router.navigate(['dashboard/transactions/accounting/receipt'])
    } else if (this.url.endsWith('report/register/credit-note')) {
      this.router.navigate(['dashboard/transactions/accounting/creditNote'])
    } else if (this.url.endsWith('report/register/debit-note')) {
      this.router.navigate(['dashboard/transactions/accounting/debitNote'])
    } else if (this.url.endsWith('report/register/contra')) {
      this.router.navigate(['dashboard/transactions/accounting/contra'])
    } else if (this.url.endsWith('report/register/deposit')) {
      this.router.navigate(['dashboard/transactions/accounting/deposit'])
    } else if (this.url.endsWith('report/register/withdraw')) {
      this.router.navigate(['dashboard/transactions/accounting/withdraw'])
    } else if (this.url.endsWith('report/register/payment')) {
      this.router.navigate(['dashboard/transactions/accounting/payment'])
    } else if (this.url.endsWith("report/register/material-in'")) {
      this.router.navigate(['/dashboard/transactions/inventory/material-in'])
    } else if (this.url.endsWith("report/register/material-out'")) {
      this.router.navigate(['/dashboard/transactions/inventory/material-out'])
    } else if (this.url.endsWith('report/register/production')) {
      this.router.navigate(['/dashboard/transactions/inventory/production'])
    } else if (this.url.endsWith('report/register/consumption')) {
      this.router.navigate(['/dashboard/transactions/inventory/consumption'])
    }
  }

  onPageChange(pageSize: number, pageIndex: number): void {
    console.log(event)

    this.pageSize = pageSize
    this.pageNumber = pageIndex + 1 // API expects 1-based page index
    if (this.searchValue != '') {
      this.valueSearchEvent(this.searchValue)
    } else {
      this.callSpecificApi() // Reload data with updated pagination
    }
  }
}
