import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutoCompleteValidator } from 'src/app/shared/autocomplete-validation';

import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { TransactionService } from 'src/services/transaction.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (event.key == "Escape") {
      this.openComponent('dashboard/transactions')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

  disableSubmitButton: boolean = false;
  isLoading: boolean = false;
  showLabel: boolean = false;
  rShowLabel: boolean = false;
  voucherData: any;
  ledger1Data: any;
  ledger3Data: any;
  billValue: any = "";
  receiptFormGroup: FormGroup;
  mName: string;
  listOfVoucher: string[];
  headTitle: string;
  editData: any;
  particular: string;
  cash: string;
  rName: string;
  url: any = window.location.href;
  minDate: any;
  maxDate: any;
  startMonth: any;
  startYear: any;
  forView: boolean = false;
  filteredOptions: Observable<string[]|any>;
  validationMsg= "Please Select Correct Option"
  filteredOptionsParticulars: Observable<string[]|any>;



  constructor(private masterService: MasterService, private transactionService: TransactionService,
    private fb: FormBuilder,
    private appConstants: AppConstants, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }

    this.startMonth = sessionStorage.getItem('startMonth');
    this.startYear = sessionStorage.getItem('startYear');
    this.minDate = this.datePipe.transform(new Date(this.startYear, this.startMonth - 1, 1), 'yyyy-MM-dd')
    this.maxDate = this.datePipe.transform(new Date(this.startYear, this.startMonth - 1 + 12, 1 - 1), 'yyyy-MM-dd')

    this.route.paramMap.subscribe(() => {
      this.editData = window.history.state
    });

    this.receiptFormGroup = this.fb.group({
      'voucherNo': [''],
      'billNo': [''],
      'date': [''],
      'refNo': [''],
      'refDate': [null],
      'cashBank': ['',[AutoCompleteValidator.autocompleteValidator(this.checkCashExists.bind(this))]],
      'particular': ['',[AutoCompleteValidator.autocompleteValidator(this.checkParticularExists.bind(this))]],
      'amount1': ['', Validators.required],
      'amount2': [''],
      'narration': [''],
      'chequeNo': [''],
      'chequeDate': [null],
      'banker': [''],
      'clearDate': [null],
    })

    this.getData();

    if (this.editData.editFlow) {
      this.showLabel = true
      this.receiptFormGroup.controls['billNo'].disable();
      this.patchReceiptValues(this.editData.data);
      this.forView = false
    } else if (this.editData.viewOnly) {
      this.showLabel = true
      this.patchReceiptValues(this.editData.data);
      this.forView = true
      this.disableAllFormControls();
    }

    this.filteredOptions = this.receiptFormGroup.get('cashBank').valueChanges.pipe(
      startWith(''),
      map(value =>this._filter(value || '')),
    );

    this.filteredOptionsParticulars= this.receiptFormGroup.get('particular').valueChanges.pipe(
      startWith(''),
      map(value =>this.particularfilter(value || '')),
    );


  }

  private checkCashExists(value): boolean {
    return this.ledger3Data?.some(item => item.name === value);
  }

  private checkParticularExists(value): boolean {
    return this.ledger1Data?.some(item => item.name === value);
  }


  getData() {

    this.isLoading = false
    let listOfLedgers = ['Ledger', 'Ledger'];
    let optionValList = ['1', '3'];
    this.masterService.getSalesDetails(listOfLedgers, optionValList).subscribe(response => {
      this.ledger1Data = response.ledger1;
      this.ledger3Data = response.ledger3;
      if (this.url.endsWith("transactions/accounting/receipt")) {
        this.listOfVoucher = ['receipt'];
        this.rShowLabel = true
        this.mName = "Receipt"
        this.headTitle = "Receipt"
        this.cash = "Cash/Bank"
        this.particular = "Particular"
      } else if (this.url.endsWith("transactions/accounting/payment")) {
        this.listOfVoucher = ['payment'];
        this.rShowLabel = true
        this.mName = "payment"
        this.headTitle = "Payment"
        this.cash = "Cash/Bank"
        this.particular = "Particular"
      } else if (this.url.endsWith("transactions/accounting/contra")) {
        this.listOfVoucher = ['contra'];
        this.mName = "contra"
        this.headTitle = "Contra"
        this.cash = "Cash/Bank"
        this.particular = "Bank/Cash"
      } else if (this.url.endsWith("transactions/accounting/deposit")) {
        this.listOfVoucher = ['deposit'];
        this.mName = "deposit"
        this.headTitle = "Deposit"
        this.cash = "Cash"
        this.particular = "Bank"
      } else if (this.url.endsWith("transactions/accounting/withdraw")) {
        this.listOfVoucher = ['withdraw'];
        this.mName = "withdraw"
        this.headTitle = "Withdraw"
        this.cash = "Bank"
        this.particular = "Cash"
      }
      this.masterService.getVoucherForSales(this.listOfVoucher).subscribe(response => {
        this.voucherData = response;
        this.receiptFormGroup.controls.voucherNo.setValue(this.voucherData[0].code);
        this.receiptFormGroup.controls.date.setValue(this.datePipe.transform(Date(), 'yyyy-MM-dd'));

        this.showBillNoFiled(this.voucherData,this.receiptFormGroup.get('voucherNo').value);

        this.isLoading = true
      });

    });
  }

  private _filter(value: string): string[] {
     const filterValue = typeof value === "string" ? value.toLowerCase() : null;
     return this.ledger3Data.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  private particularfilter(value: string): string[] {
     const filterValue = typeof value === "string" ? value.toLowerCase() : null;
     return this.ledger1Data.filter(particular => (particular.name).toLowerCase().includes(filterValue));
  }

  disableAllFormControls():void {
    this.receiptFormGroup.disable();
    this.disableSubmitButton = true;
  }

  setTwoNumberDecimal(event) {
    event.target.value = parseFloat(event.target.value).toFixed(2);
    this.receiptFormGroup.patchValue({
      'amount2': event.target.value,
      'amount1': event.target.value
    })
  }

  updateBillDateAndNo(event: any) {
    let groupValue = event.value;
    if (groupValue != undefined) {
          this.receiptFormGroup.patchValue({
            'date': this.datePipe.transform(Date(), 'yyyy-MM-dd'),
          })
        }
  this.showBillNoFiled(this.voucherData,this.receiptFormGroup.get('voucherNo').value);
  }

  saveReceipt() {

    if(this.receiptFormGroup.get('cashBank').hasError('noMatch') || this.receiptFormGroup.get('particular').hasError('noMatch') ){
      this.toastr.info("Please provide the required data","Wrong Input Data", {
        closeButton: true,
        timeOut: 10000
      })
    }
    else{
    this.disableSubmitButton = true;
    this.isLoading = false
    let cashBank = this.ledger3Data.filter(item=>(item.name).includes(this.receiptFormGroup.controls['cashBank'].value));
    let particular = this.ledger1Data.filter(item=>(item.name).includes(this.receiptFormGroup.controls['particular'].value));
    const generatedData = {
      MName: this.mName,
      Adjust : [],
      Vcode: this.receiptFormGroup.controls['voucherNo'].value,
      Serial: sessionStorage.getItem('Serial'),
      CompanyCode: sessionStorage.getItem('CompanyCode'),
      ServerVal: sessionStorage.getItem('ServerVal'),
      BillDate: this.receiptFormGroup.controls['date'].value,
      RefNo: !this.rShowLabel ? '' : this.receiptFormGroup.controls['refNo'].value,
      RefDate: !this.rShowLabel ? '' : this.receiptFormGroup.controls['refDate'].value,
      Acode: particular[0].code,    //--Particular
      BillAmount: this.receiptFormGroup.controls['amount1'].value,
      Bcode: cashBank[0].code,    //-- Bank/Code
      ChequeNo: this.receiptFormGroup.controls['chequeNo'].value,
      ChequeDate: this.receiptFormGroup.controls['chequeDate'].value,
      Narration: this.receiptFormGroup.controls['narration'].value,
      Banker: this.receiptFormGroup.controls['banker'].value,
      UID: !this.editData.editFlow ? '' : this.editData.data.uid,
      BillNo: !this.showLabel ? '' : Number(this.receiptFormGroup.controls['billNo'].value),
      Clear: this.receiptFormGroup.controls['clearDate'].value
    }

    if (this.editData.editFlow) {
      if (this.url.endsWith("transactions/accounting/receipt")) {
        this.rName = '/dashboard/report/register/receipt'
      } else if (this.url.endsWith("transactions/accounting/payment")) {
        this.rName = '/dashboard/report/register/payment'
      } else if (this.url.endsWith("transactions/accounting/contra")) {
        this.rName = '/dashboard/report/register/contra'
      } else if (this.url.endsWith("transactions/accounting/deposit")) {
        this.rName = '/dashboard/report/register/deposit'
      } else if (this.url.endsWith("transactions/accounting/withdraw")) {
        this.rName = '/dashboard/report/register/withdraw'
      }

      this.transactionService.updateReceiptTransaction(generatedData).subscribe(
        (response) => {
        this.toastr.info("Document Successfully Updated", "Successfully Submitted", {
          timeOut: 4000
        })
        this.disableSubmitButton = false;
        this.router.navigate([this.rName])
        this.disableAllFormControls();
        },
        (error) => {
          this.toastr.error('Unable To Submit Data', 'Submit Error', {
          closeButton: true,
        });
          this.disableSubmitButton = false;
        }
      );
    }
    else {
      this.transactionService.receiptTransaction(generatedData).subscribe(
        (response) => {
        this.isLoading = true;
        this.receiptFormGroup.controls['billNo'].setValue(response);
        this.showLabel = true;
        this.toastr.info(`Document No : ${response}`, "Successfully Submitted", {
          timeOut: 4000
        })
        this.disableAllFormControls();
        this.disableSubmitButton = false;
      },

      (error) => {
        if(error.error === 'Duplicate Voucher Number'){
          this.receiptFormGroup.controls['billNo'].setErrors({'duplicateValue': true});
          this.toastr.error('Duplicate Voucher Number', 'Submit Error', {
            closeButton: true,
          timeOut: 6000,
        });
        }
        else{
          this.toastr.error('Unable To Submit Data', 'Submit Error', {
            closeButton: true,
          });
        }

        this.disableSubmitButton = false;
      }
      );
    }
    }
  }

  backReceipt() {
    if (this.url.endsWith("transactions/accounting/receipt")) {
      this.rName = '/dashboard/report/register/receipt'
    } else if (this.url.endsWith("transactions/accounting/payment")) {
      this.rName = '/dashboard/report/register/payment'
    } else if (this.url.endsWith("transactions/accounting/contra")) {
      this.rName = '/dashboard/report/register/contra'
    } else if (this.url.endsWith("transactions/accounting/deposit")) {
      this.rName = '/dashboard/report/register/deposit'
    } else if (this.url.endsWith("transactions/accounting/withdraw")) {
      this.rName = '/dashboard/report/register/withdraw'
    }
    this.router.navigate([this.rName])
  }

  patchReceiptValues(row) {
    this.billValue = row.sr_No
    this.receiptFormGroup.patchValue({
      'voucherNo': row.voucher,
      'billNo': row.voucherNo,
      'date': this.datePipe.transform(row.billDate, 'yyyy-MM-dd'),
      'cashBank': row.cash,
      'particular': row.name,
      'amount1': row.billAmount,
      'amount2': row.billAmount,
      'narration': row.narration,
      'refNo': row.reference,
      'refDate': this.datePipe.transform(row.refDate, 'yyyy-MM-dd'),
      'chequeNo': row.chequeNo,
      'chequeDate': this.datePipe.transform(row.chequeDate, 'yyyy-MM-dd'),
      'banker': row.banker,
      'clearDate': this.datePipe.transform(row.clear, 'yyyy-MM-dd'),
    })
  }

  showBillNoFiled(objArray :any ,selectedValue:string):void{

    const foundObj = objArray.find(obj=> obj.code === selectedValue);
            if(foundObj){
                  if (foundObj.numbering === 'M') {
                    this.showLabel = true;
                  } else if (foundObj.numbering === 'A') {
                    this.showLabel = false;
                  }
            }
  }

}
