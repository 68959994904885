<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer-content style="min-height: 100vh;">

            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                crossorigin="anonymous">
            <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                crossorigin="anonymous"></script>
            <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">Register Client</nav>
                </mat-toolbar>
            </div>
            <div *ngIf="isLoading">
                <button mat-button class="add-btn" (click)="addNewClient()">Add New</button>
                <ng-template #newRegisterClientForm>
                    <mat-dialog-content>
                        <p class="cross" mat-dialog-close>&#10005;</p>
                        <div class="container">
                        <h1 class="companytitle" mat-dialog-title>Register Client</h1>
                        <mat-progress-bar mode="indeterminate" *ngIf="!isLoading2"></mat-progress-bar>
                        <div *ngIf="isLoading2">
                        <mat-card>
                            <mat-card-content>
                                <form [formGroup]="registerClientForm" class="mainForm" *ngIf="!isEditFlow">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="cledgerLabel"><span class="required">*</span>Select Company Ledger</label>
                                        </div>
                                        <div class="input-group col-sm-8">
                                            <select class="form-control" formControlName="cledgerLabel" id="cledgerLabel" placeholder="Select Company Ledger" [(ngModel)]="selectedLevel" (change)="customerDropDown()">
                                                <option disabled>Select</option>
                                                <option *ngFor="let cledger of cLedgerData" [value]="cledger.soCode">{{cledger.companyName}}</option>
                                            </select>
                                        </div>
                                        <span id="spn">
                                            <mat-error *ngIf="showErrorAlert && registerClientForm.get('cledgerLabel').hasError('required')">
                                                Field is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="customerLabel"><span class="required">*</span>Select Customer</label>
                                        </div>
                                        <div class="input-group col-sm-8">
                                            <select class="form-control" formControlName="customerLabel" id="customerLabel" placeholder="Select Customer">
                                                <option disabled>Select</option>
                                                <option *ngFor="let customerLabel of selectCustomer" [value]="customerLabel.code">{{customerLabel.name}}</option>
                                            </select>
                                        </div>
                                        <span id="spn">
                                            <mat-error *ngIf="showErrorAlert && registerClientForm.get('customerLabel').hasError('required')">
                                                Field is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="fNameLabel"><span class="required">*</span>First Name
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="fNameLabel"
                                                placeholder="Enter first name" id="fNameLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerClientForm.get('fNameLabel').hasError('required')">
                                                First Name is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="lNameLabel"><span class="required">*</span>Last Name
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="lNameLabel"
                                                placeholder="Enter last name" id="lNameLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerClientForm.get('lNameLabel').hasError('required')">
                                                Last Name is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="mailLabel"><span class="required">*</span>Client's Email Id
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="mailLabel"
                                                placeholder="Client's email Id" id="mailLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerClientForm.get('mailLabel').hasError('required')">
                                                 Email Id is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="passwordLabel"><span class="required">*</span>Client's Password
                                            </label>
                                        </div>
                                        <div class="input-group col-sm-8">
                                            <input class="form-control" formControlName="passwordLabel"
                                                placeholder="Enter password" [type]="hide ? 'password' : 'text'" id="passwordLabel" [readonly]="!forView">
                                            <div class="input-group-append">
                                                <span class="input-group-text">     
                                                    <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerClientForm.get('passwordLabel').hasError('required')">
                                                Password is required
                                            </mat-error>
                                        </span>
                                    </div>

                                </form>
                                <form [formGroup]="registerEditClientForm" class="mainForm" *ngIf="isEditFlow">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="companyNameLabel"><span class="required">*</span>Company Name</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="companyNameLabel" placeholder="Edit Company Name" id="companyNameLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error *ngIf="showErrorAlert && registerClientForm.get('companyNameLabel').hasError('required')"> 
                                                CompanyName is required
                                            </mat-error>
                                        </span>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="mailLabel"><span class="required">*</span>Client's email Id
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="mailLabel"
                                                placeholder="Client's email Id" id="mailLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerClientForm.get('mailLabel').hasError('required')">
                                                 Email Id is required
                                            </mat-error>
                                        </span>
                                    </div>

                                </form>
                                <section class="example-section" align='center' *ngIf="isEditFlow">
                                    <mat-checkbox class="example-margin" color="primary" labelPosition="before" [(ngModel)]="checked">Change Password ? </mat-checkbox>
                                </section>
                                <form [formGroup]="registerChangePasswordClientForm" class="mainForm" *ngIf="checked">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="changePasswordLabel"><span class="required">*</span>Client's Password
                                            </label>
                                        </div>
                                        <div class="input-group col-sm-8">
                                            <input class="form-control" formControlName="changePasswordLabel"
                                                placeholder="Enter password" id="changePasswordLabel" [readonly]="!forView">
                                            <div class="input-group-append">
                                                <span class="input-group-text">     
                                                    <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && registerChangePasswordClientForm.get('changePasswordLabel').hasError('required')">
                                                Password is required
                                            </mat-error>
                                        </span>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        
                        <mat-dialog-actions align='center' *ngIf="forView">
                            <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addClient()">Submit</button>
                            <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                        </mat-dialog-actions>
                        </div>
                        </div>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (registerClientFormComp)="addNewClient($event)"></app-table>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>