import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-stock-group-form',
  templateUrl: './stock-group-form.component.html',
  styleUrls: ['./stock-group-form.component.css']
})
export class StockGroupFormComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  passData!: any;
  data!: any;
  isLoading: boolean = false;
  forView: boolean = true;
  stockGroupData: any;
  hsnGroupData: any;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  code: any;
  @ViewChild('newStockGroupForm') newStockGroupForm!: TemplateRef<any>;

  stockGroupForm = this.fb.group({
    'itemGroup': ['', Validators.required],
    'hsnNo': [''],
    /*'igst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
    'cgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
    'sgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],*/
    'igst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
    'cgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
    'sgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
  });




  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }


  ngOnInit() {
    this.resetStockGroupForm();
    this.getStockGroupData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }

  getStockGroupData() {
    this.isLoading = false;
    let listOfItems = ['StockGroup', 'HSN'];
    let optionValList = ['0', '0']
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message");
      this.stockGroupData = response.stockGroup0;
      this.hsnGroupData = response.hsN0;
      this.replaceCodeWithNameForHSN(this.stockGroupData, this.hsnGroupData);
      this.passData = [this.appConstants.stockGroupFormColumnNames, this.stockGroupData, 'stockGroupForm', this.appConstants.stockGroupExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.stockGroupFormColumnNames, false, 'stockGroupForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  replaceCodeWithNameForHSN(stockGroup, hsn) {
    stockGroup.forEach((stockEntry) => {
      hsn.forEach((hsnEntry) => {
        if (stockEntry.hsn === hsnEntry.code) {
          stockEntry.hsn = hsnEntry.name;
        }
      })
    });
  }
  resetStockGroupForm() {
    this.showErrorAlert = false;
    this.stockGroupForm = this.fb.group({
      'itemGroup': ['', Validators.required],
      'hsnNo': ['',],
      /*'igst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
      'cgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
      'sgst': ['', [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],*/
      'igst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
      'cgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
      'sgst': ['', [Validators.required, Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")]],//Added by Priyanka
    });
  }

  addStockGroup() {
    if (this.stockGroupForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      const generatedData = {
        Name: this.stockGroupForm.controls['itemGroup'].value,
        Hsn: this.stockGroupForm.controls['hsnNo'].value,
        Igst: this.stockGroupForm.controls['igst'].value,
        Cgst: this.stockGroupForm.controls['cgst'].value,
        Sgst: this.stockGroupForm.controls['sgst'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateStockGroup(generatedData).subscribe(response => {
          this.getStockGroupData();
          this.toastr.success("Data Updated Successfully", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewStockGroup(generatedData).subscribe(
          (response) => {
            if (response == 'Success') {
              this.getStockGroupData();
              this.toastr.success("Data added Successful", "Add Success");
            }
          },
          (error) => {
            if (error.status===405) {
              this.isLoading = true;
              this.toastr.error("Same name stockgroup", "Add Error");
            } else {
              this.toastr.clear();
              this.toastr.error("Something Went Wrong");
            }
          }
        );
      }
    }
  }

  addNewstockGroup(rowData?: any) {
    this.resetStockGroupForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newStockGroupForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchItemFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newStockGroupForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteStockGroup(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchItemFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newStockGroupForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }
  patchItemFormValues(row) {
    this.stockGroupForm.patchValue({
      'itemGroup': row.name,
      'hsnNo': row.hsnCode,
      'sgst': row.sgst,
      'igst': row.igst,
      'cgst': row.cgst,
    })
  }

  setGSTValues(event: Event) {
    let hsnCode = (<HTMLInputElement>event.target).value;
    this.hsnGroupData.forEach((hsn: any) => {
      if (hsnCode === hsn.code) {
        this.stockGroupForm.patchValue({
          'sgst': hsn.sgst,
          'igst': hsn.igst,
          'cgst': hsn.cgst
        })
      }
    })
  }

  setOneNumberDecimalForIGST(event):void {

    if(this.stockGroupForm.get('igst').value && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
      this.stockGroupForm.patchValue({
        sgst: (parseFloat(event.target.value)/2).toFixed(1),
        cgst: (parseFloat(event.target.value)/2).toFixed(1)
      })
    }
  else{
      event.target.value = 0;
      }
  }

  setOneNumberDecimal(event):void {
    if((this.stockGroupForm.get('sgst').value || this.stockGroupForm.get('cgst').value) && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
    }
  else{
      event.target.value = 0;
      }
  }

  get igst() {
    return this.stockGroupForm.get('igst');
  }
  get cgst() {
    return this.stockGroupForm.get('cgst');
  }
  get sgst() {
    return this.stockGroupForm.get('sgst');
  }

}
