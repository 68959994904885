import { ToastrService } from 'ngx-toastr'

export class DataUpload{

  static dataUpload(event: any, toastrService: ToastrService, masterService: any, mName: string)  {
    const files = event.target.files
    const formData = new FormData();
    let toastr: ToastrService;

    formData.append('files', files[0], files[0].name)
    
    masterService.uploadMasterData(formData, mName).subscribe(
      (response) => {
        if (response.statusCode === 200) {
          toastrService.success('Data added Successful', 'Add Success')
        }
      },
      (error) => {

        toastrService.error('Unable To Upload Data', 'Add Error');
        return false;

      },
    )
    return true;

  }
}
