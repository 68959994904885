<div style="min-height: 100vh; padding-left: 0%;">
    <app-header></app-header>
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="push">
            <app-side-nav></app-side-nav>
        </mat-drawer>
        <mat-drawer-content style="min-height: 100vh;">
            <!-- Body Part Start -->
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
            <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>

            <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
            <div *ngIf="isLoading">
                <div class="main-containers page-header">
                    <mat-toolbar>
                        <nav class="headTitle">{{this.title}}</nav>
                    </mat-toolbar>
                </div>
                <button mat-button class="add-btn" (click)="addNewpayables()">Add New</button>
                <ng-template #newPayablesForm>
                    <mat-dialog-content>
                        <p class="cross" mat-dialog-close>&#10005;</p>
                        <h1 mat-dialog-title class="companytitle">Payables</h1>
                        <mat-card>
                            <mat-card-content>
                                <form [formGroup]="payablesForm" class="mainForm"> 
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="serialNoLabel"><span class="required">*</span>Serial No</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="serialNoLabel" placeholder="00000001" id="serialNoLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="billToLabel"><span class="required">*</span>Bill To</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="billToLabel" placeholder="B" id="billToLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="partyLabel"><span class="required">*</span>Party</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <select formControlName="partyLabel" id="partyLabel" class="form-control">
                                                <option [value]=null>Select</option>
                                                <option *ngFor="let partyLabel of ledgerData" [value]="partyLabel.aCode">{{partyLabel.aName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="receiptNoLabel"><span class="required">*</span>Receipt No</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="receiptNoLabel" placeholder="" id="receiptNoLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="receiptDtLabel"><span class="required">*</span>Receipt Dt</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control" formControlName="receiptDtLabel" placeholder="" id="receiptDtLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="receiptAmountLabel"><span class="required">*</span>Receipt Amount</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="receiptAmountLabel" placeholder="0.00" id="receiptAmountLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="billNoLabel"><span class="required">*</span>Bill No</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="billNoLabel" placeholder="" id="billNoLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="billDateLabel"><span class="required">*</span>Bill Date</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control" formControlName="billDateLabel" placeholder="" id="billDateLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="billAmountLabel"><span class="required">*</span>Bill Amount</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="billAmountLabel" placeholder="0.00" id="billAmountLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="adjAmountLabel"><span class="required">*</span>Adj Amount</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="adjAmountLabel" placeholder="0.00" id="adjAMountLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="creditDayLabel"><span class="required">*</span>Credit Day</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="number" class="form-control" formControlName="creditDayLabel" placeholder="0" id="creditDayLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="dueDateLabel"><span class="required">*</span>Due Date</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control" formControlName="dueDateLabel" placeholder="" id="dueDateLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="balanceLabel"><span class="required">*</span>Balance</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="balanceLabel" placeholder="0.00" id="balanceLabel" [readonly]="!forView">
                                        </div>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-dialog-actions align="center" *ngIf="forView">
                            <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addPayables($event)">Submit</button>
                            <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                        </mat-dialog-actions>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (billAdjustmentFormComp)="addNewpayables($event)" id="content" #content></app-table>
            </div>
            <!-- Body part end -->
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>