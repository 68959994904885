<div class="container">

    <div #widgetsContent class="middle">

        <div class="wrapper">
            <div class="cardContainer scrolls" fxLayoutGap="5px">
                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/sale-bill')">
                    <mat-card-title class="cardTitle"
                        ><span class="highlighter">S</span>ale
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/sales')"
                        matTooltip="Sales Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>
                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/purchase')">
                    <mat-card-title class="cardTitle"
                        ><span class="highlighter">P</span>urchase</mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/purchase')"
                            matTooltip="Purchase Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>
                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/saleOrder')">
                    <mat-card-title class="cardTitle"
                        >Sale <span
                            class="highlighter">O</span>rder
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/sales-order')"
                        matTooltip="Sale Order Register">analytics</mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>
                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/purchaseOrder')">
                    <mat-card-title class="cardTitle"
                        >Purchase Or<span
                            class="highlighter">d</span>er
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/purchase-order')"
                        matTooltip="Purchase Order Register">analytics</mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/receipt')">
                    <mat-card-title class="cardTitle"
                        ><span class="highlighter">R</span>eceipt
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/receipt')"
                            matTooltip="Receipt Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/creditNote')">
                    <mat-card-title class="cardTitle"
                        >Credit <span class="highlighter">N</span>ote
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/credit-note')"
                            matTooltip="Credit Note Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/debitNote')">
                    <mat-card-title class="cardTitle"
                        >De<span class="highlighter">b</span>it Note
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/debit-note')"
                            matTooltip="Debit Note Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/contra')">
                    <mat-card-title class="cardTitle"
                        >Contr<span class="highlighter">a</span>
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/contra')"
                            matTooltip="Contra Register">analytics</mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/deposit')">
                    <mat-card-title class="cardTitle"
                        >D<span class="highlighter">e</span>posit
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/deposit')"
                            matTooltip="Deposit Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/withdraw')">
                    <mat-card-title class="cardTitle"
                        ><span class="highlighter">W</span>ithdra<span class="highlighter">w</span>
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/withdraw')"
                            matTooltip="Withdraw Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>

                <!-- Card Cantent -->
                <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/accounting/payment')">
                    <mat-card-title class="cardTitle"
                        >Pa<span class="highlighter">y</span>ment
                    </mat-card-title>
                    <mat-card-content class="cardContent">
                        <mat-icon (click)="openComponent('dashboard/report/register/payment')"
                            matTooltip="Payment Register">analytics
                        </mat-icon>
                    </mat-card-content>
                    <mat-card-actions align="end">
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<!--  Left/Right icons for scroll controls -->
<!-- <button (click)="scrollLeft()" class="prev" mat-fab color="primary"><span class="arrowIcon">&#10094;</span></button>

<button (click)="scrollRight()" class="next" mat-fab color="primary"><span class="arrowIcon">&#10095;</span></button> -->
