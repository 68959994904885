import { Component, OnInit, Injectable, HostListener, ViewChild } from '@angular/core';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { ReportsService } from 'src/services/reports.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-party-wise-sales',
  templateUrl: './party-wise-sales.component.html',
  styleUrls: ['./party-wise-sales.component.css'],

})
export class PartyWiseSalesComponent implements OnInit {
  sideNavStatus:boolean =false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key=event.key.toUpperCase();
    if(event.key=="Escape"){
      this.openComponent('dashboard/report/misReport')
    }
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }

  dataSource: MatTableDataSource<any>;
  passData: any[];
  dateRange : FormGroup;
  tablefillData: any = [];
  disableSelect = new FormControl(false);
  ledger1Data: any;
  partyForm= this.fb.group({
  'ledgerlable1':[''],
  });
  partyData: any;
  itemCardData: any = [];
  subTotal: number = 0;
  total: number = 0;
  isLoading: boolean = false;
  headTitle: string;
  searchBar: FormGroup;
  searchData: any = [];
  DateFilterData: any = [];
  data: any = [];
  totalRowCount:number=0;

  search(event){
    var searching = this.searchBar.controls['searchLabel'].value + event.key
     var searches = []
    if(searching==''){
     this.tablefillData = this.data
    }else
    {
      for(var i = 0; i<this.searchData.length; i++){
        if(this.searchData[i].voucherNo.includes(searching)){
          searches.push(this.searchData[i])
        }
      }
      this.tablefillData=searches;
    }
  }


  constructor(
    private fb: FormBuilder,private router: Router,
    private reportService: ReportsService,
    private masterService: MasterService,
    public appConstants: AppConstants,
    private datePipe: DatePipe,) { }

    selectedLevel: any;

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }

    let startYear:number = Number(sessionStorage.getItem('startYear'));
    let startMonth: number = Number(sessionStorage.getItem('startMonth'));

    this.dateRange = this.fb.group({
      'startDate': [new Date(startYear, (startMonth - 1),1)],
      'endDate':[new Date((startYear + 1), (startMonth - 1),1)]
    });
    this.searchBar= this.fb.group({
      'searchLabel':['']
    });
      this.getLedger1Data();

  }

  getLedger1Data(){
    this.isLoading=false;
    let listOfItems=['ledger'];
    this.headTitle="Party Wise Sales";
    let optionValList=['1'];
    this.masterService.getSalesDetails(listOfItems, optionValList).subscribe( response => {
      this.ledger1Data = response.ledger1;
      this.selectedLevel = this.ledger1Data[0].aCode;
      this.tableFill();
    })
  }

  tableFill(){
    this.isLoading=false;
    this.appConstants.code = this.selectedLevel;
    let listOfItems=['sales'];
    this.reportService.getPartyDetails(listOfItems).subscribe( response => {
      this.tablefillData = response;
      this.passData=[this.appConstants.partyWiseColumnsNames, this.tablefillData];
      this.dataSource = new MatTableDataSource(this.passData[1]);
      this.data = response;
      this.searchData = response;
      this.itemCardData.push(this.tablefillData);
      this.calculateSubTotal();
      this.calculateTotal();
      this.isLoading=true;
      this.filterBasedOnDates();
    })


  }
  calculateSubTotal() {
    this.subTotal = 0;
    this.tablefillData.forEach((item)=>{
      if(item.amount){
        this.subTotal += item.amount;
      }
    });  }

  calculateTotal() {
    this.total = 0;
    this.tablefillData.forEach((item) => {
      if(item.amount){
        this.total+= item.amount;
    };
  });
}

filterBasedOnDates()
{
  let startDate = null;
  let endDate = null;
  let updatedRows = [];
  this.DateFilterData = this.data;
  let varStore = this.DateFilterData;

  startDate = this.dateRange.controls['startDate'].value != null? this.datePipe.transform(this.dateRange.controls['startDate'].value, 'MM-dd-yyyy').toString() : '';
  endDate = this.dateRange.controls['endDate'].value != null? this.datePipe.transform(this.dateRange.controls['endDate'].value, 'MM-dd-yyyy').toString() : '';
  for(let element of varStore)
  {
      let elementDate = this.datePipe.transform(element.billDate, 'MM-dd-yyyy').toString();
      if(new Date(startDate) <= new Date(elementDate) && new Date(endDate) >= new Date(elementDate))
      {
        updatedRows.push(element);
      }
  }
  if(updatedRows.length > 0)
    {
      this.tablefillData =updatedRows;
      this.calculateTotal();
      this.dataSource = new MatTableDataSource(this.tablefillData);
      this.dataSource.paginator = this.paginator;
      this.totalRowCount = this.tablefillData.length;


    }
  else {
    this.tablefillData = updatedRows;
    this.dataSource = new MatTableDataSource(this.tablefillData);
    this.totalRowCount = this.tablefillData.length;
    this.dataSource.paginator = this.paginator;
    this.subTotal=0
  }
}
}
